import { NavigationItem } from './types';

const gateKeeperNavigationTree: NavigationItem[] = [
  {
    label: 'Reportería',
    key: 'home',
    children: [
      {
        label: 'Dashboard',
        key: 'home/dashboard',
      },
    ],
  },
  {
    label: '',
    key: 'div1',
    type: 'divider',
  },
  {
    label: 'Portería',
    key: 'gate/validation',
  },
  {
    label: '',
    key: 'div2',
    type: 'divider',
  },
  {
    label: 'Compras',
    key: 'purchases',
    children: [
      {
        label: 'Reservaciones',
        key: 'purchases/reservations',
      },
      {
        label: 'Validaciones',
        key: 'purchases/validations',
      },
    ],
  },
  {
    label: '',
    key: 'div3',
    type: 'divider',
  },
];

export default gateKeeperNavigationTree;
