import { gql, useMutation } from '@apollo/client';
import { MutationUpdateOpenDaysArgs, OpenDay } from 'apollo/generated/types';

const UPDATE_OPEN_DAYS = gql`
  mutation updateOpenDays($input: UpdateOpenDaysInput!) {
    updateOpenDays(input: $input) {
      id
      day
    }
  }
`;

const useUpdateOpenDaysMutation = () => {
  return useMutation<OpenDay, MutationUpdateOpenDaysArgs>(UPDATE_OPEN_DAYS);
};

export default useUpdateOpenDaysMutation;
