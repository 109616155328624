import React, { FC, useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import useVisitsTodayQuery from 'apollo/resolvers/calendarReports/getTotalTicketSummary';
import Card from 'components/UI/Card';
import Button from 'components/UI/Button';
import { ActivityReservations, TodaysTicketData } from 'apollo/types';
import * as texts from 'assets/texts/dashboard';
import styles from './VisitsToday.module.scss';
import calculateActivityPercentage from './utils/calculateActivityPercentage';
import calculateEntrancePercentages from './utils/calculateEntrancePercentages';

const VisitsToday: FC = () => {
  const { data, loading } = useVisitsTodayQuery();
  const [totalTicketSummary, setTotalTicketSummary] = useState<TodaysTicketData>();

  const availableTickets = (reservations: ActivityReservations | undefined): string => {
    if (!reservations) {
      return `0 ${texts.availableTickets}`;
    }
    return `${reservations.stock * reservations.availableTimes - reservations.count} ${
      texts.availableTickets
    }`;
  };

  useEffect(() => {
    if (data) {
      setTotalTicketSummary(data.getTotalTicketSummary);
    }
  }, [data]);

  return (
    <>
      <Row>
        <Col>
          <h2 className={styles.Title}>{texts.currentVisitsTitle}</h2>
        </Col>
      </Row>
      <Row className={styles.CardRow} justify="space-between">
        <Col span={7}>
          <Card
            cardStyle="standardCard"
            footer={texts.persons}
            title={totalTicketSummary?.visitedCount || 0}
            loading={loading}
          />
        </Col>
        <Col span={7}>
          <Card
            cardStyle="standardCard"
            footer={texts.adults}
            title={totalTicketSummary?.visitedAdultCount || 0}
            subTitle={`de ${totalTicketSummary?.visitedCount || 0}`}
            loading={loading}
          />
        </Col>
        <Col span={7}>
          <Card
            cardStyle="standardCard"
            footer={texts.others}
            title={totalTicketSummary?.visitedKidsOrElderlyCount || 0}
            subTitle={`de ${totalTicketSummary?.visitedCount || 0}`}
            loading={loading}
          />
        </Col>
      </Row>
      <Row>
        <h2 className={styles.Title}>{texts.reservationsTitle}</h2>
      </Row>
      <Row className={styles.CardRow} justify="space-between">
        <Col span={7}>
          <Card
            cardStyle="reservationsCard"
            footer={texts.adultTickets}
            loading={loading}
            soldTickets={totalTicketSummary?.adultCount}
            percent={calculateEntrancePercentages(
              totalTicketSummary?.adultCount,
              totalTicketSummary?.count
            )}
          />
        </Col>
        <Col span={7}>
          <Card
            cardStyle="reservationsCard"
            footer={texts.otherTickets}
            loading={loading}
            soldTickets={totalTicketSummary?.kidsOrElderlyCount}
            percent={calculateEntrancePercentages(
              totalTicketSummary?.kidsOrElderlyCount,
              totalTicketSummary?.count
            )}
          />
        </Col>
        <Col span={7}>
          <Card
            cardStyle="availableTickets"
            footer={texts.availableTickets}
            title={(totalTicketSummary?.stock || 0) - (totalTicketSummary?.count || 0)}
            loading={loading}
          />
        </Col>
      </Row>
      <Row>
        <h2 className={styles.Title}>{texts.activityReservationsTitle}</h2>
      </Row>
      <Row className={styles.CardRow} justify="space-between">
        <Col span={7}>
          <Card
            cardStyle="aviaryCard"
            subTitle={texts.aviary}
            footer={texts.soldTickets}
            loading={loading}
            percent={calculateActivityPercentage(
              totalTicketSummary?.activitiesReservations.aviaryReservation
            )}
            availableTickets={availableTickets(
              totalTicketSummary?.activitiesReservations.aviaryReservation
            )}
            ticketButton={<Button type="primary" text={texts.seeTicketsButton} />}
          />
        </Col>
        <Col span={7}>
          <Card
            cardStyle="nurseryGardenCard"
            subTitle={texts.nurseryGarden}
            footer={texts.soldTickets}
            loading={loading}
            percent={calculateActivityPercentage(
              totalTicketSummary?.activitiesReservations.nurseryGardenReservation
            )}
            availableTickets={availableTickets(
              totalTicketSummary?.activitiesReservations.nurseryGardenReservation
            )}
            ticketButton={<Button type="primary" text={texts.seeTicketsButton} />}
          />
        </Col>
        <Col span={7}>
          <Card
            cardStyle="grillCard"
            subTitle={texts.grill}
            footer={texts.soldTickets}
            loading={loading}
            percent={calculateActivityPercentage(
              totalTicketSummary?.activitiesReservations.grillsReservation
            )}
            availableTickets={availableTickets(
              totalTicketSummary?.activitiesReservations.grillsReservation
            )}
            ticketButton={<Button type="primary" text={texts.seeTicketsButton} />}
          />
        </Col>
      </Row>
    </>
  );
};

export default VisitsToday;
