import { gql, useQuery } from '@apollo/client';
import { PromotionCode } from 'apollo/types';

const LIST_PROMOTION_CODES = gql`
  query listPromotionCodes {
    listPromotionCodes {
      createdAt
      forWeekends
      expirationDate
      codes {
        code
        id
        isUsed
        purchaseId
      }
      id
      promotionCodeActivities {
        activityId
        id
        maxQuantity
        percentageDiscount
      }
      promotionCodeEntrances {
        id
        maxQuantity
        percentageDiscount
        entranceTicketTypeId
      }
      promotionCodePacks {
        id
        maxQuantity
        percentageDiscount
        packsId
      }
      user {
        email
      }
      status
      suffix
    }
  }
`;

const useQueryListPromotionCodes = () => {
  return useQuery<{ listPromotionCodes: PromotionCode[] }>(LIST_PROMOTION_CODES, {});
};

export default useQueryListPromotionCodes;
