import * as texts from 'assets/texts/gateValidation';

export default [
  {
    title: texts.entranceTicketType,
    dataIndex: 'ticketType',
    key: 'ticketType',
  },
  {
    title: texts.quantity,
    dataIndex: 'quantity',
    key: 'quantity',
  },
  {
    title: texts.activityTime,
    dataIndex: 'activityTime',
    key: 'activityTime',
  },
];
