import { FormInstance } from 'antd';
import { EditPackInput } from 'apollo/types';
import { PackCreate, PackEdit } from '../types';

const packConfigurationFormToInput = (
  form: FormInstance,
  id?: string
): PackEdit | PackCreate => {
  const formValues = form.getFieldsValue(true) as EditPackInput;
  const mappedFormValues = {
    activities: formValues.activities || [],
    adultValue: Number(formValues.adultValue),
    description: formValues.description || '',
    enDescription: formValues.enDescription || '',
    enName: formValues.enName || '',
    entranceTicketTypes: formValues.entranceTicketTypes || [],
    name: formValues.name || '',
    otherValue: Number(formValues.otherValue),
    promotionCodePacks: undefined,
    status: formValues.status !== undefined ? Boolean(formValues.status) : undefined,
  };
  if (id) {
    return { id, ...mappedFormValues };
  }
  return mappedFormValues;
};

export default packConfigurationFormToInput;
