import { gql, useMutation } from '@apollo/client';
import { MutationEditProgramArgs, Program } from 'apollo/generated/types';

const EDIT_PROGRAM = gql`
  mutation editProgram($input: EditProgramInput!) {
    editProgram(input: $input) {
      id
      title
      subtitle
      description
      hoursDuration
      validForWeekends
      targetGroup
      videoLink
      status
    }
  }
`;

const useEditProgramMutation = () => {
  return useMutation<{ editProgram: Program }, MutationEditProgramArgs>(EDIT_PROGRAM, {});
};
export default useEditProgramMutation;
