import { FormInstance } from 'antd';
import {
  Mode,
  EditOrCreateItem,
  Refetch,
  EditFormValues,
  CreateFormValues,
  FormToInput,
} from '../types';

const handleOkForm = async (
  mode: Mode | undefined,
  editOrCreateItem: EditOrCreateItem,
  form: FormInstance,
  redirectToAddImages: boolean,
  formToInput: FormToInput,
  editFormValues: (formValues: EditFormValues) => Promise<unknown>,
  handleEdit: (
    editOrCreateItem: EditOrCreateItem,
    form: FormInstance,
    formToInput: FormToInput,
    editFormValues: (formValues: EditFormValues) => Promise<unknown>,
    validateForm?: boolean
  ) => Promise<unknown>,
  handleCreate: (
    form: FormInstance,
    redirectToAddImages: boolean,
    formToInput: FormToInput,
    createFormValues: (
      formValues: CreateFormValues,
      redirectToAddImages: boolean
    ) => Promise<unknown>
  ) => Promise<unknown>,
  refetch: Refetch,
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  createFormValues?: (
    formValues: CreateFormValues,
    redirectToAddImages: boolean
  ) => Promise<unknown>,
  validateEditForm?: boolean
) => {
  if (mode === 'edit') {
    await handleEdit(
      editOrCreateItem,
      form,
      formToInput,
      editFormValues,
      validateEditForm
    );
    await refetch();
  } else if (mode === 'create' && createFormValues) {
    await handleCreate(form, redirectToAddImages, formToInput, createFormValues);
    await refetch();
  }
  setIsModalVisible(redirectToAddImages);
};

export default handleOkForm;
