export const title = 'Configuración de actividades';
export const createButton = 'Agregar nueva actividad';

export const creationDate = 'Fecha de creación';
export const state = 'Estado';
export const editButton = 'Editar';
export const saveButton = 'Guardar';
export const deleteButton = 'Eliminar';
export const cancelButton = 'Cancelar';
export const editTitle = 'Editar actividad';

export const tooltip =
  'Cada lugar puede tener solo una actividad, si no hay ninguno disponible se debe crear un nuevo lugar antes de crear la actividad.';

export const summerSpringTimes = 'Horarios Primavera - Verano';
export const autumnWinterTimes = 'Horarios Otoño - Invierno';
export const addActivityTime = 'Agregar horario';
export const activityTimeFormError =
  'Hora de término debe ser después de la hora de inicio';
