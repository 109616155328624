const columns = () => {
  return [
    {
      title: 'Nombre',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Documento',
      dataIndex: 'documentNumber',
      key: 'documentNumber',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Teléfono',
      dataIndex: 'phone',
      key: 'phone',
    },
  ];
};

export default columns;
