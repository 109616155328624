import { DefaultOptionType } from 'antd/lib/select/index';

const clientInitInputs = () => {
  const initInputs = [
    {
      name: 'Desde',
      variableName: 'startDate',
      type: 'date',
      placeholder: '',
      options: [] as DefaultOptionType[],
    },
    {
      name: 'Hasta',
      variableName: 'endDate',
      type: 'date',
      placeholder: '',
      options: [] as DefaultOptionType[],
    },
    {
      name: 'Tipo de ticket',
      variableName: 'ticketType',
      type: 'select',
      mode: 'multiple' as const,
      placeholder: 'Todos',
      options: [] as DefaultOptionType[],
    },
  ];

  return initInputs;
};

export default clientInitInputs;
