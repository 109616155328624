import React, { FC, useState } from 'react';
import { Form, Input, Button, message as messageAntd } from 'antd';
import useErrorHandler from 'hooks/useErrorHandler';
import confirm from 'auth/methods/confirm';
import LoadingModal from 'components/UI/LoadingModal';
import * as texts from 'assets/texts/confirmationModal';
import useSuccess from 'hooks/useSuccess';
import Modal from 'antd/lib/modal/Modal';
import { useHistory } from 'react-router-dom';
import resendCode from 'auth/methods/resendCode';
import logIn from 'auth/methods/logIn';
import styles from './ConfirmationModal.module.scss';

interface Props {
  visible: boolean;
  email: string;
  password: string;
  // eslint-disable-next-line react/require-default-props
  onSuccessAdditional?: () => void;
}

const ConfirmationModal: FC<Props> = (props: Props) => {
  const { visible, email, password, onSuccessAdditional } = props;
  const history = useHistory<{ from: string }>();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { showError } = useErrorHandler(null);
  const onSuccess = () => {
    setLoading(false);
    setSuccess(true);
    if (onSuccessAdditional) {
      onSuccessAdditional();
    }
    window.location.replace(history.location.state?.from || '');
  };
  const onError = (error: Error) => {
    setLoading(false);
    showError(error);
  };
  const onLoading = () => {
    setLoading(true);
  };
  const loginAfterSuccess = () => {
    logIn(email, password, onSuccess, onLoading, onError);
  };
  const onFinish = (values: { confirmationCode: string }) => {
    const { confirmationCode } = values;
    confirm(confirmationCode, email, loginAfterSuccess, onLoading, onError);
  };
  const onSuccessResend = () => {
    setLoading(false);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    messageAntd.success(texts.resendSuccess);
  };
  useSuccess<boolean>(success, texts.confirmationSuccess);
  return (
    <Modal visible={visible} footer={null} closable={false}>
      <div className={styles.MainContainer}>
        <LoadingModal visible={loading} />
        <h1>{texts.title}</h1>
        <Form
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          className={styles.Form}
        >
          <Form.Item
            label={texts.confirmationCodeLabel}
            name="confirmationCode"
            rules={[{ required: true, message: texts.confirmationCodeWarning }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              type="text"
              onClick={() => resendCode(email, onSuccessResend, onLoading, onError)}
            >
              {texts.resendButton}
            </Button>
            <Button type="primary" htmlType="submit">
              {texts.confirmButton}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
