import { CognitoUser } from 'amazon-cognito-identity-js';
import { getStoredUserAuth } from 'auth/storage';
import userPool from 'auth/userPool';

const resendCode = (
  username?: string,
  onSuccessFunction?: () => void,
  onLoadingFunction?: () => void,
  onErrorFunction?: (error: Error) => void
) => {
  if (onLoadingFunction) onLoadingFunction();
  const userData = {
    Username: username || getStoredUserAuth().username,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  cognitoUser.resendConfirmationCode((error: unknown) => {
    if (error) {
      if (onErrorFunction) onErrorFunction(error as Error);
    }
    if (onSuccessFunction) onSuccessFunction();
  });
};

export default resendCode;
