import React, { FC, useState } from 'react';
import { Form, Input, Button, Col, Row } from 'antd';
import useErrorHandler from 'hooks/useErrorHandler';
import logIn from 'auth/methods/logIn';
import LoadingModal from 'components/UI/LoadingModal';
import * as texts from 'assets/texts/logIn';
import ConfirmationModal from 'components/auth/ConfirmationModal';
import ForgotPasswordModal from 'components/auth/ForgotPasswordModal';
import { useHistory } from 'react-router-dom';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { setStorage } from 'auth/storage';
import { UserAuth } from 'auth/types';
import styles from './Login.module.scss';

const LogIn: FC = () => {
  const history = useHistory<{ from: string }>();
  const [loading, setLoading] = useState(false);
  const { showError } = useErrorHandler(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [password, setPassword] = useState<string | undefined>(undefined);

  const onSuccess = (userAuth: UserAuth, cognitoUser: CognitoUser) => {
    cognitoUser.getUserAttributes((err, result) => {
      if (err) {
        showError(err);
      } else if (result) {
        const databaseId = result
          .find((attribute) => attribute.getName() === 'custom:databaseId')
          ?.getValue();

        const userRole = result
          .find((attribute) => attribute.getName() === 'custom:userRole')
          ?.getValue();

        if (databaseId && userRole) {
          setStorage({
            ...userAuth,
            databaseId,
            userRole,
          });
        }
      }
      setLoading(false);
      window.location.replace(history.location.state?.from || '');
    });
  };
  const onError = (error: Error) => {
    setLoading(false);
    showError(error);
    if (error.stack?.includes('UserNotConfirmedException')) {
      setShowConfirmationModal(true);
    }
  };
  const onLoading = () => {
    setLoading(true);
  };
  const onFinish = (values: {
    email: string;
    password: string;
    name: string;
    familyName: string;
  }) => {
    setEmail(values.email);
    setPassword(values.password);
    logIn(values.email, values.password, onSuccess, onLoading, onError);
  };
  const onConfirmationSuccess = () => {
    setShowConfirmationModal(false);
  };
  const onResetPasswordSuccess = () => {
    setShowResetPasswordModal(false);
  };

  return (
    <div className={styles.MainContainer}>
      <LoadingModal visible={loading} />{' '}
      {email && password && (
        <ConfirmationModal
          visible={showConfirmationModal}
          email={email}
          password={password}
          onSuccessAdditional={onConfirmationSuccess}
        />
      )}
      <ForgotPasswordModal
        visible={showResetPasswordModal}
        onSuccessAdditional={onResetPasswordSuccess}
      />
      <Row>
        <Col style={{ margin: 'auto' }}>
          <h1>{texts.title}</h1>
        </Col>
      </Row>
      <Row>
        <Col span={24} sm={{ span: 22 }}>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            className={styles.Form}
          >
            <Form.Item
              label={texts.emailLabel}
              name="email"
              rules={[{ required: true, message: texts.emailWarning }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={texts.passwordLabel}
              name="password"
              rules={[
                {
                  required: true,
                  message: texts.passwordWarning,
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item wrapperCol={{ span: 24 }}>
              <Button
                type="text"
                onClick={() => {
                  setShowResetPasswordModal(true);
                }}
                className={styles.LinkButton}
              >
                {texts.forgotPasswordButton}
              </Button>
            </Form.Item>

            <Form.Item wrapperCol={{ sm: { offset: 4 } }}>
              <Button type="primary" htmlType="submit">
                {texts.logInButton}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default LogIn;
