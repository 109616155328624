import { Collapse } from 'antd';
import { Client } from 'apollo/types';
import { Table } from 'components/UI';
import React, { FC } from 'react';
import dataToTable from 'views/Mailer/utils/dataToTable';
import columns from 'views/Mailer/utils/createColumn';
import * as texts from '../../../../assets/texts/mailer';
import styles from '../../../../views/Mailer/Mailer.module.scss';

export interface ClientDetailProps {
  clients: Client[];
}

const ClientDetail: FC<ClientDetailProps> = ({ clients }: ClientDetailProps) => {
  return (
    <>
      <h2 className={styles.Title}>{texts.listClients}</h2>
      <Collapse>
        <Collapse.Panel header={texts.listClients} key="1">
          <Table columns={columns()} dataSource={dataToTable(clients)} />
        </Collapse.Panel>
      </Collapse>
    </>
  );
};

export default ClientDetail;
