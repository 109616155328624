import { ActivityTimeRow } from '../../types';

const validateActivityTime = (record: ActivityTimeRow): boolean => {
  const { startHour, endHour } = record;
  if (!startHour || !endHour) return false;
  if (startHour < endHour) return true;
  return false;
};

export default validateActivityTime;
