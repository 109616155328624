import { ThingToDo } from 'apollo/types';

const thingsToDoFormFieldMapper = (thingsToDo: ThingToDo[]) => {
  const thingsToDoOptions = thingsToDo?.map((thingToDo) => {
    return { label: thingToDo.name, value: thingToDo.id };
  });
  return thingsToDoOptions;
};

export default thingsToDoFormFieldMapper;
