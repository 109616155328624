import { Activity, PlaceImage } from 'apollo/types';
import * as texts from '../../../../assets/texts/grillConfiguration';

const columns = (
  statusColumnRender: (value: boolean, record: unknown) => JSX.Element,
  editButton: (EditItem: Activity) => JSX.Element,
  imageGroup: (item: PlaceImage[] | undefined) => JSX.Element,
  renderHTMLTag: (description: string) => JSX.Element
) => {
  return [
    {
      title: texts.name,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: texts.description,
      dataIndex: 'description',
      key: 'description',
      render: renderHTMLTag,
    },
    {
      title: texts.photos,
      dataIndex: 'placeImages',
      key: 'placeImages',
      render: imageGroup,
    },
    {
      title: texts.state,
      dataIndex: 'status',
      key: 'status',
      render: statusColumnRender,
    },
    {
      title: texts.actions,
      dataIndex: 'actions',
      key: 'actions',
      render: editButton,
    },
  ];
};

export default columns;
