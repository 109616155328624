import React, { FC, useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import Calendar from 'components/UI/Calendar';
import useListTicketsGroupedQuery from 'apollo/resolvers/calendarReports/listTicketsGrouped';
import CollapsibleTable from 'components/views/Home/Dashboard/CollapsibleTable';
import dateFormat from 'utils/formats/dateFormat';
import * as texts from 'assets/texts/dashboard';
import moment from 'moment';
import styles from './CalendarReports.module.scss';

const CalendarReports: FC = () => {
  const [listTicketsGrouped, { data: ticketsData, loading: ticketsLoading }] =
    useListTicketsGroupedQuery();
  const [currentDate, setCurrentDate] = useState<string>('');

  const onSelect = async (date: moment.Moment) => {
    const selectedDate = dateFormat(date.toDate(), false);
    setCurrentDate(selectedDate);
    await listTicketsGrouped({ variables: { date: selectedDate } });
  };

  useEffect(() => {
    const getTodaysData = async () => {
      await onSelect(moment());
    };
    getTodaysData().catch(() => {});
  }, []);

  return (
    <>
      <Row>
        <Col>
          <h2 className={styles.Title}>{texts.calendarReportsTitle}</h2>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span={15}>
          <CollapsibleTable
            currentDate={currentDate}
            columns={texts.tableColumns}
            entrances={ticketsData?.listTicketsGrouped.entrances}
            experiences={ticketsData?.listTicketsGrouped.experiences}
            grills={ticketsData?.listTicketsGrouped.grills || undefined}
            loading={ticketsLoading}
          />
        </Col>
        <Col span={8}>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <Calendar fullscreen={false} onSelect={onSelect} />
        </Col>
      </Row>
    </>
  );
};

export default CalendarReports;
