import React, { FC, useEffect, useState } from 'react';
import { Row, Col, Tag } from 'antd';
import { Table, Switch, Button, UploadImages, ImagePreviewGroup } from 'components/UI';
import FormModal from 'components/sharedComponents/FormModal';
import * as texts from 'assets/texts/packConfiguration';
import useListPacksQuery from 'apollo/resolvers/packs/list';
import useEditPackMutation from 'apollo/resolvers/packs/edit';
import useCreatePackMutation from 'apollo/resolvers/packs/create';
import { Pack, EditPackInput, CreatePackInput, PackImage } from 'apollo/types';
import {
  CreateFormValues,
  EditFormValues,
  Mode,
} from 'components/sharedComponents/FormModal/types';
import useListEntranceTicketTypesQuery from 'apollo/resolvers/entranceTicketTypes/list';
import useListActivitiesQuery from 'apollo/resolvers/activity/listForPack';
import useUploadPackImageMutation from 'apollo/resolvers/packImages/upload';
import useDeletePackImageMutation from 'apollo/resolvers/packImages/delete';
import statusOnChange from './utils/statusOnChange';
import setEditOrCreateForm from './utils/setEditOrCreateForm';
import { PackColumn } from './types';
import formToInput from './utils/formToInput';
import dataToTable from './utils/dataToTable';
import columns from './utils/createColumns';
import packFormFields from './utils/formFields';
import styles from './PackConfiguration.module.scss';
import mapOptions from './utils/mapOptions';
import visibilityOnChange from './utils/visibilityOnChange';

const PackConfiguration: FC = () => {
  const { data, loading, refetch } = useListPacksQuery();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<Pack>();
  const [modalMode, setModalMode] = useState<Mode | undefined>();
  const [uploadImage, { data: uploadImageData, loading: uploadImageLoading }] =
    useUploadPackImageMutation();
  const [deleteImage, { loading: deleteImageLoading }] = useDeletePackImageMutation();
  const [editPackMutation] = useEditPackMutation();
  const [createPackMutation] = useCreatePackMutation();
  const { data: entranceTicketTypesData } = useListEntranceTicketTypesQuery();
  const { data: activitiesData } = useListActivitiesQuery('1');

  const statusColumnRender = (value: boolean, record: unknown): JSX.Element => {
    const pack = record as PackColumn;
    return (
      <Switch
        defaultChecked={value}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={() => statusOnChange(pack, editPackMutation, refetch)}
      />
    );
  };

  const visibilityColumnRender = (value: boolean, record: unknown): JSX.Element => {
    const pack = record as PackColumn;
    return (
      <Switch
        defaultChecked={value}
        disabled={pack.status || false}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={() => visibilityOnChange(pack, editPackMutation, refetch)}
      />
    );
  };

  const editButton = (item: Pack): JSX.Element => (
    <Button
      type="primary"
      text={texts.editButton}
      onClick={() =>
        setEditOrCreateForm(item, setEditItem, setModalMode, setIsModalVisible)
      }
    />
  );

  const createImageGroup = (entranceTicketImages: PackImage[]): JSX.Element => {
    const images = entranceTicketImages.map((image) => image.image || undefined);
    return <ImagePreviewGroup images={images} maxImagesToShow={2} widthImages={50} />;
  };

  const imageGroup = (item: PackImage[] | undefined): JSX.Element => {
    if (item && item.length > 0) {
      return createImageGroup(item);
    }
    return <></>;
  };

  const refetchFunction = async () => {
    await refetch();
  };

  const editMutation = async (formValues: EditFormValues) => {
    const entranceFormValues = formValues as EditPackInput;
    const item = await editPackMutation({
      variables: { input: entranceFormValues },
    });

    return item;
  };

  const createMutation = async (formValues: CreateFormValues) => {
    const entranceFormValues = formValues as CreatePackInput;
    const item = await createPackMutation({
      variables: { input: entranceFormValues },
    });

    return item;
  };

  const uploadPackImage = async (values: {
    id: bigint | undefined;
    parentEntityId: bigint;
    contentType: string;
  }) => {
    await uploadImage({
      variables: {
        input: {
          id: values.id?.toString(),
          packId: values.parentEntityId.toString(),
          contentType: values.contentType,
        },
      },
    });
  };

  const deletePackImage = async (values: { id: bigint; parentEntityId: bigint }) => {
    await deleteImage({
      variables: {
        input: {
          id: values.id.toString(),
          packId: values.parentEntityId.toString(),
        },
      },
    });
  };

  const mappedEntranceTicketTypes = mapOptions(
    entranceTicketTypesData?.listEntranceTicketTypesBackOffice
  );

  const mappedActivities = mapOptions(activitiesData?.listActivitiesBackOffice);

  const renderTags = (tags: { id: string; name: string }[]) => (
    <>
      {tags.slice(0, 2).map((tag) => (
        <Tag className={styles.Tag} key={tag.name}>
          {tag.name}
        </Tag>
      ))}
      {tags?.length > 2 && (
        <Tag className={styles.Tag} key="+">
          + {tags.length - 2}
        </Tag>
      )}
    </>
  );

  const uploadElement = (
    <UploadImages
      images={editItem?.packImages || []}
      parentEntityId={BigInt(editItem?.id || 0)}
      uploadedImageId={
        uploadImageData?.uploadPackImage.id
          ? BigInt(uploadImageData?.uploadPackImage.id)
          : undefined
      }
      uploadUrl={uploadImageData?.uploadPackImage.uploadUrl || undefined}
      loading={uploadImageLoading || deleteImageLoading}
      uploadCallback={uploadPackImage}
      deleteCallback={deletePackImage}
      refetchImagesCallback={refetchFunction}
    />
  );

  useEffect(() => {
    if (data?.listPacks) {
      if (editItem) {
        const foundItem = data.listPacks.find((item) => item.id === editItem.id);
        if (foundItem) setEditItem(foundItem as unknown as Pack);
      }
    }
  }, [data?.listPacks]);

  return (
    <>
      <FormModal
        formToInput={formToInput}
        isModalVisible={isModalVisible}
        editOrCreateItem={editItem}
        setIsModalVisible={setIsModalVisible}
        formFields={packFormFields(
          modalMode === 'create',
          mappedEntranceTicketTypes,
          mappedActivities,
          editItem,
          uploadElement
        )}
        title={modalMode === 'create' ? texts.createButton : texts.editTitle}
        mode={modalMode}
        refetch={refetchFunction}
        editFormValues={editMutation}
        createFormValues={createMutation}
      />
      <Row>
        <Col>
          <h2 className={styles.Title}>{texts.title}</h2>
        </Col>
      </Row>
      <Row className={styles.Row}>
        <Col>
          <Button
            type="default"
            text={texts.createButton}
            onClick={() =>
              setEditOrCreateForm(undefined, setEditItem, setModalMode, setIsModalVisible)
            }
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            columns={columns(
              statusColumnRender,
              renderTags,
              editButton,
              imageGroup,
              visibilityColumnRender
            )}
            dataSource={dataToTable(data?.listPacks)}
            loading={loading}
            pagination={false}
            rowKey="name"
          />
        </Col>
      </Row>
    </>
  );
};

export default PackConfiguration;
