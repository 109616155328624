import { gql, useMutation } from '@apollo/client';
import { Pack, MutationEditPackArgs } from 'apollo/generated/types';

const EDIT_ENTRANCE_TICKET_TYPE = gql`
  mutation editPack($input: EditPackInput!) {
    editPack(input: $input) {
      id
      adultValue
      description
      enDescription
      enName
      name
      otherValue
      status
      visibility
    }
  }
`;

const useEditPackMutation = () => {
  return useMutation<{ editPack: Pack }, MutationEditPackArgs>(
    EDIT_ENTRANCE_TICKET_TYPE,
    {}
  );
};
export default useEditPackMutation;
