import { Col, Row } from 'antd';
import { Button, ImagePreviewGroup, Switch, Table, UploadImages } from 'components/UI';
import React, { FC } from 'react';
import * as texts from 'assets/texts/programConfiguration';
import { Program, ProgramImage } from 'apollo/types';
import FormModal from 'components/sharedComponents/FormModal';
import styles from './ProgramConfiguration.module.scss';
import formToInput from './utils/formToInput';
import columns from './utils/createColumns';
import dataToTable from './utils/dataToTable';
import useProgramConfigurationViewModel from './programConfiguration.viewModel';
import programFormFields from './utils/formFields';

const ProgramConfiguration: FC = () => {
  const {
    isModalVisible,
    setIsModalVisible,
    listProgramsData,
    listProgramsLoading,
    refetchListPrograms,
    editProgramLoading,
    editedProgram,
    onValidForWeekendsChange,
    onStatusChange,
    uploadCoverImage,
    uploadSecondaryImage,
    deleteProgramImage,
    uploadImageData,
    uploadImageLoading,
    onEditButtonClick,
    onFormFinish,
    maxNumberOfSecondaryImages,
    refetchImages,
  } = useProgramConfigurationViewModel();

  const editButton = (item: Program) => {
    return (
      <Button type="primary" text={texts.edit} onClick={() => onEditButtonClick(item)} />
    );
  };

  const renderActionColumns = (item: Program) => {
    return (
      <Row>
        <Col span={24}>{editButton(item)}</Col>
      </Row>
    );
  };

  const renderValidForWeekendsColumn = (value: boolean, record: unknown) => {
    const programId = (record as Program).id;
    return (
      <Switch
        defaultChecked={value}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onChange={(checked: boolean) => onValidForWeekendsChange(programId, checked)}
      />
    );
  };

  const renderStatusColumn = (value: boolean, record: unknown) => {
    const programId = (record as Program).id;
    return (
      <Switch
        defaultChecked={value}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onChange={(checked: boolean) => onStatusChange(programId, checked)}
      />
    );
  };

  const renderImagesGroup = (images: ProgramImage[]) => {
    if (images.length > 0) {
      const urls = images.map((image) => image.url || undefined);
      return <ImagePreviewGroup images={urls} maxImagesToShow={2} widthImages={50} />;
    }
    return <></>;
  };

  const renderVideoLink = (link: string) => {
    if (!link) {
      return <>{texts.noVideoLink}</>;
    }
    return (
      <a href={link} target="_blank" rel="noopener noreferrer">
        {texts.link}
      </a>
    );
  };

  const coverLabelElement = () => {
    return (
      <Row>
        <Col span={24}>{texts.coverInputLabel}:</Col>
        <Col className={styles.CoverRecommendation} span={24}>
          {`(${texts.coverInputRecommendationFirstPart}`}
        </Col>
        <Col className={styles.CoverRecommendation} span={24}>
          {` ${texts.coverInputRecommendationSecondPart})`}
        </Col>
      </Row>
    );
  };

  const uploadProgramImageElement = (isCover = false) => {
    if (!editedProgram) {
      return <></>;
    }

    const images = [];
    if (isCover && editedProgram.coverImage) {
      images.push(editedProgram.coverImage);
    } else if (!isCover && editedProgram.secondaryImages) {
      images.push(...editedProgram.secondaryImages);
    }

    const uploadedImageId = uploadImageData?.uploadProgramImage.id
      ? BigInt(uploadImageData?.uploadProgramImage.id)
      : undefined;

    const uploadUrl = uploadImageData?.uploadProgramImage.uploadUrl || undefined;

    let maxNumberOfImages;
    if (isCover) {
      maxNumberOfImages = 1;
    } else {
      maxNumberOfImages = maxNumberOfSecondaryImages;
    }

    const uploadCallback = isCover ? uploadCoverImage : uploadSecondaryImage;

    return (
      <UploadImages
        images={images}
        parentEntityId={BigInt(editedProgram.id)}
        uploadedImageId={uploadedImageId}
        uploadUrl={uploadUrl}
        loading={uploadImageLoading}
        isMain={isCover}
        maxNumberOfImages={maxNumberOfImages}
        uploadCallback={uploadCallback}
        deleteCallback={deleteProgramImage}
        refetchImagesCallback={refetchImages}
        refetchOnDelete={false}
      />
    );
  };

  const renderForm = () => {
    if (!editedProgram) {
      return <></>;
    }

    return (
      <FormModal
        formToInput={formToInput}
        isModalVisible={isModalVisible}
        editOrCreateItem={editedProgram}
        setIsModalVisible={setIsModalVisible}
        formFields={programFormFields(
          editedProgram,
          coverLabelElement(),
          uploadProgramImageElement(true),
          uploadProgramImageElement(false)
        )}
        title={texts.editTitle}
        mode="edit"
        refetch={refetchListPrograms}
        editFormValues={onFormFinish}
        validateEditForm
      />
    );
  };

  return (
    <>
      {renderForm()}
      <Row>
        <Col>
          <h2 className={styles.Title}>{texts.title}</h2>
        </Col>
      </Row>
      <Row className={styles.Row}>
        <Col span={24}>
          <Table
            columns={columns(
              renderActionColumns,
              renderValidForWeekendsColumn,
              renderStatusColumn,
              renderImagesGroup,
              renderVideoLink
            )}
            dataSource={dataToTable(listProgramsData?.listPrograms)}
            loading={listProgramsLoading || editProgramLoading}
            pagination={false}
            rowKey="name"
          />
        </Col>
      </Row>
    </>
  );
};

export default ProgramConfiguration;
