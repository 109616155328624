import { EntranceTicketType } from 'apollo/types';
import { FormFields } from 'components/UI/Form/types';
import { ReactNode } from 'react';

const entranceFormFields = (
  isCreation: boolean,
  editableRow?: EntranceTicketType,
  uploadelement?: ReactNode
): FormFields[] => {
  const formFields = [
    {
      name: 'name',
      label: 'Nombre Ticket',
      type: 'text',
      initialValue: editableRow?.name || '',
      rules: [{ required: isCreation }],
    },
    {
      name: 'adultValue',
      label: 'Precio ticket adultos',
      type: 'number',
      initialValue: editableRow?.adultValue,
      rules: [{ required: isCreation, min: 0 }],
    },
    {
      name: 'otherValue',
      label: 'Precio adulto mayor o niños',
      type: 'number',
      initialValue: editableRow?.otherValue,
      rules: [{ required: isCreation, min: 0 }],
    },
    {
      name: 'maxStock',
      label: 'Stock de tickets por día',
      type: 'number',
      initialValue: editableRow?.maxStock,
      rules: [{ required: isCreation, min: 0 }],
    },
    {
      name: 'description',
      label: 'Descripción',
      type: 'textarea',
      initialValue: editableRow?.description || '',
      rules: [{ required: isCreation }],
    },
    {
      name: 'isBicycle',
      label: 'Entrada para bicicletas',
      type: 'switch',
      initialValue: editableRow?.isBicycle || false,
      rules: [{ required: isCreation }],
    },
  ];

  if (isCreation) {
    return formFields;
  }

  return [
    ...formFields,
    {
      name: 'images',
      label: 'Fotos',
      type: 'images',
      uploadelement,
      rules: [{ required: false }],
    },
  ];
};

export default entranceFormFields;
