import { Pack } from 'apollo/types';

const dataToTable = (data: Pack[] | undefined) => {
  if (!data) return [];
  const mappedPacks = data?.map((pack, index) => {
    const {
      name,
      adultValue,
      otherValue,
      status,
      activities,
      entranceTicketTypes,
      packImages,
      visibility,
    } = pack;
    return {
      key: index,
      name,
      adultValue,
      otherValue,
      status,
      activities,
      entranceTicketTypes,
      packImages,
      visibility,
      actions: pack,
    };
  });
  return mappedPacks;
};

export default dataToTable;
