import { Moment } from 'moment';
import { SearcherInput } from '../types';

const getInitialValues = (inputs: SearcherInput[]) => {
  const initialValues: { [name: string]: string | Moment } = {};
  inputs.forEach((input) => {
    if (input.initialValue) {
      initialValues[input.variableName] = input.initialValue;
    }
  });
  return initialValues;
};

export default getInitialValues;
