import { FormInstance } from 'antd';
import { EditActivityInput } from 'apollo/types';
import { ActivityCreate, ActivityEdit } from '../types';

const activityConfigurationFormToInput = (
  form: FormInstance,
  id?: string
): ActivityEdit | ActivityCreate => {
  const formValues = form.getFieldsValue(true) as EditActivityInput;
  const mappedFormValues = {
    activityTypeId: '1',
    name: formValues.name || '',
    value: Number(formValues.value),
    status: Boolean(formValues.status),
    placeId: formValues.placeId || '',
    paxPerTicket: Number(formValues.paxPerTicket),
    maxStock: Number(formValues.maxStock),
    activityTimes:
      formValues.activityTimes?.map((activityTime) => ({
        id: activityTime?.id,
        startHour: activityTime?.startHour || '',
        endHour: activityTime?.endHour || '',
        parkAvailabilityId: activityTime?.parkAvailabilityId || '',
        status: activityTime?.status || false,
      })) || [],
  };
  if (id) {
    let mappedPlaceId;
    if (Array.isArray(formValues.placeId)) {
      // eslint-disable-next-line prefer-destructuring, @typescript-eslint/no-unsafe-assignment
      mappedPlaceId = formValues.placeId[0];
    } else {
      mappedPlaceId = formValues.placeId;
    }
    return {
      id,
      ...mappedFormValues,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      placeId: mappedPlaceId,
    };
  }
  return mappedFormValues;
};

export default activityConfigurationFormToInput;
