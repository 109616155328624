import React, { FC, useEffect, useState } from 'react';
import Table from 'components/UI/Table';
import * as texts from 'assets/texts/clients';
import { Form, Pagination } from 'antd';
import useListClientsBackofficeLazyQuery from 'apollo/resolvers/client/list';
import { EnrichedClient, Filter, ListClientsBackofficeInput } from 'apollo/types';
import Searcher from 'components/views/Purchases/Searcher';
import dataToTable from './utils/dataToTable';
import styles from './Clients.module.scss';
import createColumns from './utils/createColumns';
import searchInputs from './utils/inputs';
import filterArrayToListClientsArgs from './utils/filterArrayToListClientsArgs';

const Clients: FC = () => {
  const [form] = Form.useForm();
  const columns = createColumns();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentPageSize, setCurrentPageSize] = useState<number>(10);
  const [count, setCount] = useState<number>();
  const [clients, setClients] = useState<EnrichedClient[] | undefined>(undefined);
  const [isPaginated, setIsPaginated] = useState<boolean>(true);

  const [listClients, { loading }] = useListClientsBackofficeLazyQuery();

  const onFinished = async (filterArray: React.SetStateAction<Filter[]>) => {
    const mappedFilters = filterArrayToListClientsArgs(filterArray as Filter[]);
    const args: ListClientsBackofficeInput = {
      page: currentPage,
      pageSize: currentPageSize,
      email: mappedFilters.email,
      phone: mappedFilters.phone,
      documentNumber: mappedFilters.documentNumber,
      name: mappedFilters.name,
      lastName: mappedFilters.lastName,
    };
    const { data } = await listClients({
      variables: {
        input: args,
      },
    });
    setCount(data?.listClientsBackoffice.count);
    setClients(data?.listClientsBackoffice.clients);
    setIsPaginated(false);
  };

  const eraseFilters = async () => {
    const args: ListClientsBackofficeInput = {
      page: currentPage,
      pageSize: currentPageSize,
    };
    const { data } = await listClients({
      variables: {
        input: args,
      },
    });
    setCount(data?.listClientsBackoffice.count);
    setClients(data?.listClientsBackoffice.clients);
    setIsPaginated(true);
    form.resetFields();
  };

  useEffect(() => {
    const getClients = async () => {
      const args: ListClientsBackofficeInput = {
        page: currentPage,
        pageSize: currentPageSize,
      };
      return listClients({
        variables: {
          input: args,
        },
      });
    };
    getClients()
      .then((response) => {
        setCount(response.data?.listClientsBackoffice.count);
        setClients(response.data?.listClientsBackoffice.clients);
      })
      .catch(() => {});
  }, [currentPage, currentPageSize]);

  return (
    <>
      <h2 className={styles.Title}>{texts.title}</h2>
      <Searcher
        inputs={searchInputs()}
        form={form}
        canEraseFilters
        eraseFilters={() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          eraseFilters();
        }}
        filterSetter={(value: React.SetStateAction<Filter[]>) => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          onFinished(value);
        }}
      />
      <Table
        columns={columns}
        dataSource={dataToTable(clients)}
        loading={loading}
        pagination={false}
      />
      {isPaginated && (
        <Pagination
          className={styles.Pagination}
          total={count}
          showSizeChanger
          pageSize={currentPageSize}
          onChange={(page, pageSize) => {
            setCurrentPage(page);
            setCurrentPageSize(pageSize);
          }}
          current={currentPage}
        />
      )}
    </>
  );
};

export default Clients;
