import { gql, useMutation } from '@apollo/client';
import { MutationUploadProgramImageArgs, ProgramImage } from 'apollo/generated/types';

const UPLOAD_PROGRAM_IMAGE = gql`
  mutation uploadProgramImage($input: UploadProgramImageInput!) {
    uploadProgramImage(input: $input) {
      id
      programImageType
      uploadUrl
      url
    }
  }
`;

const useUploadProgramImageMutation = () => {
  return useMutation<
    { uploadProgramImage: ProgramImage },
    MutationUploadProgramImageArgs
  >(UPLOAD_PROGRAM_IMAGE, {});
};
export default useUploadProgramImageMutation;
