import React, { FC, ReactElement } from 'react';
import { Image, Row, Col } from 'antd';

type Props = {
  images: (string | undefined)[];
  widthImages: number;
  maxImagesToShow?: number;
};

const ImagePreviewGroup: FC<Props> = (props: Props): ReactElement => {
  const { images, maxImagesToShow, widthImages } = props;
  let toShowImages: (string | undefined)[] = [];
  if (maxImagesToShow) {
    toShowImages = images.slice(0, maxImagesToShow);
  } else {
    toShowImages = images;
  }

  return (
    <Image.PreviewGroup>
      <Row justify="space-around" align="middle">
        {toShowImages.map((image) => {
          if (image) {
            return (
              <Col key={image} span={12}>
                <Image src={image} width={widthImages} placeholder />
              </Col>
            );
          }
          return <></>;
        })}
      </Row>
    </Image.PreviewGroup>
  );
};

export default ImagePreviewGroup;

ImagePreviewGroup.defaultProps = {
  maxImagesToShow: undefined,
};
