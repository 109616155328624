import { Button, Col, Row } from 'antd';
import React, { FC } from 'react';
import * as texts from 'assets/texts/gateValidation';
import useValidatePurchaseMutation from 'apollo/resolvers/purchases/validatePurchase';
import { ValidatePurchaseInput } from 'apollo/types';
import { LoadingModal } from 'components/UI';
import styles from '../../../../views/GateValidation/GateValidation.module.scss';

export interface ValidatePurchaseProps {
  purchaseId: string;
  userId: string;
  disabledByDate: boolean;
  disabledByUser: boolean;
  isPurchaseValid: (isPurchaseValid: boolean) => void;
}

const ValidatePurchase: FC<ValidatePurchaseProps> = ({
  purchaseId,
  userId,
  disabledByDate,
  disabledByUser,
  isPurchaseValid,
}: ValidatePurchaseProps) => {
  const [isVisible, setIsVisible] = React.useState<boolean>(false);

  const [validatePurchaseResponse] = useValidatePurchaseMutation(purchaseId, userId);

  const validatePurchaseInput: ValidatePurchaseInput = {
    purchaseId,
    userId,
  };

  const disabled = disabledByDate || disabledByUser;

  const onValidatePurchaseButtonClick = async () => {
    setIsVisible(true);
    try {
      const data = await validatePurchaseResponse({
        variables: validatePurchaseInput,
      });
      if (data.data) {
        isPurchaseValid(data.data.validatePurchase.success);
      }
      setIsVisible(false);
    } catch {
      setIsVisible(false);
    }
  };
  return (
    <>
      <LoadingModal visible={isVisible} />
      <Col>
        <Row justify="center">
          <Button
            type="primary"
            disabled={disabled}
            onClick={onValidatePurchaseButtonClick as unknown as undefined}
          >
            {texts.validateEntrance}
          </Button>
        </Row>
        <Row justify="center">
          {disabledByDate && (
            <div className={styles.errorMessage}>{texts.invalidDateEntrances}</div>
          )}
          {disabledByUser && (
            <div className={styles.errorMessage}>{texts.invalidUserEntrances}</div>
          )}
        </Row>
      </Col>
    </>
  );
};

export default ValidatePurchase;
