import { TicketPurchase } from 'apollo/types';
import dateFormat from 'utils/formats/dateFormat';
import { FormattedReservation } from '../types';

const dataToTable = (data: TicketPurchase[] | undefined): FormattedReservation[] => {
  if (!data) {
    return [];
  }
  return data.map((ticket, index) => {
    return {
      key: `${ticket.purchaseId} ${index}`,
      orderNumber: ticket.purchaseId,
      visitDate: dateFormat(new Date(ticket.visitDate), true),
      rut: ticket.documentNumber,
      ticketType: ticket.ticketType,
      disabledPurchaseHistory: ticket.disabledPurchaseHistory,
      purchase: ticket,
    };
  });
};

export default dataToTable;
