import { DefaultOptionType } from 'antd/lib/select';

const searchInputs = () => {
  const inputs = [
    {
      name: 'Nombre y Apellido',
      variableName: 'fullName',
      type: 'text',
      placeholer: '',
      options: [] as DefaultOptionType[],
    },
    {
      name: 'Rut/Nº de documento',
      variableName: 'documentNumber',
      type: 'text',
      placeholder: '',
      options: [] as DefaultOptionType[],
    },
    {
      name: 'Email',
      variableName: 'email',
      type: 'text',
      placeholder: '',
      options: [] as DefaultOptionType[],
    },
    {
      name: 'Teléfono',
      variableName: 'phone',
      type: 'text',
      placeholder: '',
      options: [] as DefaultOptionType[],
    },
  ];

  return inputs;
};

export default searchInputs;
