import React, { FC } from 'react';
import ApolloWrapperProvider from 'apollo/Provider';
import AppNavigation from 'routes';
import { ConfigProvider } from 'antd';
import esES from 'antd/lib/locale/es_ES';

const App: FC = () => {
  return (
    <ConfigProvider locale={esES}>
      <ApolloWrapperProvider>
        <AppNavigation />
      </ApolloWrapperProvider>
    </ConfigProvider>
  );
};

export default App;
