import { PlaceImage, Place } from 'apollo/types';
import * as texts from '../../../../assets/texts/placeConfiguration';

const columns = (
  editButton: (item: Place) => JSX.Element,
  imageGroup: (item: PlaceImage[] | undefined) => JSX.Element,
  renderTags: (tags: { description: string; id: string; name: string }[]) => JSX.Element,
  renderHTMLTag: (mainDescription: string) => JSX.Element
) => {
  return [
    {
      title: texts.place,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: texts.description,
      dataIndex: 'mainDescription',
      key: 'mainDescription',
      render: renderHTMLTag,
    },
    {
      title: texts.thingsToDo,
      dataIndex: 'thingsToDo',
      key: 'thingsToDo',
      render: renderTags,
    },
    {
      title: texts.photos,
      dataIndex: 'placeImages',
      key: 'placeImages',
      align: 'center' as const,
      render: imageGroup,
    },
    {
      title: texts.actions,
      dataIndex: 'actions',
      key: 'actions',
      align: 'center' as const,
      render: editButton,
    },
  ];
};

export default columns;
