import { gql, useMutation } from '@apollo/client';
import { MutationUploadNewsPostImageArgs, NewsPostImage } from 'apollo/generated/types';

const UPLOAD_NEWS_POST_IMAGE = gql`
  mutation uploadNewsPostImage($input: UploadNewsPostImageInput!) {
    uploadNewsPostImage(input: $input) {
      id
      type
      uploadUrl
      url
    }
  }
`;

const useUploadNewsPostImageMutation = () => {
  return useMutation<
    { uploadNewsPostImage: NewsPostImage },
    MutationUploadNewsPostImageArgs
  >(UPLOAD_NEWS_POST_IMAGE, {});
};
export default useUploadNewsPostImageMutation;
