import React, { FC, useState } from 'react';
import { Row, Col } from 'antd';
import { Table, Button, Switch } from 'components/UI';
import FormModal from 'components/sharedComponents/FormModal';
import * as texts from 'assets/texts/activityConfiguration';
import useListActivitiesQuery from 'apollo/resolvers/activity/list';
import useCreateActivityMutation from 'apollo/resolvers/activity/create';
import useEditActivityMutation from 'apollo/resolvers/activity/edit';
import useListPlacesQuery from 'apollo/resolvers/place/list';
import { Activity, CreateActivityInput, EditActivityInput } from 'apollo/types';
import { Mode, CreateFormValues } from 'components/sharedComponents/FormModal/types';
import setEditOrCreateForm from './utils/setEditOrCreateForm';
import { ActivityColumn } from './types';
import statusOnChange from './utils/statusOnChange';
import columns from './utils/createColumns';
import activityFormFields from './utils/formFields';
import formToInput from './utils/formToInput';
import dataToTable from './utils/dataToTable';
import mapPlaces from './utils/mapPlaces';
import styles from './ActivityConfiguration.module.scss';

const ActivityConfiguration: FC = () => {
  const { data, loading, refetch } = useListActivitiesQuery('1');
  const [createActivityMutation] = useCreateActivityMutation();
  const [editActivityMutation, { loading: activityEditLoading }] =
    useEditActivityMutation();
  const { data: places } = useListPlacesQuery();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<Activity>();
  const [modalMode, setModalMode] = useState<Mode | undefined>();

  const statusColumnRender = (value: boolean, record: unknown): JSX.Element => {
    const activity = record as ActivityColumn;
    return (
      <Switch
        defaultChecked={value}
        onClick={() => statusOnChange(activity, editActivityMutation)}
      />
    );
  };

  const editButton = (item: Activity): JSX.Element => (
    <Button
      onClick={() =>
        setEditOrCreateForm(item, setEditItem, setModalMode, setIsModalVisible)
      }
      type="primary"
      text={texts.editButton}
    />
  );

  const refetchFunction = async () => {
    await refetch();
  };

  const editMutation = async (formValues: EditActivityInput) => {
    const activityFormValues = { ...formValues, status: undefined };
    const item = await editActivityMutation({
      variables: { input: activityFormValues },
    });

    return item;
  };

  const createMutation = async (formValues: CreateFormValues) => {
    const activityFormValues = formValues as CreateActivityInput;
    const item = await createActivityMutation({
      variables: { input: activityFormValues },
    });

    return item;
  };

  return (
    <>
      <FormModal
        formToInput={formToInput}
        isModalVisible={isModalVisible}
        editOrCreateItem={editItem}
        setIsModalVisible={setIsModalVisible}
        formFields={activityFormFields(
          modalMode === 'create',
          texts.tooltip,
          mapPlaces(places?.listPlacesBackOffice),
          editItem
        )}
        title={modalMode === 'create' ? texts.createButton : texts.editTitle}
        mode={modalMode}
        editFormValues={editMutation}
        createFormValues={createMutation}
        refetch={refetchFunction}
      />
      <Row>
        <Col>
          <h2 className={styles.Title}>{texts.title}</h2>
        </Col>
      </Row>
      <Row className={styles.Row}>
        <Col>
          <Button
            onClick={() =>
              setEditOrCreateForm(undefined, setEditItem, setModalMode, setIsModalVisible)
            }
            text={texts.createButton}
            type="primary"
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            dataSource={dataToTable(data?.listActivitiesBackOffice)}
            loading={loading || activityEditLoading}
            columns={columns(statusColumnRender, editButton)}
          />
        </Col>
      </Row>
    </>
  );
};

export default ActivityConfiguration;
