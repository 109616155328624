import { gql, useMutation } from '@apollo/client';
import {
  EntranceTicketType,
  MutationEditEntranceTicketTypeArgs,
} from 'apollo/generated/types';

const EDIT_ENTRANCE_TICKET_TYPE = gql`
  mutation editEntranceTicketType($input: EditEntranceTicketTypeInput!) {
    editEntranceTicketType(input: $input) {
      adultValue
      description
      enDescription
      enName
      id
      maxStock
      name
      otherValue
      status
    }
  }
`;

const useEditEntranceTicketTypeMutation = () => {
  return useMutation<
    { editEntranceTicketType: EntranceTicketType },
    MutationEditEntranceTicketTypeArgs
  >(EDIT_ENTRANCE_TICKET_TYPE, {});
};
export default useEditEntranceTicketTypeMutation;
