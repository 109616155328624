import * as texts from 'assets/texts/gateValidation';

export default [
  {
    title: texts.entranceTicketType,
    dataIndex: 'ticketType',
    key: 'ticketType',
  },
  {
    title: texts.quantity,
    dataIndex: 'quantity',
    key: 'quantity',
  },
  {
    title: texts.percentageDiscount,
    dataIndex: 'percentageDiscount',
    key: 'percentageDiscount',
  },
  {
    title: texts.finalPrice,
    dataIndex: 'finalPrice',
    key: 'finalPrice',
  },
  {
    title: texts.visitDate,
    dataIndex: 'visitDate',
    key: 'visitDate',
  },
];
