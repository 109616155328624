/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

import { InputPromotionCodePack } from 'apollo/types';

const packsMapper = (formValues: any) => {
  const packs: InputPromotionCodePack[] = [];
  let counter = 1;

  Object.keys(formValues).forEach((key) => {
    if (key.includes('pack')) {
      const packsId = key.split('_')[2];
      const prop = key.split('_')[0];

      let maxQuantity = 0;
      let percentageDiscount = 0;
      let edited = false;

      if (prop === 'discountPercentage' && counter % 2 !== 0) {
        if (formValues[key] !== undefined) {
          edited = true;
          percentageDiscount = formValues[key] as number;
        } else {
          percentageDiscount = 0;
        }
        packs.push({
          packsId,
          maxQuantity,
          percentageDiscount,
          edited,
        });
        counter += 1;
      } else if (prop === 'maxTickets' && counter % 2 === 0) {
        if (formValues[key] !== undefined) {
          edited = true;
          maxQuantity = formValues[key] as number;
        } else {
          maxQuantity = 0;
        }
        packs.forEach((pack, index) => {
          if (pack.packsId === packsId) {
            packs[index] = {
              packsId,
              maxQuantity,
              percentageDiscount: packs[index].percentageDiscount,
              edited,
            };
            counter = 1;
          }
        });
      }
    }
  });

  return { packs };
};

export default packsMapper;
