import { FormInstance } from 'antd';
import { EditOrCreateItem, EditFormValues, FormToInput } from '../types';

const handleEdit = async (
  editOrCreateItem: EditOrCreateItem,
  form: FormInstance,
  formToInput: FormToInput,
  editFormValues: (formValues: EditFormValues) => Promise<unknown>,
  validateForm = false
) => {
  if (validateForm) {
    await form.validateFields();
  }
  if (editOrCreateItem) {
    const { id } = editOrCreateItem;
    const formValues = formToInput(form, id) as EditFormValues;
    if (!formValues) {
      // TODO: see error handling in frontend.
      return 'Error';
    }
    formValues.id = id;
    const editItem = await editFormValues(formValues);
    return editItem;
  }
  return null;
};

export default handleEdit;
