/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { ParkAvailability } from 'apollo/types';
import moment, { Moment } from 'moment';

const getParkAvailabilityForDay = (
  date: Moment,
  parkAvailabilities: ParkAvailability[]
): ParkAvailability | undefined => {
  return parkAvailabilities.find((parkAvailability) => {
    const { startDate, endDate } = parkAvailability;
    const parkAvailabilityStartDate = moment(startDate, 'YYYY-MM-DD');
    const parkAvailabilityEndDate = moment(endDate, 'YYYY-MM-DD');
    return date.isBetween(parkAvailabilityStartDate, parkAvailabilityEndDate);
  });
};

export default getParkAvailabilityForDay;
