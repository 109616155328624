import { gql, useLazyQuery } from '@apollo/client';
import { ListGiftCardsResponse, QueryListGiftCardsArgs } from 'apollo/generated/types';

const LIST_GIFT_CARDS = gql`
  query listGiftCards($input: ListGiftCardsInput!) {
    listGiftCards(input: $input) {
      count
      giftCards {
        to
        from
        totalAmount
        expirationDate
        remainingAmount
        purchase {
          id
        }
      }
    }
  }
`;

const useListGiftCardsLazyQuery = () => {
  return useLazyQuery<{ listGiftCards: ListGiftCardsResponse }, QueryListGiftCardsArgs>(
    LIST_GIFT_CARDS,
    {}
  );
};
export default useListGiftCardsLazyQuery;
