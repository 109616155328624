import { EnrichedClient } from 'apollo/types';

const dataToTable = (data: EnrichedClient[] | undefined) => {
  if (!data) return [];
  const mappedClients = data?.map((client, index) => {
    const {
      activityTicketsCount,
      entranceTicketsCount,
      documentNumber,
      email,
      firstName,
      lastName,
      phone,
      purchasesCount,
    } = client;
    return {
      key: index,
      activityTicketsCount,
      entranceTicketsCount,
      documentNumber,
      email,
      name: `${firstName} ${lastName}`,
      phone,
      purchasesCount,
      actions: client,
    };
  });
  return mappedClients;
};

export default dataToTable;
