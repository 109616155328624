import { Options } from 'components/UI/Form/types';

const options = () => {
  const hourOptions: Options = [];
  const n = 24;
  const hours = new Array(n).fill(null).map((_, i) => (i < 10 ? `0${i}` : `${i}`));

  hours.forEach((hour) => {
    hourOptions.push({ label: `${hour}:00`, value: `${hour}:00` });
    hourOptions.push({ label: `${hour}:30`, value: `${hour}:30` });
  });
  return hourOptions;
};

export default options;
