import { Col, Row } from 'antd';
import FormModal from 'components/sharedComponents/FormModal';
import { Button, ImagePreviewGroup, Table, UploadImages } from 'components/UI';
import React, { FC, useEffect, useState } from 'react';
import * as texts from 'assets/texts/thingsToDoConfiguration';
import useListThingsToDoQuery from 'apollo/resolvers/thingsToDo/list';
import {
  CreateThingToDoInput,
  ThingToDo,
  ThingToDoImage,
  UpdateThingToDoInput,
} from 'apollo/types';
import useCreateThingToDoMutation from 'apollo/resolvers/thingsToDo/create';
import useEditThingToDoMutation from 'apollo/resolvers/thingsToDo/edit';
import {
  CreateFormValues,
  EditFormValues,
} from 'components/sharedComponents/FormModal/types';
import useUploadThingsToDoImagesMutation from 'apollo/resolvers/thingsToDoImage/upload';
import useDeleteThingsToDoImageMutation from 'apollo/resolvers/thingsToDoImage/delete';
import styles from './ThingsToDoConfiguration.module.scss';
import setEditOrCreateForm from './utils/setEditOrCreateForm';
import thingsToDoFormFields from './utils/formFields';
import formToInput from './utils/formToInput';
import columns from './utils/createColumns';
import dataToTable from './utils/dataToTable';

const ThingsToDoConfiguration: FC = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<ThingToDo>();
  const [modalMode, setModalMode] = useState<'edit' | 'create' | undefined>();

  const { data, loading, refetch } = useListThingsToDoQuery();
  const [editThingToDoMutation] = useEditThingToDoMutation();
  const [createThingToDoMutation] = useCreateThingToDoMutation();
  const [uploadImage, { data: uploadImageData, loading: uploadImageLoading }] =
    useUploadThingsToDoImagesMutation();
  const [deleteImage, { loading: deleteImageLoading }] =
    useDeleteThingsToDoImageMutation();

  const editButton = (item: ThingToDo): JSX.Element => (
    <Button
      type="primary"
      text={texts.edit}
      onClick={() =>
        setEditOrCreateForm(item, setEditItem, setModalMode, setIsModalVisible)
      }
    />
  );

  const renderActionColumns = (item: ThingToDo): JSX.Element => {
    return (
      <Row>
        <Col span={24}>{editButton(item)}</Col>
      </Row>
    );
  };

  const createImageGroup = (entranceTicketImages: ThingToDoImage[]): JSX.Element => {
    const images = entranceTicketImages.map((image) => image.image || undefined);
    return <ImagePreviewGroup images={images} maxImagesToShow={2} widthImages={50} />;
  };

  const imageGroup = (item: ThingToDoImage[] | undefined): JSX.Element => {
    if (item && item.length > 0) {
      return createImageGroup(item);
    }
    return <></>;
  };

  const editMutation = async (formValues: EditFormValues) => {
    const thingToDoValues = formValues as UpdateThingToDoInput;
    const item = await editThingToDoMutation({
      variables: { input: thingToDoValues },
    });
    return item;
  };

  const createMutation = async (formValues: CreateFormValues) => {
    const thingToDoValues = formValues as CreateThingToDoInput;
    const item = await createThingToDoMutation({
      variables: { input: thingToDoValues },
    });
    return item;
  };

  const uploadThingsToDoImage = async (values: {
    id: bigint | undefined;
    parentEntityId: bigint;
    contentType: string;
    isMain: boolean;
  }) => {
    await uploadImage({
      variables: {
        input: {
          id: values.id?.toString(),
          thingsToDoId: values.parentEntityId.toString(),
          contentType: values.contentType,
        },
      },
    });
  };

  const deleteThingsToDoImage = async (values: {
    id: bigint;
    parentEntityId: bigint;
    isMain: boolean;
  }) => {
    await deleteImage({
      variables: {
        input: {
          id: values.id.toString(),
          thingsToDoId: values.parentEntityId.toString(),
        },
      },
    });
  };

  const refetchFunction = async () => {
    await refetch();
  };

  const uploadElement = (isMain = false) => {
    const images = editItem?.thingToDoImages || [];
    return (
      <UploadImages
        images={images}
        parentEntityId={BigInt(editItem?.id || 0)}
        uploadedImageId={
          uploadImageData?.uploadThingsToDoImages.id
            ? BigInt(uploadImageData?.uploadThingsToDoImages.id)
            : undefined
        }
        uploadUrl={uploadImageData?.uploadThingsToDoImages.uploadUrl || undefined}
        loading={uploadImageLoading || deleteImageLoading}
        isMain={isMain}
        uploadCallback={uploadThingsToDoImage}
        deleteCallback={deleteThingsToDoImage}
        refetchImagesCallback={refetchFunction}
      />
    );
  };

  const getThingsToDo = () => {
    if (data?.listThingsToDo) {
      if (editItem) {
        const foundItem = data.listThingsToDo.find((item) => item.id === editItem.id);
        if (foundItem) setEditItem(foundItem as unknown as ThingToDo);
      }
    }
  };

  useEffect(() => {
    getThingsToDo();
  }, [data?.listThingsToDo]);

  return (
    <>
      <FormModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        formFields={thingsToDoFormFields(
          modalMode === 'create',
          editItem,
          uploadElement()
        )}
        title={modalMode === 'create' ? texts.addThingToDo : texts.editTitle}
        mode={modalMode}
        refetch={refetch}
        editFormValues={editMutation}
        createFormValues={createMutation}
        formToInput={formToInput}
        editOrCreateItem={editItem}
      />
      <Row>
        <Col>
          <h2 className={styles.Title}>{texts.title}</h2>
        </Col>
      </Row>
      <Row className={styles.Row}>
        <Col>
          <Button
            type="primary"
            text={texts.addThingToDo}
            onClick={() =>
              setEditOrCreateForm(undefined, setEditItem, setModalMode, setIsModalVisible)
            }
          />
        </Col>
      </Row>
      <Row className={styles.Row}>
        <Col span={24}>
          <Table
            columns={columns(renderActionColumns, imageGroup)}
            dataSource={dataToTable(data?.listThingsToDo)}
            loading={loading}
            pagination={false}
            rowKey="name"
          />
        </Col>
      </Row>
    </>
  );
};

export default ThingsToDoConfiguration;
