const columns = () => {
  return [
    {
      title: 'N Orden',
      dataIndex: 'purchaseId',
      key: 'purchaseId',
    },
    {
      title: 'De',
      dataIndex: 'from',
      key: 'from',
    },
    {
      title: 'Para',
      dataIndex: 'to',
      key: 'to',
    },
    {
      title: 'Monto total',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
    },
    {
      title: 'Monto restante',
      dataIndex: 'remainingAmount',
      key: 'remainingAmount',
    },
    {
      title: 'Fecha de expiración',
      dataIndex: 'expirationDate',
      key: 'expirationDate',
    },
  ];
};

export default columns;
