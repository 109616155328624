import React, { FC } from 'react';
import { Form as AntdForm } from 'antd';
import { Form, Modal } from 'components/UI';
import * as texts from 'assets/texts/formModal';
import Button from 'components/UI/Button';
import styles from './FormModal.module.scss';
import { FormModalProps } from './types';
import handleCreate from './utils/handleCreate';
import handleEdit from './utils/handleEdit';
import handleOkForm from './utils/handleOkForm';
import handleCancelForm from './utils/handleCancelForm';

const FormModal: FC<FormModalProps> = (props: FormModalProps) => {
  const {
    formToInput,
    title,
    isModalVisible,
    setIsModalVisible,
    formFields,
    editOrCreateItem,
    mode,
    refetch,
    editFormValues,
    createFormValues,
    validateEditForm,
  } = props;
  const [form] = AntdForm.useForm();

  const handleCancelFunc = () => {
    handleCancelForm(form, setIsModalVisible);
  };

  const handleOkFunc = (redirectToAddImages = false) =>
    handleOkForm(
      mode,
      editOrCreateItem,
      form,
      redirectToAddImages,
      formToInput,
      editFormValues,
      handleEdit,
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      handleCreate,
      refetch,
      setIsModalVisible,
      createFormValues,
      validateEditForm
    );

  const setFooterButtons = (): JSX.Element[] => {
    const footerButtons = [
      <Button key={1} type="ghost" onClick={handleCancelFunc} text={texts.cancel} />,
      <Button
        key={2}
        type="primary"
        onClick={async () => {
          await handleOkFunc();
        }}
        text={mode === 'create' ? texts.create : texts.save}
      />,
    ];
    if (mode === 'create') {
      return [
        ...footerButtons,
        <Button
          key={3}
          type="primary"
          onClick={async () => {
            await handleOkFunc(true);
          }}
          text={texts.addImages}
        />,
      ];
    }
    return footerButtons;
  };

  return (
    <>
      <Modal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        footer={setFooterButtons()}
        onCancel={handleCancelFunc}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onOk={async () => {
          await handleOkFunc();
        }}
        title={title}
        className={styles.ModalTitle}
      >
        <Form
          preserve={false}
          formFields={formFields}
          form={form}
          isCreationForm={mode === 'create'}
        />
      </Modal>
    </>
  );
};

FormModal.defaultProps = {
  editOrCreateItem: undefined,
  mode: undefined,
};

export default FormModal;
