/* eslint-disable prefer-destructuring */
import { Filter, ListClientsBackofficeInput } from 'apollo/types';

const filterArrayToListClientsArgs = (filterArray: Filter[]) => {
  const listArgs: ListClientsBackofficeInput = {
    email: undefined,
    name: undefined,
    lastName: undefined,
    documentNumber: undefined,
    phone: undefined,
  };
  filterArray.forEach((filterForm) => {
    const { filterType, filter } = filterForm;
    if (filterType === 'email') {
      listArgs.email = filter;
    } else if (filterType === 'documentNumber') {
      listArgs.documentNumber = filter;
    } else if (filterType === 'phone') {
      listArgs.phone = filter;
    } else if (filterType === 'fullName' && filter) {
      const fullName = filter.split(' ');
      listArgs.name = fullName[0];
      listArgs.lastName = fullName[1];
    }
  });
  return listArgs;
};

export default filterArrayToListClientsArgs;
