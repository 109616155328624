import { FormInstance } from 'antd';
import { EditGrillInput } from 'apollo/generated/types';

const grillConfiguration = (form: FormInstance) => {
  const formValues = form.getFieldsValue(true) as EditGrillInput;
  const mappedFormValues = {
    name: formValues.name || '',
    status: true,
    mainDescription: formValues.mainDescription || '',
    secondaryDescription: formValues.secondaryDescription || '',
  };
  return mappedFormValues;
};

export default grillConfiguration;
