import { TicketTypes } from 'apollo/types';

const ticketTypesMapper = (ticketTypes: TicketTypes) => {
  const entranceTypes = {
    label: 'Entradas',
    options:
      ticketTypes?.listEntranceTicketTypesBackOffice.map((type) => {
        return { label: type.name, value: type.name };
      }) || [],
  };
  const activityTypes = {
    label: 'Actividades',
    options:
      ticketTypes?.listActivitiesBackOffice.map((type) => {
        return { label: type.name, value: type.name };
      }) || [],
  };
  return [entranceTypes, activityTypes];
};

export default ticketTypesMapper;
