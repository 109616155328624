import React, { FC } from 'react';
import { Form as AntdForm, Input, Divider, Row, Col, DatePicker, Select } from 'antd';
import * as texts from 'assets/texts/newsPostConfiguration';
import RichTextEditor from '@mantine/rte';
import { FormInstance } from 'antd/es/form';
import { NewsPost } from 'apollo/types';
import { Button, UploadImages } from 'components/UI';
import { CreateNewsPostFormValues } from 'views/Configuration/NewsPostConfiguration/types';
import moment from 'moment';
import styles from './Form.module.scss';
import useFormViewModel from './form.viewModel';

export interface FormProps {
  initialValues?: NewsPost;
  onFormFinished: (values: CreateNewsPostFormValues, formInstance: FormInstance) => void;
  onCloseModal: () => void;
}

export const Form: FC<FormProps> = ({
  initialValues,
  onFormFinished,
  onCloseModal,
}: FormProps) => {
  const {
    mapCurrentRecommendations,
    uploadImageLoading,
    onCoverImageUpload,
    deleteCoverImage,
    refetchCoverImage,
    coverImage,
    onMainImageUpload,
    deleteMainImage,
    refetchMainImage,
    closingImages,
    onClosingImageUpload,
    deleteClosingImage,
    refetchClosingImage,
    maxClosingImages,
    recommendationIds,
    onChangeRecommendationIds,
    recommendationOptions,
    mainImage,
    maxRecommendationsSelected,
    form,
    onCloseModalButtonClicked,
  } = useFormViewModel(initialValues);
  const { Item } = AntdForm;

  const initValues = {
    ...initialValues,
    publicationDate: initialValues?.publicationDate
      ? moment(initialValues?.publicationDate)
      : null,
    recommendationIds: mapCurrentRecommendations(initialValues),
    content: initialValues?.contents?.map((content) => content.content),
    closingImagesIds: initialValues?.closingImages?.map((image) => image.id) || [],
    authorId: initialValues?.author,
  };

  const mappedRecommendationOptions = recommendationOptions?.map((item) => ({
    ...item,
    disabled:
      recommendationIds.length >= maxRecommendationsSelected
        ? !recommendationIds.includes(String(item.value))
        : false,
  }));

  const rules = initialValues ? undefined : [{ required: true, message: texts.required }];

  const renderContentRichTextEditors = () => {
    return (
      <>
        <Item name={['content', '0']} label={texts.firstContent} rules={rules}>
          <RichTextEditor
            value=""
            onChange={(value) => {
              form.setFieldsValue({ [`content[0]`]: value });
            }}
          />
        </Item>

        <Item name={['content', '1']} label={texts.secondContent} rules={rules}>
          <RichTextEditor
            value=""
            onChange={(value) => {
              form.setFieldsValue({ [`content[1]`]: value });
            }}
          />
        </Item>
      </>
    );
  };

  const renderModalFooterButtons = () => {
    return (
      <Row justify="end">
        <Col>
          <Row justify="space-between" gutter={[4, 0]}>
            <Col>
              <Button
                type="default"
                text={texts.cancel}
                onClick={() => onCloseModalButtonClicked(onCloseModal)}
              />
            </Col>
            <Col>
              {!initialValues ? (
                <Button type="default" text={texts.create} htmlType="submit" />
              ) : (
                <Button type="default" text={texts.save} htmlType="submit" />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <AntdForm
      form={form}
      layout="vertical"
      initialValues={initValues}
      onFinish={(values: CreateNewsPostFormValues) => onFormFinished(values, form)}
    >
      <Item name="title" label={texts.title} rules={rules}>
        <Input />
      </Item>
      <Item name="publicationDate" label={texts.publicationDate} rules={rules}>
        <DatePicker />
      </Item>
      <Divider />
      <Item name={['coverImage', 'id']} label={texts.coverImage} rules={rules}>
        <UploadImages
          images={coverImage}
          parentEntityId={BigInt(initialValues?.id || 0)}
          uploadedImageId={coverImage.length ? BigInt(coverImage[0].id) : undefined}
          uploadUrl={coverImage.length ? coverImage[0].uploadUrl || '' : undefined}
          loading={uploadImageLoading}
          isMain
          uploadCallback={onCoverImageUpload}
          deleteCallback={deleteCoverImage}
          refetchImagesCallback={refetchCoverImage}
          refetchOnDelete={false}
        />
      </Item>
      <Divider />
      <div className={styles.GroupName}>
        <b>{texts.mainImage}</b>
      </div>
      <Item name={['mainImage', 'id']} label={texts.image} rules={rules}>
        <UploadImages
          images={mainImage}
          parentEntityId={BigInt(initialValues?.id || 0)}
          uploadedImageId={mainImage.length ? BigInt(mainImage[0].id) : undefined}
          uploadUrl={mainImage.length ? mainImage[0].uploadUrl || '' : undefined}
          loading={uploadImageLoading}
          isMain
          uploadCallback={onMainImageUpload}
          deleteCallback={deleteMainImage}
          refetchImagesCallback={refetchMainImage}
          refetchOnDelete={false}
        />
      </Item>
      <Item name={['mainImage', 'caption']} label={texts.caption}>
        <Input />
      </Item>
      <Divider />
      <Item name="closingImagesIds" label={texts.closingImages}>
        <UploadImages
          images={closingImages}
          parentEntityId={BigInt(initialValues?.id || 0)}
          uploadedImageId={
            closingImages.length
              ? BigInt(closingImages[closingImages.length - 1].id)
              : undefined
          }
          uploadUrl={
            closingImages.length
              ? closingImages[closingImages.length - 1].uploadUrl || ''
              : undefined
          }
          loading={uploadImageLoading}
          uploadCallback={onClosingImageUpload}
          deleteCallback={deleteClosingImage}
          refetchImagesCallback={refetchClosingImage}
          maxNumberOfImages={maxClosingImages}
          refetchOnDelete={false}
        />
      </Item>
      <Divider />
      {renderContentRichTextEditors()}
      <Divider />
      <Item name="recommendationIds" label={texts.recommendations}>
        <Select
          mode="multiple"
          options={mappedRecommendationOptions}
          onChange={onChangeRecommendationIds}
        />
      </Item>
      <Item name="author" label={texts.author} rules={rules}>
        <Input />
      </Item>
      {renderModalFooterButtons()}
    </AntdForm>
  );
};

Form.defaultProps = {
  initialValues: undefined,
};
