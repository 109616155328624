import React, { FC } from 'react';
import { Button as AntdButton } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import styles from './Button.module.scss';

type Props = ButtonProps & {
  type: string;
  text?: string;
  onClick?: () => Promise<void> | void;
};

const Button: FC<Props> = (props: Props) => {
  const { type, text, onClick, ...otherProps } = props;
  let className;
  switch (type) {
    case 'primary':
      className = styles.PrimaryButton;
      break;
    case 'ghost':
      className = styles.CancelButton;
      break;
    case 'default':
      className = styles.ActionButton;
      break;
    default:
      className = styles.PrimaryButton;
  }
  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <AntdButton className={className} {...otherProps} onClick={onClick}>
        {text}
      </AntdButton>
    </>
  );
};

Button.defaultProps = {
  text: '',
  onClick: undefined,
};

export default Button;
