import { FormInstance } from 'antd';
import { UpdateThingToDoInput } from 'apollo/types';
import { ThingToDoCreate, ThingToDoEdit } from '../types';

const formToInput = (
  form: FormInstance,
  id?: string
): ThingToDoEdit | ThingToDoCreate => {
  const formValues = form.getFieldsValue(true) as UpdateThingToDoInput;

  const mappedFormValues = {
    description: formValues.description ? formValues.description : undefined,
    name: formValues.name ? formValues.name : undefined,
  };

  if (id) {
    return { id, ...mappedFormValues };
  }

  return mappedFormValues;
};

export default formToInput;
