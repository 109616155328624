const columns = () => {
  return [
    {
      title: 'Rut',
      dataIndex: 'documentNumber',
      key: 'documentNumber',
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Teléfono',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'N° Compras',
      dataIndex: 'purchasesCount',
      key: 'purchasesCount',
    },
    {
      title: 'N° Entradas',
      dataIndex: 'entranceTicketsCount',
      key: 'entranceTicketsCount',
    },
    {
      title: 'N° actividades',
      dataIndex: 'activityTicketsCount',
      key: 'activityTicketsCount',
    },
  ];
};

export default columns;
