import { ThingToDo } from 'apollo/types';
import { ThingsToDoOptions } from '../types';

const thingsToDoMapper = (thingsToDo: ThingToDo[]): ThingsToDoOptions => {
  const thingsToDoOptions = {
    label: 'Experiencias',
    options:
      thingsToDo?.map((thingToDo) => {
        return { label: thingToDo.name, value: thingToDo.id };
      }) || [],
  };
  return [thingsToDoOptions];
};

export default thingsToDoMapper;
