import { gql, useMutation } from '@apollo/client';
import {
  EntranceTicketType,
  MutationCreateEntranceTicketTypeArgs,
} from 'apollo/generated/types';

const CREATE_ENTRANCE_TICKET_TYPE = gql`
  mutation createEntranceTicketType($input: CreateEntranceTicketTypeInput!) {
    createEntranceTicketType(input: $input) {
      description
      enDescription
      enName
      id
      maxStock
      otherValue
      name
      status
      adultValue
    }
  }
`;

const useCreateEntranceTicketTypeMutation = () => {
  return useMutation<
    { createEntranceTicketType: EntranceTicketType },
    MutationCreateEntranceTicketTypeArgs
  >(CREATE_ENTRANCE_TICKET_TYPE, {});
};
export default useCreateEntranceTicketTypeMutation;
