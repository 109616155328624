import { gql, useQuery } from '@apollo/client';
import { TicketTypes } from 'apollo/types';

const LIST_TICKET_TYPES = gql`
  query listTicketTypes {
    listEntranceTicketTypesBackOffice {
      id
      name
    }
    listActivitiesBackOffice {
      id
      name
    }
  }
`;

const uselistTicketTypesQuery = () => {
  return useQuery<TicketTypes>(LIST_TICKET_TYPES, {});
};
export default uselistTicketTypesQuery;
