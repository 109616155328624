import jwt_decode from 'jwt-decode';
import userPool from 'auth/userPool';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import AUTH_GROUPS from './groups';
import { UserAuth } from './types';

export const USER_AUTH_KEY = 'UserAuth';
/** Check if a token has expired
 * @param userAuth - user auth object
 */

export const cleanStorage = (): void => {
  window.localStorage.clear();
};

export const setStorage = (userAuth: UserAuth) => {
  window.localStorage.setItem(USER_AUTH_KEY, JSON.stringify(userAuth));
};

export const DEFAULT_USER_AUTH = Object.freeze({
  userId: 0,
  username: '',
  idToken: '',
  accessToken: '',
  authenticated: false,
});

export const getStoredUserAuth = (): UserAuth => {
  const auth = window.localStorage.getItem(USER_AUTH_KEY);
  if (auth) {
    return JSON.parse(auth) as UserAuth;
  }
  return DEFAULT_USER_AUTH;
};

const refreshToken = () => {
  let success = false;
  const cognitoUser = userPool.getCurrentUser();
  cognitoUser?.getSession((err: unknown, session: CognitoUserSession) => {
    const cognitoRefreshToken = session.getRefreshToken();
    cognitoUser.refreshSession(
      cognitoRefreshToken,
      (_err, result: CognitoUserSession) => {
        if (_err) {
          // eslint-disable-next-line no-console
          console.log('Error refreshing token:', _err);
        } else {
          const userAuth = getStoredUserAuth();
          setStorage({
            userId: result.getIdToken().payload.sub as string,
            idToken: result.getIdToken().getJwtToken(),
            authenticated: true,
            username: userAuth.username,
            accessToken: result.getAccessToken().getJwtToken(),
          });
          success = true;
        }
      }
    );
  });
  return success;
};

export const checkIfTokenIsValid = (): boolean => {
  const userAuth = getStoredUserAuth();
  if (!userAuth || !userAuth.idToken) {
    window.localStorage.setItem(USER_AUTH_KEY, JSON.stringify(DEFAULT_USER_AUTH));
    return false;
  }

  try {
    const decodedJwt: { exp: number } = jwt_decode(userAuth.idToken);
    if (decodedJwt.exp >= Date.now() / 1000) {
      return true;
    }
    if (refreshToken()) {
      return true;
    }
    window.localStorage.setItem(USER_AUTH_KEY, JSON.stringify(DEFAULT_USER_AUTH));
    return false;
  } catch (e) {
    return false;
  }
};

export const checkIfUserCanAccess = (group: string): boolean => {
  if (group === AUTH_GROUPS.ALL) return true;
  const userAuth = localStorage.getItem(USER_AUTH_KEY);
  if (userAuth) {
    const { userRole } = JSON.parse(userAuth) as UserAuth;
    if (userRole === group) {
      return true;
    }
  }
  return false;
};

export const checkTokenGroups = (group: string): boolean => {
  const userAuth = getStoredUserAuth();
  if (group === AUTH_GROUPS.ALL) return true;
  if (userAuth && userAuth.accessToken) {
    const decodedJwt: { 'cognito:groups': string[] | undefined } = jwt_decode(
      userAuth.accessToken
    );
    const { 'cognito:groups': decodedGroups } = decodedJwt;
    if (decodedGroups) {
      if (decodedGroups.includes(group)) {
        return true;
      }
    }
  }
  return false;
};
