import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { Button, Form, Input } from 'antd';
import getAttributes from 'auth/methods/getAttributes';
import logOut from 'auth/methods/logOut';
import LoadingModal from 'components/UI/LoadingModal';
import useErrorHandler from 'hooks/useErrorHandler';
import React, { FC, useEffect, useState } from 'react';
import * as texts from 'assets/texts/profile';
import styles from './Profile.module.scss';

const Profile: FC = () => {
  const [loading, setLoading] = useState(false);
  const [attributes, setAttributes] = useState<CognitoUserAttribute[]>([]);
  const { showError } = useErrorHandler(null);
  const onSuccess = (data: CognitoUserAttribute[]) => {
    setLoading(false);
    setAttributes(data);
  };
  const onError = (error: Error) => {
    setLoading(false);
    showError(error);
  };
  const onLoading = () => {
    setLoading(true);
  };
  useEffect(() => {
    // TO DO: Map the attribute array into a object
    getAttributes(onSuccess, onLoading, onError);
  }, []);
  return (
    <div className={styles.MainContainer}>
      <LoadingModal visible={loading} />
      <h1>{texts.title}</h1>

      <Form
        name="basic"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        className={styles.Form}
      >
        {attributes.map(({ Name, Value }) => (
          <Form.Item label={Name} name={Name} key={Name}>
            <Input disabled defaultValue={Value} />
          </Form.Item>
        ))}
      </Form>
      <Button onClick={logOut}>{texts.logOutButton}</Button>
    </div>
  );
};

export default Profile;
