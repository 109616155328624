import { Program } from 'apollo/types';

const dataToTable = (data: Program[] | undefined) => {
  if (!data) return [];
  const mappedThingsToDo = data?.map((program, index) => {
    const {
      id,
      title,
      subtitle,
      description,
      validForWeekends,
      status,
      hoursDuration,
      targetGroup,
      videoLink,
      coverImage,
      secondaryImages,
    } = program;

    const programImages = [];
    if (coverImage) {
      programImages.push(coverImage);
    }
    if (secondaryImages) {
      programImages.push(...secondaryImages);
    }

    return {
      key: index,
      id,
      title,
      subtitle,
      description,
      validForWeekends,
      status,
      hoursDuration,
      targetGroup,
      videoLink,
      programImages,
      actions: program,
    };
  });
  return mappedThingsToDo;
};

export default dataToTable;
