import { Program } from 'apollo/types';
import { FormFields } from 'components/UI/Form/types';

const programFormFields = (
  editableRow: Program,
  coverLabel: JSX.Element,
  uploadCoverImageElement: JSX.Element,
  uploadSecondaryImageElement: JSX.Element
): FormFields[] => {
  const formFields = [
    {
      name: 'title',
      label: 'Título',
      type: 'text',
      initialValue: editableRow.title,
      rules: [{ required: true }],
    },
    {
      name: 'subtitle',
      label: 'Subtítulo',
      type: 'simpleTextArea',
      initialValue: editableRow.subtitle,
      rules: [{ required: true }],
    },
    {
      name: 'description',
      label: 'Descripción',
      type: 'textarea',
      initialValue: editableRow.description,
      rules: [{ required: true }],
    },
    {
      name: 'targetGroup',
      label: 'Rango de edades',
      type: 'text',
      initialValue: editableRow.targetGroup,
      rules: [{ required: true }],
    },
    {
      name: 'hoursDuration',
      label: 'Duración en horas',
      type: 'number',
      initialValue: editableRow.hoursDuration,
      rules: [{ required: true }],
    },
    {
      name: 'videoLink',
      label: 'Link de video',
      type: 'text',
      initialValue: editableRow.videoLink || '',
    },
    {
      name: 'coverImage',
      label: coverLabel,
      type: 'images',
      uploadelement: uploadCoverImageElement,
      rules: [{ required: false }],
      colon: false,
    },
    {
      name: 'secondaryImages',
      label: 'Galería de imágenes',
      type: 'images',
      uploadelement: uploadSecondaryImageElement,
      rules: [{ required: false }],
    },
  ];

  return formFields;
};

export default programFormFields;
