import { EntranceTicketsGrouped } from 'apollo/types';
import { GroupedEntranceTicketsRow } from '../types';

const mapGroupedEntranceTickets = (
  data?: EntranceTicketsGrouped[]
): GroupedEntranceTicketsRow[] => {
  if (!data) return [];
  return data.map((groupedEntranceTicket, index) => {
    const mappedGroupedEntrance: GroupedEntranceTicketsRow = {
      key: groupedEntranceTicket.entranceTicketTypeName || index,
      type: 'entrance',
      ticketType: groupedEntranceTicket.entranceTicketTypeName || '',
      availableAmount: groupedEntranceTicket.stock,
      soldAmount: groupedEntranceTicket.count,
      adultTickets: groupedEntranceTicket.adultCount,
      otherTickets: groupedEntranceTicket.kidsOrElderlyCount,
      registeredVisits: groupedEntranceTicket.visitedCount,
    };
    return mappedGroupedEntrance;
  });
};

export default mapGroupedEntranceTickets;
