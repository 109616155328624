import React, { FC, ReactElement } from 'react';
import { Card as AntdCard, Progress, Skeleton } from 'antd';
import { CardProps } from 'antd/lib/card/index';
import { TeamOutlined, TagOutlined, FireOutlined, BugOutlined } from '@ant-design/icons';
import Aviary from 'assets/images/aviary.png';
import styles from './Card.module.scss';

type ItemProps = {
  cardStyle: string;
  type: 'normal' | 'small' | undefined;
  percent: number | undefined;
  soldTickets: number | undefined;
};

const Item: FC<ItemProps> = (props: ItemProps): ReactElement => {
  const { cardStyle, type, percent, soldTickets } = props;
  switch (cardStyle) {
    case 'standardCard':
      return <TeamOutlined className={styles.Icon} />;
    case 'reservationsCard':
      return (
        <Progress
          type="circle"
          percent={percent}
          strokeColor="#f26d31"
          format={() => `${soldTickets || 0}`}
        />
      );
    case 'availableTickets':
      if (type === 'small') {
        return <></>;
      }
      return <TagOutlined className={styles.Icon} />;
    case 'aviaryCard':
      return <img src={Aviary} alt="aviaryIcon" className={styles.IconImage} />;
    case 'nurseryGardenCard':
      return <BugOutlined className={styles.Icon} />;
    case 'grillCard':
      return <FireOutlined className={styles.Icon} />;
    default:
      return <></>;
  }
};

type Props = CardProps & {
  cardStyle: string;
  subTitle?: string;
  percent?: number;
  soldTickets?: number;
  footer: string;
  availableTickets?: string;
  ticketButton?: React.ReactNode;
  cardType?: 'normal' | 'small';
};

const Card: FC<Props> = (props: Props): ReactElement => {
  const {
    cardStyle,
    title,
    subTitle,
    footer,
    percent,
    soldTickets,
    loading,
    availableTickets,
    ticketButton,
    cardType,
    ...otherProps
  } = props;

  const content =
    cardType === 'small' ? (
      <>
        <p>{footer}</p>
        <div className={styles.CountContainer}>
          <TagOutlined className={styles.SmallIcon} />
          <p className={styles.SmallTitle}>{title}</p>
        </div>
      </>
    ) : (
      <>
        <h1 className={styles.Title}>{title}</h1>
        <p className={styles.Subtitle}>{subTitle}</p>
        <p>{footer}</p>
      </>
    );

  const activityCards = ['aviaryCard', 'nurseryGardenCard', 'grillCard'];
  return (
    <AntdCard {...otherProps} className={styles.Card}>
      <Skeleton loading={loading} active>
        <Item
          cardStyle={cardStyle}
          type={cardType}
          percent={percent}
          soldTickets={soldTickets}
        />
        {content}
        {activityCards.includes(cardStyle) && (
          <>
            <Progress
              className={styles.ProgressBar}
              percent={percent}
              strokeColor="#21b367"
              strokeWidth={20}
            />
            <p>{availableTickets}</p>
            <div>{ticketButton}</div>
          </>
        )}
      </Skeleton>
    </AntdCard>
  );
};

export default Card;

Card.defaultProps = {
  subTitle: undefined,
  percent: undefined,
  soldTickets: undefined,
  availableTickets: undefined,
  ticketButton: undefined,
  cardType: undefined,
};
