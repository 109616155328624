import { OpenDay } from 'apollo/types';
import moment from 'moment';

const mapOpenDays = (listOpenDays?: OpenDay[]): number[] | undefined => {
  const momentOpenDays = listOpenDays?.map((day) => {
    const dayDate = Number(day.day.split('T')[0].split('-')[2]);
    const mappedDate = new Date(day.day);
    if (dayDate > mappedDate.getDate()) {
      mappedDate.setDate(mappedDate.getDate() + 1);
    }
    return moment(mappedDate).startOf('day').valueOf();
  });
  return momentOpenDays;
};

export default mapOpenDays;
