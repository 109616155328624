import Home from 'views/Home';
import LogIn from 'views/LogIn';
import { FunctionComponent } from 'react';
import Profile from 'views/Profile';
import ActivityConfiguration from 'views/Configuration/ActivityConfiguration';
import EntranceConfiguration from 'views/Configuration/EntranceConfiguration';
import GrillConfiguration from 'views/Configuration/GrillConfiguration';
import OpenDays from 'views/OpenDays';
import AUTH_GROUPS from 'auth/groups';
import PackConfiguration from 'views/Configuration/PackConfiguration';
import Reservation from 'views/Purchases/Reservation';
import Validation from 'views/Purchases/Validation';
import Report from 'views/Report';
import PlaceConfiguration from 'views/Configuration/PlaceConfiguration';
import Clients from 'views/Clients';
import Mailer from 'views/Mailer';
import GateValidation from 'views/GateValidation';
import ThingsToDoConfiguration from 'views/Configuration/ThingsToDoConfiguration';
import PromotionCodeConfiguration from 'views/Configuration/PromotionCodeConfiguration';
import NewsBannerConfiguration from 'views/Configuration/NewsBannerConfiguration';
import ProgramConfiguration from 'views/Configuration/ProgramConfiguration';
import NewsPostConfiguration from 'views/Configuration/NewsPostConfiguration';
import GiftCards from 'views/GiftCards';

export type Route = {
  title: string;
  path: string;
  component: FunctionComponent;
  exact: boolean;
  private: boolean;
  group: string;
  navigatedView: boolean;
};

const generalRoutes: Route[] = [
  {
    title: 'Log In',
    path: '/log-in',
    component: LogIn,
    exact: true,
    private: false,
    group: AUTH_GROUPS.ALL,
    navigatedView: false,
  },
  {
    title: 'Profile',
    path: '/profile',
    component: Profile,
    exact: true,
    private: true,
    group: AUTH_GROUPS.ALL,
    navigatedView: false,
  },
  {
    title: 'OpenDays',
    path: '/open-days',
    component: OpenDays,
    exact: true,
    private: true,
    group: AUTH_GROUPS.ADMIN,
    navigatedView: true,
  },
  {
    title: 'Clients',
    path: '/clients',
    component: Clients,
    exact: true,
    private: true,
    group: AUTH_GROUPS.ADMIN,
    navigatedView: true,
  },
  {
    title: 'GiftCards',
    path: '/giftCards',
    component: GiftCards,
    exact: true,
    private: true,
    group: AUTH_GROUPS.ADMIN,
    navigatedView: true,
  },
  {
    title: 'Mailer',
    path: '/mailer',
    component: Mailer,
    exact: true,
    private: true,
    group: AUTH_GROUPS.ADMIN,
    navigatedView: true,
  },
];

const homeRoutes: Route[] = [
  {
    title: 'Home',
    path: '/home/dashboard',
    component: Home,
    exact: true,
    private: true,
    group: AUTH_GROUPS.ALL,
    navigatedView: true,
  },
  {
    title: 'Reporte anual',
    path: '/home/report',
    component: Report,
    exact: true,
    private: true,
    group: AUTH_GROUPS.ADMIN,
    navigatedView: true,
  },
];

const purchasesRoutes: Route[] = [
  {
    title: 'Reservations',
    path: '/purchases/reservations',
    component: Reservation,
    exact: true,
    private: true,
    group: AUTH_GROUPS.ALL,
    navigatedView: true,
  },
  {
    title: 'Validations',
    path: '/purchases/validations',
    component: Validation,
    exact: true,
    private: true,
    group: AUTH_GROUPS.ALL,
    navigatedView: true,
  },
];

const configurationRoutes: Route[] = [
  {
    title: 'Activity Configuration',
    path: '/configuration/activities',
    component: ActivityConfiguration,
    exact: true,
    private: true,
    group: AUTH_GROUPS.ADMIN,
    navigatedView: true,
  },
  {
    title: 'Entrance Configuration',
    path: '/configuration/entrances',
    component: EntranceConfiguration,
    exact: true,
    private: true,
    group: AUTH_GROUPS.ADMIN,
    navigatedView: true,
  },
  {
    title: 'Grill Configuration',
    path: '/configuration/grills',
    component: GrillConfiguration,
    exact: true,
    private: true,
    group: AUTH_GROUPS.ADMIN,
    navigatedView: true,
  },
  {
    title: 'Pack Configuration',
    path: '/configuration/packs',
    component: PackConfiguration,
    exact: true,
    private: true,
    group: AUTH_GROUPS.ADMIN,
    navigatedView: true,
  },
  {
    title: 'Place Configuration',
    path: '/configuration/places',
    component: PlaceConfiguration,
    exact: true,
    private: true,
    group: AUTH_GROUPS.ADMIN,
    navigatedView: true,
  },
  {
    title: 'Things Todo Configuration',
    path: '/configuration/thingsTodo',
    component: ThingsToDoConfiguration,
    exact: true,
    private: true,
    group: AUTH_GROUPS.ADMIN,
    navigatedView: true,
  },
  {
    title: 'News Banner Configuration',
    path: '/configuration/newsBanner',
    component: NewsBannerConfiguration,
    exact: true,
    private: true,
    group: AUTH_GROUPS.ADMIN,
    navigatedView: true,
  },
  {
    title: 'Program Configuration',
    path: '/configuration/programs',
    component: ProgramConfiguration,
    exact: true,
    private: true,
    group: AUTH_GROUPS.ADMIN,
    navigatedView: true,
  },
  {
    title: 'News Post Configuration',
    path: '/configuration/newsPosts',
    component: NewsPostConfiguration,
    exact: true,
    private: true,
    group: AUTH_GROUPS.ADMIN,
    navigatedView: true,
  },
];

const promotionCodeRoute: Route[] = [
  {
    title: 'Promotion Code Configuration',
    path: '/promotionCodes',
    component: PromotionCodeConfiguration,
    exact: true,
    private: true,
    group: AUTH_GROUPS.ADMIN,
    navigatedView: true,
  },
];

const gateRoutes: Route[] = [
  {
    title: 'Gate Validation',
    path: '/gate/validation',
    component: GateValidation,
    exact: true,
    private: true,
    group: AUTH_GROUPS.ALL,
    navigatedView: true,
  },
];

const routes: Route[] = [
  ...generalRoutes,
  ...homeRoutes,
  ...purchasesRoutes,
  ...configurationRoutes,
  ...promotionCodeRoute,
  ...gateRoutes,
];

export default routes;
