import { UploadFile } from 'antd/lib/upload/interface';

interface IImage {
  id: bigint;
  image: string;
  url: string;
}

const mapImages = <T>(_data?: T[]): UploadFile[] => {
  if (!_data) return [];
  const data = _data as unknown as IImage[];
  return data.map((image) => {
    const mappedImage: UploadFile = {
      uid: image.id.toString(),
      name: image.id.toString(),
      url: image.image || image.url,
    };
    return mappedImage;
  });
};

export default mapImages;
