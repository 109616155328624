import { Activity } from 'apollo/types';

const columns = (
  statusColumnRender: (value: boolean, record: unknown) => JSX.Element,
  editButton: (EditItem: Activity) => JSX.Element
) => {
  return [
    {
      title: 'Tipo de ticket',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Precio ticket',
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: 'Cantidad de Horarios',
      dataIndex: 'activityTimes',
      key: 'activityTimes',
    },
    {
      title: 'Stock tickets por horario',
      dataIndex: 'maxStock',
      key: 'maxStock',
    },
    {
      title: 'Lugar',
      dataIndex: 'place',
      key: 'place',
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: statusColumnRender,
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: editButton,
    },
  ];
};

export default columns;
