import { gql, useMutation } from '@apollo/client';
import { EntranceImage, MutationUploadEntranceImageArgs } from 'apollo/generated/types';

const UPLOAD_ENTRANCE_IMAGE = gql`
  mutation uploadEntranceImage($input: UploadEntranceImageInput!) {
    uploadEntranceImage(input: $input) {
      id
      image
      uploadUrl
    }
  }
`;

const useUploadEntranceImageMutation = () => {
  return useMutation<
    { uploadEntranceImage: EntranceImage },
    MutationUploadEntranceImageArgs
  >(UPLOAD_ENTRANCE_IMAGE);
};
export default useUploadEntranceImageMutation;
