import { CognitoUser } from 'amazon-cognito-identity-js';
import { getStoredUserAuth } from 'auth/storage';
import userPool from 'auth/userPool';
import * as texts from 'assets/texts/auth';

const confirm = (
  code: string,
  username?: string,
  onSuccessFunction?: () => void,
  onLoadingFunction?: () => void,
  onErrorFunction?: (error: Error) => void
) => {
  if (onLoadingFunction) onLoadingFunction();
  const userData = {
    Username: username || getStoredUserAuth().username,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  cognitoUser.confirmRegistration(code, true, (error: Error, result) => {
    if (error) {
      if (onErrorFunction) onErrorFunction(error);
    }
    if (result === 'SUCCESS') {
      if (onSuccessFunction) onSuccessFunction();
    } else if (onErrorFunction) {
      onErrorFunction({ message: texts.confirmError } as Error);
    }
  });
};

export default confirm;
