import { BillingDocumentType } from 'apollo/types';
import moment from 'moment';

const inputs = (date: string) => [
  {
    name: 'N° de orden',
    variableName: 'orderNumber',
    type: 'string',
    placeholder: 'Ej: 0000',
  },
  {
    name: 'Rut',
    variableName: 'rut',
    type: 'string',
    placeholder: 'Ej: 11.111.111-1',
  },
  {
    name: 'Fecha de visita',
    variableName: 'dateVisit',
    type: 'date',
    initialValue: moment(date),
  },
  {
    name: 'Tipo de ticket',
    variableName: 'ticketType',
    type: 'select',
    mode: 'multiple' as const,
    placeholder: 'Todos',
    options: [],
  },
  {
    name: 'Estado',
    variableName: 'disabled',
    type: 'select',
    placeholder: 'Todos',
    options: [
      {
        label: 'Todos',
        value: 'all',
      },
      {
        label: 'Habilitado',
        value: 'enabled',
      },
      {
        label: 'Deshabilitado',
        value: 'disabled',
      },
    ],
  },
  {
    name: 'Número de documento',
    variableName: 'billingSystemDocumentNumber',
    type: 'number',
    placeholder: 'Ej: 111111',
  },
  {
    name: 'Tipo de documento',
    variableName: 'billingSystemDocumentType',
    type: 'select',
    placeholder: 'Todos',
    options: [
      {
        label: 'Todos',
        value: null,
      },
      {
        label: 'Boleta',
        value: BillingDocumentType.Voucher,
      },
      {
        label: 'Factura',
        value: BillingDocumentType.Invoice,
      },
    ],
  },
];

export default inputs;
