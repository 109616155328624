import { Activity, EntranceTicketTypeBackOffice } from 'apollo/types';
import { Options } from 'components/UI/Form/types';

type NameId = {
  name: string;
  id: string;
};

const mapOptions = (
  objects: Activity[] | EntranceTicketTypeBackOffice[] | undefined
): Options | undefined => {
  const typedObjects = objects as NameId[];
  const labelValue = typedObjects?.map((obj) => {
    return { label: obj.name || '', value: Number(obj.id) };
  });
  return labelValue;
};

export default mapOptions;
