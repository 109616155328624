import { ApolloQueryResult, OperationVariables } from '@apollo/client';
import { NewsBanner } from 'apollo/types';
import { Button, Form } from 'components/UI';
import React, { FC } from 'react';
import { Form as AntdForm, message } from 'antd';
import useCreateNewsBannerMutation from 'apollo/resolvers/newsBanner/create';
import useUpdateNewsBannerMutation from 'apollo/resolvers/newsBanner/update';
import newsBannerFormFields from './utils/formFields';
import * as texts from '../../../../assets/texts/newsBanner';

export interface NewsBannerFormProps {
  isCreationForm: boolean;
  newsBanner?: NewsBanner;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<
    ApolloQueryResult<{
      getNewsBanner: NewsBanner;
    }>
  >;
}

const NewsBannerForm: FC<NewsBannerFormProps> = (props: NewsBannerFormProps) => {
  const { isCreationForm, newsBanner, refetch } = props;
  const [form] = AntdForm.useForm();

  const [useCreateNewsBanner] = useCreateNewsBannerMutation();
  const [useUpdateNewsBanner] = useUpdateNewsBannerMutation();

  const onFinished = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue() as {
        title: string;
        body: string;
        isVisible: boolean;
      };
      const { title, body, isVisible } = values;
      if (isCreationForm) {
        await useCreateNewsBanner({
          variables: {
            input: {
              title,
              body,
              isVisible,
            },
          },
        });
        await message.success(texts.createSuccess);
        await refetch();
      } else {
        await useUpdateNewsBanner({
          variables: {
            input: {
              id: '1',
              title,
              body,
              isVisible,
            },
          },
        });
        await message.success(texts.updateSuccess);
        await refetch();
      }
    } catch {
      await message.error(texts.error);
    }
  };

  return (
    <>
      {newsBanner ? (
        <>
          <Form
            preserve={false}
            layout="vertical"
            formFields={newsBannerFormFields(isCreationForm, newsBanner)}
            form={form}
            isCreationForm={isCreationForm}
          />
        </>
      ) : (
        <Form
          layout="vertical"
          formFields={newsBannerFormFields(isCreationForm)}
          form={form}
          isCreationForm={isCreationForm}
        />
      )}
      <Button
        type="primary"
        text={!isCreationForm ? texts.save : texts.createNewsBanner}
        onClick={async () => {
          await onFinished();
        }}
      />
    </>
  );
};

NewsBannerForm.defaultProps = {
  newsBanner: undefined,
};

export default NewsBannerForm;
