import { Place } from 'apollo/types';

const dataToTable = (data: Place[] | undefined) => {
  if (!data) return [];
  const mappedPlaces = data?.map((place, index) => {
    const {
      id,
      mainDescription,
      secondaryDescription,
      name,
      placeImages,
      placeMainImage,
      thingsToDo,
    } = place;
    return {
      key: index,
      id,
      name,
      mainDescription,
      secondaryDescription,
      placeImages,
      placeMainImage,
      thingsToDo,
      actions: place,
    };
  });
  return mappedPlaces;
};

export default dataToTable;
