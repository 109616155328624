import { NavigationItem } from './types';

const adminNavigationTree: NavigationItem[] = [
  {
    label: 'Reportería',
    key: 'home',
    children: [
      {
        label: 'Dashboard',
        key: 'home/dashboard',
      },
      {
        label: 'Reporte anual',
        key: 'home/report',
      },
    ],
  },
  {
    label: '',
    key: 'div1',
    type: 'divider',
  },
  {
    label: 'Portería',
    key: 'gate/validation',
  },
  {
    label: '',
    key: 'div2',
    type: 'divider',
  },
  {
    label: 'Compras',
    key: 'purchases',
    children: [
      {
        label: 'Reservaciones',
        key: 'purchases/reservations',
      },
      {
        label: 'Validaciones',
        key: 'purchases/validations',
      },
    ],
  },
  {
    label: '',
    key: 'div3',
    type: 'divider',
  },
  {
    label: 'Configuracion',
    key: 'configuration',
    children: [
      {
        label: 'Entradas',
        key: 'configuration/entrances',
      },
      {
        label: 'Actividades',
        key: 'configuration/activities',
      },
      {
        label: 'Packs',
        key: 'configuration/packs',
      },
      {
        label: 'Quinchos',
        key: 'configuration/grills',
      },
      {
        label: 'Espacios',
        key: 'configuration/places',
      },
      {
        label: 'Experiencias',
        key: 'configuration/thingsTodo',
      },
      {
        label: 'Banner de noticias',
        key: 'configuration/newsBanner',
      },
      {
        label: 'Programas',
        key: 'configuration/programs',
      },
      {
        label: 'Noticias',
        key: 'configuration/newsPosts',
      },
    ],
  },
  {
    label: '',
    key: 'div4',
    type: 'divider',
  },
  {
    label: 'Códigos promocionales',
    key: 'promotionCodes',
  },
  {
    label: '',
    key: 'div6',
    type: 'divider',
  },
  {
    label: 'Gift cards',
    key: 'giftCards',
  },
  {
    label: '',
    key: 'div7',
    type: 'divider',
  },
  {
    label: 'Disponibilidad',
    key: 'open-days',
  },
  {
    label: '',
    key: 'div8',
    type: 'divider',
  },
  {
    label: 'Clientes',
    key: 'clients',
  },
  {
    label: '',
    key: 'div9',
    type: 'divider',
  },
  {
    label: 'Mailer',
    key: 'mailer',
  },
  {
    label: '',
    key: 'div10',
    type: 'divider',
  },
];

export default adminNavigationTree;
