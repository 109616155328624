import VisitsToday from 'components/views/Home/Dashboard/VisitsToday';
import CalendarReports from 'components/views/Home/Dashboard/CalendarReports';
import React, { FC } from 'react';

const Home: FC = () => {
  return (
    <>
      <VisitsToday />
      <CalendarReports />
    </>
  );
};

export default Home;
