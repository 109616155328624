import React, { FC } from 'react';
import * as texts from 'assets/texts/gateValidation';
import { Row, Col } from 'antd';
import { BillingDocument, BillingDocumentType } from 'apollo/types';
import styles from './BillingDocumentInformation.module.scss';

export interface BillingDocumentInformationProps {
  billingDocument: BillingDocument | undefined;
}

const BillingDocumentInformation: FC<BillingDocumentInformationProps> = ({
  billingDocument,
}: BillingDocumentInformationProps) => {
  const renderBillingDocumentType = (type: BillingDocumentType) => {
    switch (type) {
      case BillingDocumentType.Invoice:
        return texts.invoice;
      case BillingDocumentType.Voucher:
        return texts.voucher;
      default:
        break;
    }
    return '';
  };

  return (
    <>
      {billingDocument && (
        <Row className={styles.Text}>
          <Col span={24}>
            <h3 className={styles.Text}>{texts.billingInformation}</h3>
            <Row className={styles.Layout} gutter={[8, 24]} justify="space-around">
              <Col className={styles.Column}>
                <b>{texts.billingDocumentNumber}:</b>{' '}
                {billingDocument.documentNumber !== null
                  ? billingDocument.documentNumber
                  : texts.notAvailable}
              </Col>
              <Col className={styles.Column}>
                <b>{texts.billingDocumentType}:</b>{' '}
                {renderBillingDocumentType(billingDocument.type)}
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default BillingDocumentInformation;
