import { PromotionCode } from 'apollo/types';

const columns = (
  statusColumnRender: (value: boolean, record: unknown) => JSX.Element,
  forWeekendsColumnRender: (value: boolean, record: unknown) => JSX.Element,
  actionButtons: (EditItem: PromotionCode) => JSX.Element,
  validityTags: (editItem: PromotionCode) => JSX.Element
) => {
  return [
    {
      title: 'Sufijo',
      dataIndex: 'suffix',
      key: 'suffix',
    },
    {
      title: 'Creado por',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Fecha de creación',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Fecha de caducidad',
      dataIndex: 'expirationDate',
      key: 'expirationDate',
    },
    {
      title: 'Vigencia',
      dataIndex: 'validity',
      key: 'validity',
      render: validityTags,
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: statusColumnRender,
    },
    {
      title: 'Válido para fines de semana',
      dataIndex: 'forWeekends',
      key: 'forWeekends',
      render: forWeekendsColumnRender,
    },
    {
      title: 'Cantidad de códigos',
      dataIndex: 'numberOfCodes',
      key: 'numberOfCodes',
    },
    {
      title: 'Acciones para códigos',
      dataIndex: 'actions',
      key: 'actions',
      render: actionButtons,
    },
  ];
};

export default columns;
