import { ActivityTimeRow } from '../../types';

const updateDataSource = (
  record: ActivityTimeRow,
  dataSource: ActivityTimeRow[],
  setDataSource: React.Dispatch<React.SetStateAction<ActivityTimeRow[]>>
) => {
  const newData = [...dataSource];
  const index = newData.findIndex((item) => item.key === record.key);
  if (index > -1) {
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...record,
    });
  } else {
    newData.push(record);
  }
  setDataSource(newData);
};

export default updateDataSource;
