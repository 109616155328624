import useListNewsPosts from 'apollo/resolvers/newsPost/list';
import useEditNewsPostMutation from 'apollo/resolvers/newsPost/edit';
import { EditNewsPostInput, NewsPost } from 'apollo/types';
import { useEffect, useState } from 'react';
import useDeleteNewsPostMutation from 'apollo/resolvers/newsPost/delete';
import { message } from 'antd';
import * as texts from 'assets/texts/newsPostConfiguration';
import { FormInstance } from 'antd/es/form';
import useCreateNewsPostMutation from 'apollo/resolvers/newsPost/create';
import { mapCreateFormValues, mapEditFormValues } from './utils/mapFormValues';
import { CreateNewsPostFormValues } from './types';

const useNewsPostConfigurationViewModel = () => {
  const initialPaginationPage = 1;
  const initialPaginationPageSize = 4;

  const [modalInitialValues, setModalInitialValues] = useState<NewsPost>();

  const [
    useLazyListNewsPostsQuery,
    {
      data: listNewsPostsData,
      loading: listNewsPostsLoading,
      refetch: refetchListNewsPosts,
    },
  ] = useListNewsPosts();

  const [editNewsPostMutation, { loading: editNewsPostLoading }] =
    useEditNewsPostMutation();

  const [deleteNewsPostMutation, { loading: deleteNewsPostLoading }] =
    useDeleteNewsPostMutation();

  const [createNewsPostMutation, { loading: createNewsPostLoading }] =
    useCreateNewsPostMutation();

  const editNewsPost = async (input: EditNewsPostInput) => {
    const response = await editNewsPostMutation({
      variables: {
        input,
      },
    });
    if (response.data?.editNewsPost) {
      await refetchListNewsPosts();
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.success(texts.editSuccess);
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    message.error(texts.editError);
  };

  const onDeleteNewsPost = async (newsPostId: string) => {
    const response = await deleteNewsPostMutation({
      variables: {
        input: {
          id: newsPostId,
        },
      },
    });
    if (response.data?.deleteNewsPost) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.success(texts.deleteSuccess);
      await refetchListNewsPosts();
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    message.error(texts.deleteError);
  };

  const onFeaturedChange = async (newsPostId: string, value: boolean) => {
    await editNewsPost({
      id: Number(newsPostId),
      featured: value,
    });
  };

  const onStatusChange = async (newsPostId: string, value: boolean) => {
    await editNewsPost({
      id: Number(newsPostId),
      status: value,
    });
  };

  const fetchListNewsPosts = async (page: number, pageSize: number) => {
    await useLazyListNewsPostsQuery({
      variables: {
        input: {
          page,
          pageSize,
        },
      },
    });
  };

  const onPaginationChange = async (page: number, pageSize: number) => {
    await fetchListNewsPosts(page, pageSize);
  };

  const onCreateModalButtonClicked = (
    setIsModalVisible: (value: boolean) => void,
    setModalType: (type: string) => void
  ) => {
    setModalType('create');
    setIsModalVisible(true);
  };

  const onEditModalButtonClicked = (
    setIsModalVisible: (value: boolean) => void,
    setModalType: (type: string) => void,
    initialValues: NewsPost
  ) => {
    setModalType('edit');
    setIsModalVisible(true);
    setModalInitialValues(initialValues);
  };

  const onCloseModal = (setIsModalVisible: (value: boolean) => void) => {
    setIsModalVisible(false);
  };

  const onModalFinished = async (
    values: CreateNewsPostFormValues,
    formInstance: FormInstance,
    setIsModalVisible: (value: boolean) => void,
    modalType: string
  ) => {
    await formInstance.validateFields();
    if (modalType === 'create') {
      const mappedValues = mapCreateFormValues(values);
      const response = await createNewsPostMutation({
        variables: {
          input: {
            ...mappedValues,
          },
        },
      });

      if (response.data?.createNewsPost) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success(texts.createSuccess);
        await refetchListNewsPosts();
      }
    }
    if (modalType === 'edit') {
      const mappedValues = mapEditFormValues(values, Number(modalInitialValues?.id));
      const response = await editNewsPostMutation({
        variables: {
          input: {
            ...mappedValues,
          },
        },
      });

      if (response.data?.editNewsPost) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success(texts.editSuccess);
        await refetchListNewsPosts();
      }
    }
    formInstance.resetFields();
    setIsModalVisible(false);
  };

  const orderNewsPostContent = (newsPost: NewsPost) => {
    if (!newsPost.contents) return [];
    const arrayToOrder = [...newsPost.contents];
    const orderedContent = arrayToOrder.sort((a, b) => a.order - b.order);
    return orderedContent;
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchListNewsPosts(initialPaginationPage, initialPaginationPageSize);
  }, []);

  return {
    onFeaturedChange,
    onStatusChange,
    listNewsPostsData,
    listNewsPostsLoading,
    editNewsPostLoading,
    deleteNewsPostLoading,
    createNewsPostLoading,
    onPaginationChange,
    onDeleteNewsPost,
    onCreateModalButtonClicked,
    onEditModalButtonClicked,
    onCloseModal,
    onModalFinished,
    orderNewsPostContent,
    modalInitialValues,
  };
};

export default useNewsPostConfigurationViewModel;
