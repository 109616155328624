import { gql, useMutation } from '@apollo/client';
import { PackImage, MutationDeletePackImageArgs } from 'apollo/generated/types';

const DELETE_PACK_IMAGE = gql`
  mutation deletePackImage($input: DeletePackImageInput!) {
    deletePackImage(input: $input) {
      id
    }
  }
`;

const useDeletePackImageMutation = () => {
  return useMutation<{ deletePackImage: PackImage }, MutationDeletePackImageArgs>(
    DELETE_PACK_IMAGE
  );
};
export default useDeletePackImageMutation;
