import { Code } from 'apollo/types';
import dateFormat from 'utils/formats/dateFormat';

const dataToExcel = (data: Code[] | undefined, expirationDate?: string | null) => {
  if (!data) return [];
  const mappedPromotionCode = data?.map((promotionCode) => {
    const { id, isUsed, code, purchaseId } = promotionCode;
    return {
      id,
      expirationDate: expirationDate
        ? dateFormat(new Date(expirationDate), true)
        : 'No expira',
      used: isUsed ? 'Si' : 'No',
      code,
      associatedPurchase: purchaseId ? Number(purchaseId) : 'No asociado',
    };
  });
  return mappedPromotionCode;
};

export default dataToExcel;
