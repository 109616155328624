import {
  PromotionCodeColumn,
  PromotionCodeConfigurationEditMutation,
  PromotionCodeFetchResult,
} from '../types';

const forWeekendsOnChange = async (
  item: PromotionCodeColumn | undefined,
  editMutation: PromotionCodeConfigurationEditMutation
): Promise<PromotionCodeFetchResult> => {
  const id = item?.actions.id || '';
  const toEdit = {
    id,
    forWeekends: !item?.forWeekends,
  };
  const edited = await editMutation({
    variables: { input: toEdit },
  });
  return edited;
};

export default forWeekendsOnChange;
