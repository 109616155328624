import { gql, useQuery } from '@apollo/client';
import { ParkAvailability } from 'apollo/generated/types';

const LIST_PARK_AVAILABILITY = gql`
  query listParkAvailability {
    listParkAvailability {
      id
      startDate
      endDate
    }
  }
`;

const useListParkAvailabilityQuery = () => {
  return useQuery<{ listParkAvailability: ParkAvailability[] }>(LIST_PARK_AVAILABILITY);
};

export default useListParkAvailabilityQuery;
