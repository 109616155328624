import { NewsPost, NewsPostImage } from 'apollo/types';
import * as texts from 'assets/texts/newsPostConfiguration';

const columns = (
  renderFeaturedColumn: (value: boolean, record: unknown) => JSX.Element,
  renderStatusColumn: (value: boolean, record: unknown) => JSX.Element,
  renderImagesGroup: (item: NewsPostImage[]) => JSX.Element,
  renderActionColumns: (item: NewsPost) => JSX.Element
) => {
  return [
    {
      title: texts.title,
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: texts.publicationDate,
      dataIndex: 'publicationDate',
      key: 'publicationDate',
    },
    {
      title: texts.viewCount,
      dataIndex: 'viewCount',
      key: 'viewCount',
    },
    {
      title: texts.readTime,
      dataIndex: 'readTime',
      key: 'readTime',
    },
    {
      title: texts.featured,
      dataIndex: 'featured',
      key: 'featured',
      render: renderFeaturedColumn,
    },
    {
      title: texts.status,
      dataIndex: 'status',
      key: 'status',
      render: renderStatusColumn,
    },
    {
      title: texts.photos,
      dataIndex: 'newsPostImages',
      key: 'newsPostImages',
      align: 'center' as const,
      render: renderImagesGroup,
    },
    {
      title: texts.actions,
      dataIndex: 'actions',
      key: 'actions',
      align: 'center' as const,
      render: renderActionColumns,
    },
  ];
};

export default columns;
