import { ReactNode } from 'react';
import { Place, ThingToDo } from 'apollo/types';
import { FormFields } from 'components/UI/Form/types';
import * as texts from '../../../../assets/texts/placeConfiguration';
import { ThingsToDoOptions } from '../types';
import thingsToDoFormFieldMapper from './thingsToDoFormFieldMapper';

const placeFormFields = (
  isCreation: boolean,
  editableRow?: Place,
  uploadElements?: ReactNode,
  uploadElement?: ReactNode,
  options?: ThingsToDoOptions
): FormFields[] => {
  const thingsToDo = thingsToDoFormFieldMapper(editableRow?.thingsToDo as ThingToDo[]);
  const formFields = [
    {
      name: 'name',
      label: texts.placeName,
      type: 'text',
      initialValue: editableRow?.name || '',
      rules: [{ required: isCreation }],
    },
    {
      name: 'mainDescription',
      label: texts.description,
      type: 'textarea',
      initialValue: editableRow?.mainDescription || '',
      rules: [{ required: isCreation }],
    },
    {
      name: 'secondaryDescription',
      label: texts.secondaryDescription,
      type: 'textarea',
      initialValue: editableRow?.secondaryDescription || '',
      rules: [{ required: isCreation }],
    },
    {
      name: 'thingsToDo',
      label: 'Experiencias',
      variableName: 'thingsToDo',
      type: 'select',
      mode: 'multiple' as const,
      initialValue: thingsToDo,
      options,
    },
  ];

  if (isCreation) {
    return formFields;
  }
  return [
    ...formFields,
    {
      name: 'placeMainImage',
      label: texts.placeMainImage,
      type: 'images',
      uploadelement: uploadElement,
      rules: [{ required: false }],
    },
    {
      name: 'images',
      label: 'Fotos',
      type: 'images',
      uploadelement: uploadElements,
      rules: [{ required: false }],
    },
  ];
};

export default placeFormFields;
