import { gql, useMutation } from '@apollo/client';
import { Activity, MutationEditActivityArgs } from 'apollo/generated/types';

const EDIT_ACTIVITY = gql`
  mutation editActivity($input: EditActivityInput!) {
    editActivity(input: $input) {
      activityTypeId
      description
      enDescription
      enName
      id
      maxStock
      name
      status
      paxPerTicket
      value
      activityImages {
        image
        id
      }
      activityTimes {
        id
      }
    }
  }
`;

const useEditActivityMutation = () => {
  return useMutation<{ editActivity: Activity }, MutationEditActivityArgs>(
    EDIT_ACTIVITY,
    {}
  );
};
export default useEditActivityMutation;
