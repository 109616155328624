import { gql, useLazyQuery } from '@apollo/client';
import { EnrichedPurchaseBackOffice, QueryGetEnrichedPurchaseArgs } from 'apollo/types';

const GET_ENRICHED_PURCHASE = gql`
  query getEnrichedPurchase($id: ID!, $isInvalid: Boolean) {
    getEnrichedPurchase(id: $id, isInvalid: $isInvalid) {
      totalPrice
      purchaseQrScans
      disabledPurchaseHistory {
        userFirstName
        userLastName
        userEmail
        createdAt
      }
      purchase {
        id
        visitDate
        isDisabled
        createdAt
        entranceTickets {
          productId
          price
          quantity
          percentageDiscount
          name
          finalPrice
        }
        activityTickets {
          productId
          price
          quantity
          percentageDiscount
          name
          finalPrice
        }
        packPurchases {
          productId
          price
          quantity
          percentageDiscount
          name
          finalPrice
        }
        activityTimes {
          id
          name
          activityTime {
            startHour
            endHour
          }
          quantity
        }
        giftCardPurchase {
          giftCardId
          amountUsed
        }
      }
      client {
        firstName
        id
        lastName
        phone
        documentNumber
        email
      }
      billingDocument {
        documentNumber
        type
      }
    }
  }
`;

const useGetEnrichedPurchaseLazyQuery = (id: string, isInvalid: boolean) => {
  return useLazyQuery<
    { getEnrichedPurchase: EnrichedPurchaseBackOffice },
    QueryGetEnrichedPurchaseArgs
  >(GET_ENRICHED_PURCHASE, {
    variables: { id, isInvalid },
    fetchPolicy: 'no-cache',
  });
};

export default useGetEnrichedPurchaseLazyQuery;
