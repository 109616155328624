import { gql, useMutation } from '@apollo/client';
import { MutationSendClientsEmailsBackofficeArgs, Success } from 'apollo/types';

const SEND_MAIL = gql`
  mutation sendClientsEmailsBackoffice($input: SendClientsEmailsInput!) {
    sendClientsEmailsBackoffice(input: $input) {
      success
    }
  }
`;

const useSendMailMutation = () => {
  return useMutation<
    { sendClientsEmailsBackoffice: Success },
    MutationSendClientsEmailsBackofficeArgs
  >(SEND_MAIL, {});
};

export default useSendMailMutation;
