const calculateEntrancePercentages = (
  count: number | undefined,
  totalCount: number | undefined
): number => {
  if (!totalCount || !count) {
    return 0;
  }
  return Math.floor((count * 100) / totalCount);
};

export default calculateEntrancePercentages;
