import { NewsBanner } from 'apollo/types';

const newsBannerFormFields = (isCreationForm: boolean, initialValues?: NewsBanner) => {
  const formFields = [
    {
      name: 'body',
      label: 'Contenido',
      type: 'simpleTextArea',
      initialValue: initialValues?.body,
      rules: [{ required: isCreationForm }],
    },
    {
      name: 'isVisible',
      label: '¿Habilitado?',
      type: 'switch',
      initialValue: initialValues?.isVisible,
      rules: [{ required: isCreationForm }],
    },
  ];
  return formFields;
};

export default newsBannerFormFields;
