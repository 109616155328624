import React from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import Routes from './routes';
import AuthRoute from './authRoute';

const AppNavigation = () => (
  <Router>
    <Switch>
      {Routes.map((route) => {
        return <AuthRoute key={route.title} {...route} />;
      })}
      <Redirect to="/home/dashboard" />
    </Switch>
  </Router>
);
export default AppNavigation;
