import { Activity } from 'apollo/types';
import { FormFields, Options } from 'components/UI/Form/types';

const activityFormFields = (
  isCreation: boolean,
  tooltip: string,
  places?: Options,
  editableRow?: Activity
): FormFields[] => {
  const formFields = [
    {
      name: 'name',
      label: 'Nombre Ticket',
      type: 'text',
      initialValue: editableRow?.name,
      rules: [{ required: isCreation }],
    },
    {
      name: 'value',
      label: 'Precio',
      type: 'number',
      initialValue: editableRow?.value,
      rules: [{ required: isCreation, min: 0 }],
    },
    {
      name: 'paxPerTicket',
      label: 'Personas por ticket',
      type: 'number',
      initialValue: editableRow?.paxPerTicket,
      rules: [{ required: isCreation, min: 0 }],
    },
    {
      name: 'maxStock',
      label: 'Capacidad por horario',
      type: 'number',
      initialValue: editableRow?.maxStock,
      rules: [{ required: isCreation, min: 0 }],
    },
    {
      name: 'placeId',
      label: 'Lugar',
      type: 'select',
      initialValue: editableRow
        ? [
            {
              label: editableRow.place.name || '',
              value: Number(editableRow.place.id),
            },
          ]
        : undefined,
      rules: [{ required: isCreation }],
      options: places,
      tooltip,
    },
    {
      name: 'activityTimes',
      label: 'Horario',
      type: 'activityTimes',
      initialValue: editableRow?.activityTimes || [],
      rules: [{ required: isCreation }],
    },
  ];

  return formFields;
};

export default activityFormFields;
