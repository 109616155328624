import { ActivityTime } from 'apollo/types';
import { ActivityTimeRow } from '../../types';

const dataToTable = (
  parkAvailabilityId: string,
  data: ActivityTime[]
): ActivityTimeRow[] => {
  return data
    .filter((activityTime) => activityTime.parkAvailabilityId === parkAvailabilityId)
    .map((activityTime) => {
      return {
        ...activityTime,
        parkAvailabilityId,
        key: Number(activityTime.id),
      };
    });
};

export default dataToTable;
