import { FormInstance } from 'antd';
import { EditEntranceTicketTypeInput } from 'apollo/types';
import { EntranceTicketTypeCreate, EntranceTicketTypeEdit } from '../types';

const entranceConfigurationFormToInput = (
  form: FormInstance
): EntranceTicketTypeEdit | EntranceTicketTypeCreate => {
  const formValues = form.getFieldsValue(true) as EditEntranceTicketTypeInput;
  const mappedFormValues = {
    name: formValues.name ? formValues.name.toString() : undefined,
    adultValue: Number(formValues.adultValue),
    otherValue: Number(formValues.otherValue),
    maxStock: Number(formValues.maxStock),
    description: formValues.description?.toString(),
    isBicycle: formValues.isBicycle,
  };
  return mappedFormValues;
};

export default entranceConfigurationFormToInput;
