import { Place } from 'apollo/types';
import { Options } from 'components/UI/Form/types';

const mapPlaces = (objects: Place[] | undefined): Options | undefined => {
  const labelValue = objects?.map((obj) => {
    return {
      label: obj.name || '',
      value: Number(obj.id),
      disabled: obj.activities !== null,
    };
  });
  return labelValue;
};

export default mapPlaces;
