import { message } from 'antd';
import { Client, Filter } from 'apollo/generated/types';
import useLazyListClientsQuery from 'apollo/resolvers/client/listMailer';
import uselistTicketTypesQuery from 'apollo/resolvers/purchases/ticketTypes';
import { LoadingModal } from 'components/UI';
import ClientDetail from 'components/views/Mailer/ClientDetail';
import CreateMail from 'components/views/Mailer/CreateMail';
import ListClientSearcher from 'components/views/Mailer/ListClientSearcher';
import { SearcherInput } from 'components/views/Purchases/Searcher/types';
import React, { FC, useEffect, useState } from 'react';
import ticketTypesMapper from 'views/Purchases/Reservation/utils/ticketTypesMapper';
import * as texts from '../../assets/texts/mailer';
import styles from './Mailer.module.scss';
import clientInitInputs from './utils/initInputs';

const Mailer: FC = () => {
  const { data: filterData } = uselistTicketTypesQuery();
  const [listClientsQuery, { data: clientListData }] = useLazyListClientsQuery();
  const [clients, setClients] = useState<Client[]>();
  const [inputs, setInputs] = useState<SearcherInput[]>(clientInitInputs());
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const setListClients = async (filters: Filter[]) => {
    if (filters.length > 0) {
      setIsModalVisible(true);
      const startDate = filters[0].filter as string;
      const endDate = filters[1].filter as string;
      let listEntranceTicketTypes: string[] = [];
      if (filters.length === 3) {
        listEntranceTicketTypes = filters[2].filterList as string[];
      }
      await listClientsQuery({
        variables: { startDate, endDate, listEntranceTickets: listEntranceTicketTypes },
      });
      setIsModalVisible(false);
    }
  };

  const OnFinishedClientSearcher = (filterArray: React.SetStateAction<Filter[]>) => {
    setClients([]);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    setListClients(filterArray as Filter[]);
  };

  const setClientsFromApi = () => {
    if (clientListData) {
      if (clientListData.listClientsMailerBackoffice.length === 0) {
        message.error(texts.error) as unknown as void;
      }
      setClients(clientListData.listClientsMailerBackoffice);
    }
  };

  const setInputsFromApi = () => {
    if (filterData) {
      const inputsCopy = [...inputs];
      inputsCopy[2].options = ticketTypesMapper(filterData);
      setInputs(inputsCopy);
    }
  };

  const onEmailSent = () => {
    setClients([]);
    message.success(texts.emailSent) as unknown as void;
  };

  useEffect(() => {
    setClientsFromApi();
  }, [clientListData]);

  useEffect(() => {
    setInputsFromApi();
  }, [filterData]);

  return (
    <>
      <LoadingModal visible={isModalVisible} />
      <h1 className={styles.Title}>{texts.title}</h1>
      <ListClientSearcher inputs={inputs} onFinished={OnFinishedClientSearcher} />
      {clients && clients.length > 0 && (
        <>
          <ClientDetail clients={clients} />
          <CreateMail clients={clients} onEmailSent={onEmailSent} />
        </>
      )}
    </>
  );
};

export default Mailer;
