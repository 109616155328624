import { ActivityReservations } from 'apollo/types';

const calculateActivityPercentage = (
  reservations: ActivityReservations | undefined
): number => {
  if (!reservations || reservations.stock === 0) {
    return 0;
  }
  return Math.floor((reservations.count * 100) / reservations.stock);
};

export default calculateActivityPercentage;
