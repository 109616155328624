const clientFormFields = () => {
  const formFields = [
    {
      name: 'subject',
      label: 'Asunto',
      type: 'text',
      initialValue: '',
      rules: [{ required: true }],
    },
    {
      name: 'body',
      label: 'Cuerpo',
      type: 'textarea',
      initialValue: '',
      rules: [{ required: true }],
    },
  ];
  return formFields;
};

export default clientFormFields;
