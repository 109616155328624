import { useMutation } from '@apollo/client';
import { MutationUpdateNewsBannerArgs, NewsBanner } from 'apollo/types';
import { gql } from 'graphql.macro';

const UPDATE_NEWSBANNER = gql`
  mutation updateNewsBanner($input: UpdateNewsBannerInput!) {
    updateNewsBanner(input: $input) {
      title
      body
      isVisible
    }
  }
`;

const useUpdateNewsBannerMutation = () => {
  return useMutation<{ updateNewsBanner: NewsBanner }, MutationUpdateNewsBannerArgs>(
    UPDATE_NEWSBANNER,
    {}
  );
};

export default useUpdateNewsBannerMutation;
