import { FormInstance } from 'antd';
import { CreatePlaceThingToDoInput, EditPlaceInput } from 'apollo/types';
import { PlaceCreate, PlaceEdit } from '../types';

const formToInput = (form: FormInstance, id?: string): PlaceEdit | PlaceCreate => {
  const formValues = form.getFieldsValue(true) as EditPlaceInput;
  const formThingsTodo = form.getFieldValue('thingsToDo') as number[];

  const thingsToDo: CreatePlaceThingToDoInput[] = formThingsTodo?.map((thingToDo) => ({
    id: thingToDo.toString(),
    name: '',
    description: '',
  }));

  const mappedFormValues = {
    mainDescription: formValues.mainDescription,
    secondaryDescription: formValues.secondaryDescription,
    name: formValues.name || undefined,
    thingsToDo: thingsToDo || undefined,
  };
  if (id) {
    return { id, ...mappedFormValues };
  }

  return mappedFormValues;
};

export default formToInput;
