import { gql, useQuery } from '@apollo/client';
import { TodaysTicketData } from 'apollo/generated/types';

const GET_TOTAL_TICKET_SUMMARY = gql`
  query getTotalTicketSummary {
    getTotalTicketSummary {
      adultCount
      count
      kidsOrElderlyCount
      stock
      visitedAdultCount
      visitedCount
      visitedKidsOrElderlyCount
      activitiesReservations {
        aviaryReservation {
          count
          stock
          availableTimes
        }
        grillsReservation {
          count
          stock
          availableTimes
        }
        nurseryGardenReservation {
          count
          stock
          availableTimes
        }
      }
    }
  }
`;

const useVisitsTodayQuery = () => {
  return useQuery<{ getTotalTicketSummary: TodaysTicketData }>(
    GET_TOTAL_TICKET_SUMMARY,
    {}
  );
};
export default useVisitsTodayQuery;
