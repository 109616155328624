import React, { FC, useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { showErrorModal, errorData } from 'apollo/cache';
import NavBar from 'components/Layout/NavBar';
import ErrorModal from 'views/Error';
import { Col, Row } from 'antd';
import styles from './GeneralLayout.module.scss';

interface Props {
  children?: JSX.Element;
}

const GeneralLayout: FC<Props> = ({ children }: Props) => {
  const showErrorModalHook = useReactiveVar(showErrorModal);

  const [errorModalVisible, setErrorModalVisible] = useState(false);
  useEffect(() => {
    if (showErrorModalHook) {
      setErrorModalVisible(true);
    }
  }, [showErrorModalHook]);

  return (
    <>
      <ErrorModal
        visible={errorModalVisible}
        onClose={() => {
          setErrorModalVisible(false);
          showErrorModal(false);
          errorData(null);
        }}
      />
      <NavBar />
      <div className={styles.MainContainer}>
        <Row className={styles.FullHeight}>
          <Col xs={24}>
            <div className={styles.ComponentContainer}>{children}</div>
          </Col>
        </Row>
      </div>
    </>
  );
};

GeneralLayout.defaultProps = {
  children: undefined,
};

export default GeneralLayout;
