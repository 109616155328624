import { Col, Modal, Row } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import * as texts from 'assets/texts/reservation';
import { EnrichedPurchaseBackOffice, TicketPurchase } from 'apollo/types';
import OrderDetail from 'components/views/GateValidation/OrderDetail';
import ClientInformation from 'components/views/GateValidation/ClientInformation';
import useGetEnrichedPurchaseLazyQuery from 'apollo/resolvers/purchases/getEnrichedPurchase';
import { LoadingModal } from 'components/UI';
import BillingDocumentInformation from 'components/views/GateValidation/BillingDocumentInformation';
import priceFormat from 'utils/formats/priceFormat';
import styles from './DetailModal.module.scss';

export interface DetailModalProps {
  visible: boolean;
  onCancel: () => void;
  purchaseDetail: TicketPurchase;
}

export const DetailModal: FC<DetailModalProps> = ({
  visible,
  onCancel,
  purchaseDetail,
}) => {
  const [enrichedPurchase, setEnrichedPurchase] = useState<EnrichedPurchaseBackOffice>();
  const { purchaseId } = purchaseDetail;
  const [getEnrichedPurchase, { loading }] = useGetEnrichedPurchaseLazyQuery(
    purchaseId,
    false
  );

  const onCloseModal = () => {
    setEnrichedPurchase(undefined);
    onCancel();
  };

  const performGetEnrichedQuery = async () => {
    if (visible) {
      const dataFromQuery = await getEnrichedPurchase({
        variables: {
          id: purchaseId,
        },
      });
      setEnrichedPurchase(dataFromQuery.data?.getEnrichedPurchase);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    performGetEnrichedQuery();
  }, [visible]);

  return (
    <>
      <LoadingModal visible={visible && loading} />
      {enrichedPurchase && enrichedPurchase.purchase && (
        <Modal
          className={styles.Modal}
          visible={visible}
          onCancel={onCloseModal}
          okButtonProps={{
            className: styles.NoButton,
          }}
          cancelButtonProps={{
            className: styles.NoButton,
          }}
        >
          <h1>{texts.purchaseDetail}</h1>
          <ClientInformation client={enrichedPurchase.client || undefined} />
          <BillingDocumentInformation
            billingDocument={enrichedPurchase.billingDocument || undefined}
          />
          <OrderDetail
            purchaseId={purchaseId}
            entranceTickets={enrichedPurchase.purchase.entranceTickets || undefined}
            activityTickets={enrichedPurchase.purchase.activityTickets || undefined}
            packTickets={enrichedPurchase.purchase.packPurchases || undefined}
            activityTimeTickets={enrichedPurchase.purchase.activityTimes || undefined}
            visitDate={enrichedPurchase.purchase.visitDate || undefined}
            createdAt={enrichedPurchase.purchase.createdAt || undefined}
            giftCardPurchase={enrichedPurchase.purchase.giftCardPurchase || undefined}
          />
          <Row className={styles.MarginTop}>
            <Col span={24}>
              <h3 className={styles.TextEnd}>
                {texts.totalPrice}{' '}
                {enrichedPurchase.totalPrice &&
                  priceFormat.format(enrichedPurchase.totalPrice)}
              </h3>
            </Col>
          </Row>
        </Modal>
      )}
    </>
  );
};
