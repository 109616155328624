import React, { FC, Key, ReactElement } from 'react';
import { Table as AntdTable } from 'antd';
import { TableProps } from 'antd/lib/table/index';
import { TableRowSelection } from 'antd/lib/table/interface';
import styles from './Table.module.scss';

type CustomTableProps = TableProps<object> & {
  selectedRowKeys?: Key[];
  setSelectedRowKeys?: (keys: Key[]) => void;
  customizedSelect?: (key: Key) => void;
};

const Table: FC<CustomTableProps> = (props: CustomTableProps): ReactElement => {
  const {
    selectedRowKeys,
    setSelectedRowKeys,
    dataSource,
    customizedSelect,
    ...otherProps
  } = props;

  const onSelectChange = (rowKeys: Key[]) => {
    const selectedKey = rowKeys[rowKeys.length - 1];
    if (setSelectedRowKeys && selectedRowKeys) {
      if (selectedRowKeys.includes(selectedKey)) {
        setSelectedRowKeys([]);
      } else {
        setSelectedRowKeys(rowKeys);
        if (customizedSelect) {
          customizedSelect(selectedKey);
        }
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <AntdTable
      rowSelection={
        selectedRowKeys
          ? {
              ...rowSelection,
            }
          : undefined
      }
      {...otherProps}
      rowClassName={(record, index) =>
        index % 2 === 0 ? styles.tableRowDark : styles.tableRowLight
      }
      dataSource={dataSource}
    />
  );
};

Table.defaultProps = {
  selectedRowKeys: undefined,
  setSelectedRowKeys: undefined,
  customizedSelect: undefined,
};

export default Table;
