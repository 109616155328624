import React, { FC, useState } from 'react';
import { Form, Input, Button, message as messageAntd } from 'antd';
import useErrorHandler from 'hooks/useErrorHandler';
import LoadingModal from 'components/UI/LoadingModal';
import * as texts from 'assets/texts/forgotPasswordModal';
import useSuccess from 'hooks/useSuccess';
import Modal from 'antd/lib/modal/Modal';
import { useHistory } from 'react-router-dom';
import logIn from 'auth/methods/logIn';
import { forgotPasswordComplete, forgotPasswordInit } from 'auth/methods/forgotPassword';
import styles from './ForgotPasswordModal.module.scss';

interface Props {
  visible: boolean;
  // eslint-disable-next-line react/require-default-props
  onSuccessAdditional: () => void;
}

const ForgotPasswordModal: FC<Props> = (props: Props) => {
  const { visible, onSuccessAdditional } = props;
  const history = useHistory<{ from: string }>();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [newPassword, setNewPassword] = useState<string | undefined>(undefined);
  const { showError } = useErrorHandler(null);
  const onLoginSuccess = () => {
    setLoading(false);
    if (onSuccessAdditional) {
      onSuccessAdditional();
    }
    window.location.replace(history.location.state?.from || '');
  };
  const onError = (error: Error) => {
    setLoading(false);
    showError(error);
  };
  const onLoading = () => {
    setLoading(true);
  };
  const loginAfterSuccess = () => {
    setSuccess(true);
    if (email && newPassword) {
      logIn(email, newPassword, onLoginSuccess, onLoading, onError);
    }
  };

  const onFinishPassword = (values: { code: string; password: string }) => {
    setLoading(true);
    const { code, password } = values;
    setNewPassword(password);
    forgotPasswordComplete(code, password, email, loginAfterSuccess, onError);
    setLoading(false);
    onSuccessAdditional();
  };
  const onSuccessCode = () => {
    setLoading(false);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    messageAntd.success(texts.sendCodeSuccess);
  };
  const onFinishCode = (values: { emailInput: string }) => {
    const { emailInput } = values;
    setEmail(emailInput);
    forgotPasswordInit(emailInput, onSuccessCode, onLoading, onError);
  };
  useSuccess<boolean>(success, texts.updatePasswordSuccess);
  return (
    <Modal visible={visible} footer={null} closable={false}>
      <div className={styles.MainContainer}>
        <LoadingModal visible={loading} />
        <h1>{texts.title}</h1>
        <Form
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={onFinishCode}
          autoComplete="off"
          className={styles.Form}
        >
          <Form.Item
            label={texts.emailLabel}
            name="emailInput"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {texts.sendCodeButton}
            </Button>
          </Form.Item>
        </Form>

        <Form
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={onFinishPassword}
          autoComplete="off"
          className={styles.Form}
        >
          <Form.Item label={texts.codeLabel} name="code" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label={texts.newPasswordLabel}
            name="password"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {texts.updatePasswordButton}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default ForgotPasswordModal;
