import { EntranceTicketTypeColumn, EntranceConfigurationEditMutation } from '../types';

const visibilityOnChange = async (
  item: EntranceTicketTypeColumn | undefined,
  editMutation: EntranceConfigurationEditMutation,
  refetch: () => Promise<unknown>
): Promise<unknown> => {
  const id = item?.actions.id || '';
  const toEdit = { id, visibility: !item?.visibility, name: item?.name };
  const edited = await editMutation({
    variables: { input: toEdit },
  });
  await refetch();
  return edited;
};

export default visibilityOnChange;
