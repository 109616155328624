import { PromotionOptions } from 'apollo/types';

const dataToTable = (data: PromotionOptions | undefined) => {
  if (!data) return [];
  const activityTypes = data.activityTypes as { id: string; name: string }[];
  const mappedActivities = activityTypes.map((activity) => {
    return {
      key: `activity_${activity.id}`,
      name: activity.name,
      id: activity.id,
      type: 'activity',
    };
  });
  const mappedEntranceTicketTypes =
    data.entranceTicketTypes?.map((entranceTicketType) => {
      return {
        key: `entranceTicketType_${entranceTicketType.id}`,
        name: entranceTicketType.name,
        id: entranceTicketType.id,
        type: 'entranceTicketType',
      };
    }) || [];
  const mappedPacks =
    data.packs?.map((pack) => {
      return {
        key: `pack_${pack.id}`,
        name: pack.name,
        id: pack.id,
        type: 'pack',
      };
    }) || [];
  const mappedData = [...mappedActivities, ...mappedEntranceTicketTypes, ...mappedPacks];

  return mappedData;
};

export default dataToTable;
