export const currentVisitsTitle = 'Visitas en el parque en este momento';
export const persons = 'Personas';
export const adults = 'Adultos';
export const others = 'Adultos mayores/niños';

export const reservationsTitle = 'Reservaciones';
export const adultTickets = 'Tickets Adultos';
export const otherTickets = 'Tickets Adulto mayor/Niños';
export const availableTickets = 'Tickets Disponibles';

export const activityReservationsTitle = 'Reservaciones Actividades';
export const soldTickets = 'Tickets vendidos';
export const seeTicketsButton = 'Ver tickets';
export const aviary = 'Aviario';
export const nurseryGarden = 'Jardín botánico';
export const grill = 'Quincho';

export const calendarReportsTitle = 'Reporte de ventas de tickets';
export const reportsTableTitle = 'Entradas';
export const tableColumns = [
  { title: 'Tipo de ticket', dataIndex: 'ticketType', key: 'ticketType' },
  { title: 'Cantidad disponible', dataIndex: 'availableAmount', key: 'availableAmount' },
  { title: 'Cantidad vendida', dataIndex: 'soldAmount', key: 'soldAmount' },
];
