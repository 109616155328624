import { gql, useMutation } from '@apollo/client';
import {
  MutationUploadThingsToDoImagesArgs,
  ThingToDoImage,
} from 'apollo/generated/types';

const UPLOAD_THINGS_TODO_IMAGE = gql`
  mutation uploadThingsToDoImages($input: UploadThingToDoImageInput!) {
    uploadThingsToDoImages(input: $input) {
      id
      image
      uploadUrl
    }
  }
`;

const useUploadThingsToDoImagesMutation = () => {
  return useMutation<
    { uploadThingsToDoImages: ThingToDoImage },
    MutationUploadThingsToDoImagesArgs
  >(UPLOAD_THINGS_TODO_IMAGE, {});
};
export default useUploadThingsToDoImagesMutation;
