import { gql, useQuery } from '@apollo/client';
import { Place } from 'apollo/types';

const LIST_PLACES = gql`
  query listPlacesBackOffice {
    listPlacesBackOffice {
      secondaryDescription
      placeMainImage
      name
      mainDescription
      id
      mainDescription
      name
      placeMainImage
      secondaryDescription
      placeImages {
        id
        image
      }
      thingsToDo {
        description
        id
        name
      }
      activities {
        id
        name
      }
    }
  }
`;

const useListPlacesQuery = () => {
  return useQuery<{ listPlacesBackOffice: Place[] }>(LIST_PLACES, {});
};

export default useListPlacesQuery;
