import { gql, useMutation } from '@apollo/client';
import { MutationDisablePurchasesBackofficeArgs } from 'apollo/types';

const DISABLE_PURCHASES = gql`
  mutation disablePurchasesBackoffice($input: DisablePurchasesBackofficeInput!) {
    disablePurchasesBackoffice(input: $input) {
      success
    }
  }
`;

const useDisablePurchasesMutation = () => {
  return useMutation<
    { disablePurchasesBackoffice: { success: boolean } },
    MutationDisablePurchasesBackofficeArgs
  >(DISABLE_PURCHASES);
};

export default useDisablePurchasesMutation;
