import React, { FC } from 'react';
import Logo from 'assets/images/tricao-logo.png';
import { Col, Row } from 'antd';
import { Button } from 'components/UI';
import * as texts from 'assets/texts/navbar';
import styles from './NavBar.module.scss';

const NavBar: FC = () => {
  const getUserIdFromLocalStorage = () => {
    const userAuth = localStorage.getItem('UserAuth');
    if (userAuth) {
      const userAuthJson = JSON.parse(userAuth) as { userId?: number };
      if (userAuthJson.userId !== 0 && userAuthJson.userId !== undefined) {
        return userAuthJson.userId;
      }
    }
    return undefined;
  };

  const onLogOutButtonClick = () => {
    localStorage.clear();
    window.location.href = '/';
  };

  const isUserLoggedIn = () => {
    const userId = getUserIdFromLocalStorage();
    if (userId !== undefined) {
      return (
        <Col xs={{ span: 5 }} span={8}>
          <Button text={texts.logOut} type="primary" onClick={onLogOutButtonClick} />
        </Col>
      );
    }
    return <></>;
  };

  return (
    <Row className={styles.Container} align="middle">
      <Col xs={{ span: 4 }} span={8} />
      <Col xs={{ span: 15 }} span={8}>
        <a href="/">
          <img src={Logo} alt="Tricao Logo" className={styles.Logo} />
        </a>
      </Col>
      {isUserLoggedIn()}
    </Row>
  );
};

export default NavBar;
