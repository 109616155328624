import { DisablePurchaseHistory, TicketPurchase } from 'apollo/types';
import { ReactNode } from 'react';

const createColumns = (
  renderIsDisabledColumn: (disabledPurchaseHistory: DisablePurchaseHistory) => ReactNode,
  renderPurchaseDetailButton: (purchase: TicketPurchase) => ReactNode
): object[] => [
  {
    title: 'N° de orden',
    dataIndex: 'orderNumber',
    key: 'orderNumber',
  },
  {
    title: 'Fecha de visita',
    dataIndex: 'visitDate',
    key: 'visitDate',
  },
  {
    title: 'Rut',
    dataIndex: 'rut',
    key: 'rut',
  },
  {
    title: 'Tipo de ticket',
    dataIndex: 'ticketType',
    key: 'ticketType',
  },
  {
    title: 'Estado',
    dataIndex: 'disabledPurchaseHistory',
    key: 'disabledPurchaseHistory',
    render: renderIsDisabledColumn,
  },
  {
    title: 'Acciones',
    dataIndex: 'purchase',
    key: 'purchase',
    render: renderPurchaseDetailButton,
  },
];

export default createColumns;
