import { gql, useQuery } from '@apollo/client';
import { Activity, QueryListActivitiesArgs } from 'apollo/generated/types';

const LIST_GRILLS = gql`
  query listActivities {
    listActivitiesBackOffice(activityTypeId: "2") {
      activityTypeId
      description
      enDescription
      enName
      id
      maxStock
      name
      status
      paxPerTicket
      value
      place {
        id
        mainDescription
        name
        placeMainImage
        secondaryDescription
        placeImages {
          id
          image
          uploadUrl
        }
      }
      activityTimes {
        id
      }
      activityImages {
        image
      }
    }
  }
`;

const useListGrillsQuery = () => {
  return useQuery<{ listActivitiesBackOffice: Activity[] }, QueryListActivitiesArgs>(
    LIST_GRILLS
  );
};
export default useListGrillsQuery;
