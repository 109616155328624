import React, { FC, useEffect, useState } from 'react';
import * as texts from 'assets/texts/gateValidation';
import { Button, Col, Form, InputNumber, message, Row, Space } from 'antd';
import { EnrichedPurchaseBackOffice } from 'apollo/generated/types';
import useGetEnrichedPurchaseLazyQuery from 'apollo/resolvers/purchases/getEnrichedPurchase';
import { LoadingModal } from 'components/UI';
import styles from './PurchaseOrderInput.module.scss';

export interface PurchaseOrderInputProps {
  setPurchaseFromEnrichedPurchase: (
    enrichedPurchasFromQuery: EnrichedPurchaseBackOffice | undefined
  ) => void;
  setPurchaseIsDisabled: (isDisabled: boolean) => void;
}

const PurchaseOrderInput: FC<PurchaseOrderInputProps> = ({
  setPurchaseFromEnrichedPurchase,
  setPurchaseIsDisabled,
}: PurchaseOrderInputProps) => {
  const [form] = Form.useForm();

  const [purchaseNumber, setPurchaseNumber] = useState<string>('');
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [queryEnrichedPurchase, setQueryEnrichedPurchase] =
    useState<EnrichedPurchaseBackOffice>();

  const [getEnrichedPurchaseResponse, { data }] = useGetEnrichedPurchaseLazyQuery(
    purchaseNumber,
    true
  );

  const onFinish = async (event: { purchaseNumberInput: string }) => {
    setIsVisible(true);
    const { purchaseNumberInput } = event;
    setPurchaseNumber(purchaseNumberInput);
    const response = await getEnrichedPurchaseResponse({
      variables: {
        id: purchaseNumberInput,
      },
    });
    const purchase = response.data?.getEnrichedPurchase;
    if (purchase) {
      if (purchase.purchase?.isDisabled) {
        setPurchaseIsDisabled(true);
      }
      setQueryEnrichedPurchase(purchase);
      form.resetFields();
    }
    // If the request does not have an error, but the purchase null, it means it was already validated
    else if (!purchase && response.data && !response.error) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.error(texts.purchaseAlreadyValidated);
    }
    setIsVisible(false);
  };

  const getPurchaseFromQuery = () => {
    if (data && data.getEnrichedPurchase) {
      const purchase = data.getEnrichedPurchase;
      if (purchase.purchaseQrScans.length === 0) {
        setPurchaseFromEnrichedPurchase(purchase);
      } else {
        const errorMsg = texts.purchaseNotFoundError + purchaseNumber.toString();
        message.error(errorMsg) as unknown as void;
        setPurchaseFromEnrichedPurchase(undefined);
      }
    } else if (purchaseNumber !== '' && data && !data.getEnrichedPurchase) {
      const errorMsg = texts.purchaseNotFoundError + purchaseNumber.toString();
      message.error(errorMsg) as unknown as void;
      setPurchaseFromEnrichedPurchase(undefined);
    }
  };

  useEffect(() => {
    getPurchaseFromQuery();
  }, [queryEnrichedPurchase]);

  return (
    <>
      <LoadingModal visible={isVisible} />
      <Row className={styles.PurchaseOrderContainer}>
        <Col span={24}>
          <Space direction="vertical" size="large">
            <h3>{texts.title}</h3>
            <Form onFinish={onFinish as unknown as undefined} layout="inline">
              <Form.Item
                name="purchaseNumberInput"
                rules={[{ required: true, message: texts.purchaseNumberInputError }]}
              >
                <InputNumber
                  placeholder={texts.purchaseNumberInput}
                  autoFocus
                  stringMode
                  controls={false}
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {texts.search}
                </Button>
              </Form.Item>
            </Form>
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default PurchaseOrderInput;
