import { PromotionCode } from 'apollo/types';
import dateFormat from 'utils/formats/dateFormat';

const dataToTable = (data: PromotionCode[] | undefined) => {
  if (!data) return [];
  const mappedPromotionCode = data?.map((promotionCode) => {
    const { suffix, codes, expirationDate, forWeekends, status, user, createdAt, id } =
      promotionCode;

    const tableData = {
      key: id,
      suffix,
      expirationDate: expirationDate
        ? dateFormat(new Date(expirationDate), true)
        : 'Error',
      createdAt: createdAt ? dateFormat(new Date(createdAt), true) : 'Error',
      email: user?.email,
      status,
      forWeekends,
      numberOfCodes: codes ? codes.length : 0,
      codes,
      actions: promotionCode,
      validity: promotionCode,
    };
    return tableData;
  });
  return mappedPromotionCode;
};

export default dataToTable;
