import { FilterType } from 'apollo/types';

const filterTypeMapper: { [key: string]: FilterType } = {
  dateVisit: FilterType.Date,
  orderNumber: FilterType.PurchaseId,
  rut: FilterType.Rut,
  email: FilterType.Email,
  phone: FilterType.Phone,
  ticketType: FilterType.TicketType,
  documentNumber: FilterType.DocumentNumber,
  fullName: FilterType.FullName,
  disabled: FilterType.Disabled,
  billingSystemDocumentNumber: FilterType.BillingSystemDocumentNumber,
  billingSystemDocumentType: FilterType.BillingSystemDocumentType,
};

export default filterTypeMapper;
