import { useMutation } from '@apollo/client';
import { MutationEditPlaceArgs, Place } from 'apollo/types';
import { gql } from 'graphql.macro';

const EDIT_PLACE = gql`
  mutation editPlace($input: EditPlaceInput!) {
    editPlace(input: $input) {
      id
      mainDescription
      name
      placeMainImage
      secondaryDescription
    }
  }
`;

const useEditPlaceMutation = () => {
  return useMutation<{ editPlace: Place }, MutationEditPlaceArgs>(EDIT_PLACE, {});
};

export default useEditPlaceMutation;
