import { ExperienceTickets, Grills } from 'apollo/types';
import { GroupedActivityTicketsRow } from '../types';

const mapGroupedActivityTickets = (
  experiences?: ExperienceTickets[],
  grills?: Grills
): GroupedActivityTicketsRow[] => {
  if (!experiences) return [];
  const mappedExperiences = experiences.map((groupedActivityTicket) => {
    const mappedGroupedActivity: GroupedActivityTicketsRow = {
      key: groupedActivityTicket.name,
      type: 'experience',
      ticketType: groupedActivityTicket.name || '',
      availableAmount:
        groupedActivityTicket.stockPerTime * groupedActivityTicket.availableTimes,
      stockPerTime: groupedActivityTicket.stockPerTime,
      soldAmount: groupedActivityTicket.totalCount,
      activityTimes: groupedActivityTicket.times,
    };
    return mappedGroupedActivity;
  });
  if (grills) {
    if (grills.reservedGrills.length > 0) {
      mappedExperiences.push({
        key: 'quincho',
        type: 'grill',
        ticketType: 'Quincho',
        availableAmount: grills.stock,
        stockPerTime: grills.stock,
        soldAmount: grills.count,
        reservedGrills: grills.reservedGrills,
      });
    }
  }

  return mappedExperiences;
};

export default mapGroupedActivityTickets;
