const promotionCodeExcelColumns = () => {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Código',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Usado',
      dataIndex: 'used',
      key: 'used',
    },
    {
      title: 'Expira',
      dataIndex: 'expirationDate',
      key: 'expirationDate',
    },
    {
      title: 'ID compra asociada',
      dataIndex: 'associatedPurchase',
      key: 'associatedPurchase',
    },
  ];
};

export default promotionCodeExcelColumns;
