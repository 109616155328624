import { gql, useLazyQuery } from '@apollo/client';
import { TicketsCount, QueryListTicketsGroupedArgs } from 'apollo/generated/types';

const LIST_TICKETS_GROUPED = gql`
  query listTicketsGrouped($date: String!) {
    listTicketsGrouped(date: $date) {
      entrances {
        adultCount
        count
        entranceTicketTypeName
        kidsOrElderlyCount
        stock
        visitedAdultCount
        visitedCount
        visitedKidsOrElderlyCount
      }
      experiences {
        name
        stockPerTime
        availableTimes
        times {
          count
          startEndHour
        }
        totalCount
        availableTimes
      }
      grills {
        count
        stock
        reservedGrills {
          clientEmail
          clientName
          clientPhone
          name
        }
      }
    }
  }
`;

const useListTicketsGroupedQuery = () => {
  return useLazyQuery<{ listTicketsGrouped: TicketsCount }, QueryListTicketsGroupedArgs>(
    LIST_TICKETS_GROUPED
  );
};
export default useListTicketsGroupedQuery;
