import { gql, useQuery } from '@apollo/client';
import { Pack, QueryListPacksArgs } from 'apollo/generated/types';

const LIST_PACKS = gql`
  query listPacks($date: String) {
    listPacks(date: $date) {
      id
      name
      adultValue
      otherValue
      description
      status
      visibility
      activities {
        id
        name
      }
      entranceTicketTypes {
        id
        name
      }
      packImages {
        id
        image
      }
    }
  }
`;

const useListPacksQuery = (date?: string) => {
  return useQuery<{ listPacks: Pack[] }, QueryListPacksArgs>(LIST_PACKS, {
    variables: { date },
  });
};
export default useListPacksQuery;
