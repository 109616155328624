import { gql, useQuery } from '@apollo/client';
import { Program } from 'apollo/types';

const LIST_PROGRAMS = gql`
  query listPrograms {
    listPrograms {
      id
      title
      subtitle
      description
      validForWeekends
      status
      hoursDuration
      targetGroup
      videoLink
      coverImage {
        id
        url
      }
      secondaryImages {
        id
        url
      }
    }
  }
`;

const useListProgramsQuery = () => {
  return useQuery<{ listPrograms: Program[] }>(LIST_PROGRAMS, {});
};

export default useListProgramsQuery;
