import moment, { Moment } from 'moment';

const isNotSameMonth = (firstDate: Moment | null, secondDate: Moment | null): boolean => {
  return (
    moment(firstDate).startOf('month').valueOf() !==
    moment(secondDate).startOf('month').valueOf()
  );
};

export default isNotSameMonth;
