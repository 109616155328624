import {
  PackColumn,
  EntranceConfigurationEditMutation,
  EntranceTicketFetchResult,
} from '../types';

const statusOnChange = async (
  item: PackColumn | undefined,
  editMutation: EntranceConfigurationEditMutation,
  refetch: () => Promise<unknown>
): Promise<EntranceTicketFetchResult> => {
  const id = item?.actions.id || '';
  const toEdit = { id, status: !item?.status };
  const edited = await editMutation({
    variables: { input: toEdit },
  });
  await refetch();
  return edited;
};

export default statusOnChange;
