/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC } from 'react';
import {
  Checkbox,
  Col,
  DatePicker,
  Form as AntdForm,
  Input,
  InputNumber,
  message,
  Row,
} from 'antd';
import { Modal, Table } from 'components/UI';
import * as texts from 'assets/texts/formModal';
import Button from 'components/UI/Button';
import handleCancelForm from 'components/sharedComponents/FormModal/utils/handleCancelForm';
import useQueryListPromotionOptions from 'apollo/resolvers/promotionCode/listOptions';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';
import useMutationCreatePromotionCode from 'apollo/resolvers/promotionCode/create';
import { CreatePromotionCodeInput } from 'apollo/types';
import styles from './CreationModal.module.scss';
import columns from './utils/columns';
import dataToTable from './utils/dataToTable';
import activityMapper from './utils/formValuesMapper/activityMapper';
import entranceTicketTypeMapper from './utils/formValuesMapper/entranceTicketTypeMapper';
import packsMapper from './utils/formValuesMapper/packsMapper';

export interface CreationModalProps {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  refetchPromotionCodes: () => Promise<void>;
}

const CreationModal: FC<CreationModalProps> = (props: CreationModalProps) => {
  const [useCreatePromotionCode] = useMutationCreatePromotionCode();
  const { data, loading } = useQueryListPromotionOptions();

  const { isModalVisible, setIsModalVisible, refetchPromotionCodes } = props;
  const [form] = AntdForm.useForm();

  const { Item } = AntdForm;

  const handleCancelFunc = () => {
    handleCancelForm(form, setIsModalVisible);
  };
  const userAuth = window.localStorage.getItem('UserAuth');

  const onSubmitForm = async () => {
    const formValues = form.getFieldsValue();
    const { activities } = activityMapper(formValues);
    const { entrances } = entranceTicketTypeMapper(formValues);
    const { packs } = packsMapper(formValues);
    if (userAuth) {
      const { username } = JSON.parse(userAuth) as { username: string };
      const args: CreatePromotionCodeInput = {
        promotionCodeActivities: activities,
        promotionCodeEntrances: entrances,
        promotionCodePacks: packs,
        expirationDate: formValues.expirationDate,
        forWeekends: formValues.forWeekends ? formValues.forWeekends : false,
        suffix: formValues.suffix,
        status: true,
        amountOfCodes: formValues.amountOfCodes,
        email: username,
      };

      try {
        await useCreatePromotionCode({ variables: { input: args } });
        await message.success('Código de promoción creado correctamente', 0.5);
      } catch {
        await message.error('Error al crear el código de promoción', 0.5);
      }
      form.resetFields();
      await refetchPromotionCodes();
      setIsModalVisible(false);
    }
  };

  const setFooterButtons = (): JSX.Element[] => {
    const footerButtons = [
      <Button key={1} type="primary" onClick={handleCancelFunc} text={texts.cancel} />,
      <Button
        key={2}
        type="primary"
        onClick={async () => {
          await onSubmitForm();
        }}
        text={texts.create}
      />,
    ];
    return footerButtons;
  };

  const renderDiscountPercentage = (
    id: string,
    type: { id: string; name: string; type: string }
  ) => {
    return (
      <Item
        key={`discountPercentage_${type.type}_${type.id}`}
        name={`discountPercentage_${type.type}_${type.id}`}
      >
        <InputNumber
          placeholder="0% de descuento"
          className={styles.Input}
          min={0}
          max={100}
        />
      </Item>
    );
  };

  const renderMaxTicketsQuantity = (
    id: string,
    type: { id: string; name: string; type: string }
  ): JSX.Element => {
    return (
      <Item
        key={`maxTickets_${type.type}_${type.id}`}
        name={`maxTickets_${type.type}_${type.id}`}
      >
        <InputNumber placeholder="0 Tickets" className={styles.Input} min={1} />
      </Item>
    );
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current < moment().endOf('day');
  };

  return (
    <>
      <Modal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        footer={setFooterButtons()}
        onCancel={handleCancelFunc}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onOk={async () => {
          await onSubmitForm();
        }}
        title={texts.title}
        className={styles.ModalTitle}
      >
        <Row>
          <Col span={24}>
            <AntdForm form={form} layout="inline">
              <Table
                size="small"
                bordered
                className={styles.OptionsTable}
                dataSource={dataToTable(data?.listPromotionOptions)}
                loading={loading}
                columns={columns(renderDiscountPercentage, renderMaxTicketsQuantity)}
                pagination={false}
                scroll={{ y: 200 }}
              />
            </AntdForm>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <AntdForm className={styles.Center} form={form} layout="inline">
              <Row className={styles.Row}>
                <Col span={12}>
                  <Item key="suffix" name="suffix" label="Sufijo" required>
                    <Input />
                  </Item>
                </Col>
                <Col span={12}>
                  <Item
                    key="expirationDate"
                    name="expirationDate"
                    label="Día de expiración"
                    required
                  >
                    <DatePicker disabledDate={disabledDate} />
                  </Item>
                </Col>
              </Row>
              <Row className={styles.Row}>
                <Col span={12}>
                  <Item
                    key="amountOfCodes"
                    name="amountOfCodes"
                    label="Cantidad de códigos"
                    required
                  >
                    <InputNumber className={styles.NotRoundInput} min={0} />
                  </Item>
                </Col>
                <Col span={12}>
                  <Item
                    key="forWeekends"
                    name="forWeekends"
                    label="Válido para fin de semanas"
                    required
                    valuePropName="checked"
                  >
                    <Checkbox defaultChecked={false} />
                  </Item>
                </Col>
              </Row>
            </AntdForm>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default CreationModal;
