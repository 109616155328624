/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { FC, useEffect, useState } from 'react';
import Table from 'components/UI/Table';
import * as texts from 'assets/texts/giftCard';
import { Pagination } from 'antd';
import { GiftCardWithRemainingAmount } from 'apollo/types';
import useListGiftCardsLazyQuery from 'apollo/resolvers/giftCard/list';
import dataToTable from './utils/dataToTable';
import styles from './Clients.module.scss';
import createColumns from './utils/createColumns';

const GiftCards: FC = () => {
  const columns = createColumns();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentPageSize, setCurrentPageSize] = useState<number>(10);
  const [count, setCount] = useState<number>();
  const [giftCards, setGiftCards] = useState<GiftCardWithRemainingAmount[]>();

  const [listGiftCards, { loading }] = useListGiftCardsLazyQuery();

  const handleListGiftCards = async () => {
    const response = await listGiftCards({
      variables: {
        input: {
          page: currentPage,
          pageSize: currentPageSize,
        },
      },
    });
    if (response.data?.listGiftCards) {
      setGiftCards(response.data.listGiftCards.giftCards);
      setCount(response.data.listGiftCards.count);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    handleListGiftCards();
  }, [currentPage, currentPageSize]);

  return (
    <>
      <h2 className={styles.Title}>{texts.title}</h2>
      <Table
        columns={columns}
        dataSource={dataToTable(giftCards)}
        loading={loading}
        pagination={false}
      />
      <Pagination
        className={styles.Pagination}
        showSizeChanger
        total={count}
        pageSize={currentPageSize}
        onChange={(page, pageSize) => {
          setCurrentPage(page);
          setCurrentPageSize(pageSize);
        }}
        current={currentPage}
      />
    </>
  );
};

export default GiftCards;
