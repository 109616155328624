import { gql, useMutation } from '@apollo/client';
import { PlaceImage, MutationDeletePlaceImageArgs } from 'apollo/generated/types';

const DELETE_PLACE_IMAGE = gql`
  mutation deletePlaceImage($input: DeletePlaceImageInput!) {
    deletePlaceImage(input: $input) {
      id
    }
  }
`;

const useDeletePlaceImageMutation = () => {
  return useMutation<{ deletePlaceImage: PlaceImage }, MutationDeletePlaceImageArgs>(
    DELETE_PLACE_IMAGE
  );
};
export default useDeletePlaceImageMutation;
