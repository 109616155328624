import { EnrichedPurchaseEntranceTickets } from 'apollo/types';
import Table from 'components/UI/Table';
import React, { FC } from 'react';
import styles from '../OrderDetail.module.scss';
import dataToTable from './utils/dataToTable';
import columns from './utils/columns';

export interface EntrancesTableProps {
  purchaseId: string;
  entranceTickets: EnrichedPurchaseEntranceTickets[] | undefined;
  visitDate: string | undefined;
  // setVisitDate: (isVisitDateValid: boolean) => void;
}

const EntrancesTable: FC<EntrancesTableProps> = ({
  purchaseId,
  entranceTickets,
  visitDate,
}: EntrancesTableProps) => {
  const isTicketInfoAvailable = entranceTickets && purchaseId !== undefined;

  return (
    <Table
      title={() => 'Entradas'}
      columns={columns}
      dataSource={dataToTable(entranceTickets || [], visitDate || '')}
      size="large"
      pagination={false}
      className={!isTicketInfoAvailable ? styles.Disabled : ''}
    />
  );
};

export default EntrancesTable;
