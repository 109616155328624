import { EnrichedPurchaseActivityTimes } from 'apollo/generated/types';

export interface MappedReservationActivity {
  key: number;
  ticketType: string;
  quantity: number;
  activityTime: string;
}

const dataToTable = (data: EnrichedPurchaseActivityTimes[]) => {
  const mappedData = data
    .map((summarizedActivity, index) => {
      const { startHour, endHour } = summarizedActivity.activityTime;
      const mappedSummarizedActivity: MappedReservationActivity = {
        key: index,
        ticketType: summarizedActivity.name || '',
        quantity: summarizedActivity.quantity || 0,
        activityTime: `${startHour} - ${endHour}`,
      };

      return Array(1).fill(mappedSummarizedActivity) as MappedReservationActivity[];
    })
    .flat();

  return mappedData;
};

export default dataToTable;
