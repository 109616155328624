import { gql, useMutation } from '@apollo/client';
import { PackImage, MutationUploadPackImageArgs } from 'apollo/generated/types';

const UPLOAD_PACK_IMAGE = gql`
  mutation uploadPackImage($input: UploadPackImageInput!) {
    uploadPackImage(input: $input) {
      id
      image
      uploadUrl
    }
  }
`;

const useUploadPackImageMutation = () => {
  return useMutation<{ uploadPackImage: PackImage }, MutationUploadPackImageArgs>(
    UPLOAD_PACK_IMAGE
  );
};
export default useUploadPackImageMutation;
