import React, { useState, FC, useEffect } from 'react';
import moment, { Moment } from 'moment';
import Calendar from 'components/UI/Calendar';
import { Spin } from 'antd';
import useListOpenDaysQuery from 'apollo/resolvers/openDays/listOpenDays';
import useUpdateOpenDaysMutation from 'apollo/resolvers/openDays/updateOpenDays';
import * as texts from 'assets/texts/openDays';
import { LoadingModal } from 'components/UI';
import ActivityTimetableConfigurator from 'components/views/OpenDays/ActivityTimetableConfigurator/index';
import useListActivitiesQuery from 'apollo/resolvers/activity/list';
import CloseDayConfirmationModal from 'components/views/OpenDays/CloseDayConfirmationModal';
import useListParkAvailabilityQuery from 'apollo/resolvers/parkAvailability/listParkAvailability';
import { ParkAvailability } from 'apollo/types';
import {
  isSelectedStyle,
  isNotSelectedStyle,
  isNotTodayContainerStyle,
  isTodayContainerStyle,
} from './utils/styles';
import styles from './OpenDays.module.scss';
import mapOpenDays from './utils/mapOpenDays';
import isNotSameMonth from './utils/isNotSameMonth';
import isSameMonth from './utils/isSameMonth';
import getParkAvailabilityForDay from './utils/getParkAvailabilityForDay';

const OpenDays: FC = () => {
  const [selectedDates, setSelectedDates] = useState<number[]>([]);
  const [lastDate, setLastDate] = useState<Moment | null>(moment(new Date()));
  const [selectedDate, setSelectedDate] = useState<number>();
  const [selectedParkAvailability, setSelectedParkAvailability] =
    useState<ParkAvailability>();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isConfigurationModalVisible, setIsConfigurationModalVisible] =
    useState<boolean>(false);
  const [isCloseDayModalVisible, setIsCloseDayModalVisible] = useState<boolean>(false);
  const { data: parkAvailabilyData } = useListParkAvailabilityQuery();
  const {
    data: activitiesData,
    refetch: refetchActivitiesData,
    loading: loadingActivitiesData,
  } = useListActivitiesQuery();
  const aviaryActivityTimes = activitiesData?.listActivitiesBackOffice.find(
    (activity) => activity.name === 'Aviario'
  )?.activityTimes;
  const { data, refetch, loading } = useListOpenDaysQuery();
  const formattedHistoricDates = mapOpenDays(data?.listOpenDays);
  const [updateOpenDaysMutation] = useUpdateOpenDaysMutation();

  const handleOnSuccessNewOpenDay = async (activityTimesIdsToSave: number[]) => {
    if (selectedDate) {
      const formattedSelectDay = moment(selectedDate).toISOString();
      setIsConfigurationModalVisible(false);
      setIsVisible(true);
      await updateOpenDaysMutation({
        variables: {
          input: { day: formattedSelectDay, activityTimesIds: activityTimesIdsToSave },
        },
      });
      await refetchActivitiesData();
      await refetch();
      setIsVisible(false);
    }
  };

  const handleCloseDayConfirmation = async () => {
    if (selectedDate) {
      const formattedSelectDay = moment(selectedDate).toISOString();
      setIsCloseDayModalVisible(false);
      setIsVisible(true);
      await updateOpenDaysMutation({
        variables: {
          input: { day: formattedSelectDay, activityTimesIds: [] },
        },
      });
      await refetch();
      setIsVisible(false);
    }
  };

  const onValueChange = (date: Moment | null) => {
    if (isSameMonth(date, lastDate)) {
      const newDate = moment(date).startOf('day').valueOf();
      if (selectedDates.includes(newDate)) {
        setSelectedDates([...selectedDates.filter((item) => item !== newDate)]);
      } else if (formattedHistoricDates?.includes(newDate)) {
        setSelectedDate(newDate);
        setIsCloseDayModalVisible(true);
      } else {
        const newAvailability = getParkAvailabilityForDay(
          moment(selectedDate),
          parkAvailabilyData?.listParkAvailability || []
        );
        setSelectedDate(newDate);
        setSelectedParkAvailability(newAvailability);
        setIsConfigurationModalVisible(true);
      }
    }
    setLastDate(moment(date));
  };

  const dateRender = (currentDate: Moment) => {
    const numberDate = moment(currentDate).startOf('day').valueOf();

    const isToday = currentDate.isSame(new Date(), 'day');
    const containerStyle = isToday ? isTodayContainerStyle : isNotTodayContainerStyle;

    const isSelected =
      (selectedDates.includes(numberDate) &&
        !formattedHistoricDates?.includes(numberDate)) ||
      (!selectedDates.includes(numberDate) &&
        formattedHistoricDates?.includes(numberDate));
    let style = isSelected ? isSelectedStyle : isNotSelectedStyle;
    if (isSelected && isNotSameMonth(currentDate, lastDate)) {
      style = { ...style, backgroundColor: '#6db690' };
    }

    return (
      <div style={containerStyle} className={styles.Container}>
        <div style={style}>{currentDate.date()}</div>
      </div>
    );
  };

  const handleCancel = () => {
    setIsConfigurationModalVisible(false);
  };

  const handleRefetchInClosDayModal = async () => {
    if (isCloseDayModalVisible === false) {
      await refetchActivitiesData();
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    handleRefetchInClosDayModal();
  }, [isCloseDayModalVisible]);

  return (
    <>
      <ActivityTimetableConfigurator
        isModalVisible={isConfigurationModalVisible}
        setIsModalVisible={setIsConfigurationModalVisible}
        onCancel={handleCancel}
        aviaryActivityTimes={aviaryActivityTimes}
        parkAvailability={selectedParkAvailability}
        handleOnSuccessNewOpenDay={handleOnSuccessNewOpenDay}
      />
      <CloseDayConfirmationModal
        isModalVisible={isCloseDayModalVisible}
        setIsModalVisible={setIsCloseDayModalVisible}
        confirmationFunction={handleCloseDayConfirmation}
      />
      <LoadingModal visible={isVisible || loadingActivitiesData} />
      <div className={styles.TitleContainer}>
        <h2>{texts.title}</h2>
        <p>{texts.subTitle}</p>
      </div>
      <Spin spinning={loading}>
        <Calendar
          className={styles.Calendar}
          onSelect={onValueChange}
          dateFullCellRender={dateRender}
        />
      </Spin>
    </>
  );
};

export default OpenDays;
