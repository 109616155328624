import React, { FC } from 'react';
import { Select, Input, Form, FormInstance } from 'antd';
import { ActivityTime } from 'apollo/types';
import options from '../utils/createHourOptions';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: 'startHour' | 'endHour';
  title: string;
  inputType: string;
  record: ActivityTime;
  form: FormInstance;
  index: number;
  children: React.ReactNode;
}

const EditableCell: FC<EditableCellProps> = ({
  editing,
  dataIndex,
  inputType,
  record,
  children,
  form,
  ...restProps
}) => {
  const optionOnChange = (val: string) => {
    // eslint-disable-next-line no-param-reassign
    record[dataIndex] = val;
  };
  const inputNode =
    inputType === 'select' ? (
      <Select options={options()} onChange={optionOnChange} />
    ) : (
      <Input />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;
