export const title = 'Reservaciones';
export const details = 'Detalles';
export const detail = 'Detalle';
export const purchaseDetail = 'Detalle de compra';
export const disablePurchases = 'Deshabilitar compras';
export const errorDisablePurchases = 'Error al deshabilitar compras';
export const disableSuccess = 'Compras deshabilitadas';
export const disabled = 'Deshabilitado';
export const enabled = 'Habilitado';
export const disabledBy = 'Deshabilitado por';
export const inTheDate = 'Fecha';
export const email = 'Email';
export const name = 'Nombre';
export const totalPrice = 'Total:';
