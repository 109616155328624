import { gql, useMutation } from '@apollo/client';
import { MutationCreatePromotionCodeArgs, PromotionCode } from 'apollo/types';

const CREATE_PROMOTION_CODE = gql`
  mutation createPromotionCode($input: CreatePromotionCodeInput!) {
    createPromotionCode(input: $input) {
      id
    }
  }
`;

const useMutationCreatePromotionCode = () => {
  return useMutation<
    { createPromotionCode: PromotionCode },
    MutationCreatePromotionCodeArgs
  >(CREATE_PROMOTION_CODE, {});
};

export default useMutationCreatePromotionCode;
