export const tableTitle = 'Configuración de noticias';
export const programs = 'Noticias';
export const edit = 'Editar';
export const deleteText = 'Eliminar';
export const title = 'Título';
export const publicationDate = 'Fecha de publicación';
export const viewCount = 'N° de visitas';
export const readTime = 'Tiempo de lectura (mins)';
export const status = 'Visible';
export const featured = 'Destacado';
export const photos = 'Fotos';
export const actions = 'Acciones';
export const cancel = 'Cancelar';
export const create = 'Crear';
export const save = 'Guardar';
export const deleteSuccess = 'Noticia eliminada exitosamente';
export const deleteConfirm = '¿Está seguro que desea eliminar esta noticia?';
export const deleteError = 'Error al eliminar noticia';
export const createNewsPost = 'Agregar nueva noticia';
export const createSuccess = 'Noticia creada exitosamente';
export const createError = 'Error al crear noticia';
export const editNewsPost = 'Editar noticia';
export const editSuccess = 'Noticia editada exitosamente';
export const editError = 'Error al editar noticia';
export const minutes = 'minutos';
export const image = 'Imagen';
export const coverImage = 'Imagen de portada';
export const mainImage = 'Imagen principal';
export const closingImages = 'Imagenes de cierre';
export const caption = 'Pie de foto';
export const content = 'Contenido';
export const firstContent = 'Primer contenido';
export const secondContent = 'Segundo contenido';
export const closeImages = 'Imágenes de cierre';
export const recommendations = 'Recomendaciones';
export const author = 'Autor';
export const required = 'Campo requerido';
