import { ThingToDo } from 'apollo/types';
import { FormFields } from 'components/UI/Form/types';
import { ReactNode } from 'react';
import * as texts from '../../../../assets/texts/thingsToDoConfiguration';

const thingsToDoFormFields = (
  isCreation: boolean,
  editableRow?: ThingToDo,
  uploadElements?: ReactNode
): FormFields[] => {
  const formFields = [
    {
      name: 'name',
      label: texts.name,
      type: 'text',
      initialValue: editableRow?.name || '',
      rules: [{ required: isCreation }],
    },
    {
      name: 'description',
      label: texts.description,
      type: 'textarea',
      initialValue: editableRow?.description || '',
      rules: [{ required: isCreation }],
    },
  ];

  if (isCreation) {
    return formFields;
  }

  return [
    ...formFields,
    {
      name: 'images',
      label: 'Fotos',
      type: 'images',
      uploadelement: uploadElements,
      rules: [{ required: false }],
    },
  ];
};

export default thingsToDoFormFields;
