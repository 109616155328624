import { EntranceImage, EntranceTicketType } from 'apollo/types';

const columns = (
  statusColumnRender: (value: boolean, record: unknown) => JSX.Element,
  editButton: (EditItem: EntranceTicketType) => JSX.Element,
  imageGroup: (item: EntranceImage[] | undefined) => JSX.Element,
  visibilityColumnRender: (value: boolean, record: unknown) => JSX.Element
) => {
  return [
    {
      title: 'Tipo de ticket',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Precio adultos',
      dataIndex: 'adultValue',
      key: 'adultValue',
    },
    {
      title: 'Precio Adulto mayor o Niños < 4 años',
      dataIndex: 'otherValue',
      key: 'otherValue',
    },
    {
      title: 'Stock tickets diario',
      dataIndex: 'maxStock',
      key: 'maxStock',
    },
    {
      title: 'Fotos',
      dataIndex: 'entranceImages',
      key: 'entranceImages',
      render: imageGroup,
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: statusColumnRender,
    },
    {
      title: 'Visibilidad',
      dataIndex: 'visibility',
      key: 'visibility',
      render: visibilityColumnRender,
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: editButton,
    },
  ];
};

export default columns;
