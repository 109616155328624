import { useMutation } from '@apollo/client';
import { MutationCreateThingToDoArgs, ThingToDo } from 'apollo/types';
import { gql } from 'graphql.macro';

const CREATE_THINGS_TO_DO = gql`
  mutation createThingToDo($input: CreateThingToDoInput!) {
    createThingToDo(input: $input) {
      description
      id
      name
      thingToDoImages {
        id
        image
      }
    }
  }
`;

const useCreateThingToDoMutation = () => {
  return useMutation<{ createThingToDo: ThingToDo }, MutationCreateThingToDoArgs>(
    CREATE_THINGS_TO_DO,
    {}
  );
};

export default useCreateThingToDoMutation;
