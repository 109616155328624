import React, { FC, useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { showErrorModal, errorData } from 'apollo/cache';
import NavBar from 'components/Layout/NavBar';
import ErrorModal from 'views/Error';
import { Col, Row } from 'antd';
import styles from './NavigatedLayout.module.scss';
import Sidebar from '../Sidebar';
import TabBar from '../TabBar';

interface Props {
  children?: JSX.Element;
}

const NavigatedLayout: FC<Props> = ({ children }: Props) => {
  const showErrorModalHook = useReactiveVar(showErrorModal);

  const [errorModalVisible, setErrorModalVisible] = useState(false);

  useEffect(() => {
    if (showErrorModalHook) {
      setErrorModalVisible(true);
    }
  }, [showErrorModalHook]);

  return (
    <div className={styles.Scrollable}>
      <NavBar />
      <div className={styles.MainContainer}>
        <Row className={styles.FullHeight}>
          <Col xs={4}>
            <Sidebar />
          </Col>
          <Col xs={20}>
            <TabBar />
            <div className={styles.ComponentContainer}>{children}</div>
          </Col>
        </Row>
      </div>
      <ErrorModal
        visible={errorModalVisible}
        onClose={() => {
          setErrorModalVisible(false);
          showErrorModal(false);
          errorData(null);
        }}
      />
    </div>
  );
};

NavigatedLayout.defaultProps = {
  children: undefined,
};

export default NavigatedLayout;
