export const title = 'Configuración de programas';
export const programs = 'Programas';
export const edit = 'Editar';
export const editTitle = 'Editar programa';
export const name = 'Nombre';
export const subtitle = 'Subtítulo';
export const validForWeekends = 'Disponible para fines de semana';
export const status = 'Estado';
export const hoursDuration = 'Duración';
export const targetGroup = 'Rango de edades';
export const videoLink = 'Link de video';
export const noVideoLink = 'Sin link';
export const photos = 'Fotos';
export const link = 'Link';
export const actions = 'Acciones';
export const coverInputLabel = 'Portada';
export const coverInputRecommendationFirstPart = 'Resolución recomendada';
export const coverInputRecommendationSecondPart = '1920x1080';
