import { gql, useLazyQuery } from '@apollo/client';
import { NewsPost } from 'apollo/types';

const LIST_NEWS_POSTS = gql`
  query listNewsPosts($input: ListNewsPostsInput!) {
    listNewsPosts(input: $input) {
      count
      newsPosts {
        id
        title
        publicationDate
        viewCount
        featured
        status
        readTime
        author
        contents {
          id
          order
          content
        }
        coverImage {
          id
          url
          type
          caption
        }
        mainImage {
          id
          url
          type
          caption
        }
        closingImages {
          id
          url
          type
          caption
        }
        newsPostRecommendations {
          id
          title
        }
      }
    }
  }
`;

const useListNewsPostsQuery = () => {
  return useLazyQuery<{
    listNewsPosts: {
      count: number;
      newsPosts: NewsPost[];
    };
  }>(LIST_NEWS_POSTS, { fetchPolicy: 'no-cache' });
};

export default useListNewsPostsQuery;
