import React, { FC } from 'react';
import * as texts from 'assets/texts/gateValidation';
import { Row, Col } from 'antd';
import { Client } from 'apollo/types';
import styles from './ClientInformation.module.scss';

export interface ClientInformationProps {
  client: Client | undefined;
}

const ClientInformation: FC<ClientInformationProps> = ({
  client,
}: ClientInformationProps) => {
  return (
    <>
      {client && (
        <Row className={styles.Text}>
          <Col span={24}>
            <h3 className={styles.Text}>{texts.clientInformation}</h3>
            <Row className={styles.Layout} gutter={[8, 24]} justify="space-around">
              <Col className={styles.Column}>
                <b>{texts.id}:</b> {client.id}
              </Col>
              <Col className={styles.Column}>
                <b>{texts.document}:</b> {client.documentNumber}
              </Col>
              <Col className={styles.Column}>
                <b>{texts.name}:</b> {client.firstName} {client.lastName}
              </Col>
              {client.ageRange && (
                <Col className={styles.Column}>
                  <b>{texts.birthRange}:</b> {client.ageRange.name}
                </Col>
              )}
              <Col className={styles.Column}>
                <b>{texts.phoneNumber}:</b> {client.phone}
              </Col>
              <Col className={styles.Column}>
                <b>{texts.mail}:</b> {client.email}
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ClientInformation;
