import { ThingToDo, ThingToDoImage } from 'apollo/types';
import * as texts from 'assets/texts/thingsToDoConfiguration';

const columns = (
  renderActionColumns: (item: ThingToDo) => JSX.Element,
  imageGroup: (item: ThingToDoImage[] | undefined) => JSX.Element
) => {
  return [
    {
      title: texts.name,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: texts.description,
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: texts.photos,
      dataIndex: 'thingToDoImages',
      key: 'thingToDoImages',
      align: 'center' as const,
      render: imageGroup,
    },
    {
      title: texts.actions,
      dataIndex: 'actions',
      key: 'actions',
      align: 'center' as const,
      render: renderActionColumns,
    },
  ];
};

export default columns;
