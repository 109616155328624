// import { Col, DatePicker, Row } from 'antd';
import React, { FC, useEffect } from 'react';
// import GrillOccupation from 'components/views/Report/GrillOccupation';
// import ParkOccupation from 'components/views/Report/ParkOccupation';
// import ClientReccurencyComponent from 'components/views/Report/ClientRecurrency';
// import ParkSalesGroupByDay from 'components/views/Report/ParkSalesGroupByDay';
// import SaleReport from 'components/views/Report/SaleReport';
// import { Button } from 'components/UI';
// import * as texts from '../../assets/texts/report';
// import styles from './Report.module.scss';
// import {
//   ClientReccurencyExcel,
//   OccupationExcel,
//   ReportExcel,
//   SalesByGroupDayExcel,
//   SalesExcelData,
// } from './types';
// import toExcel from './utils/toExcel';

const Report: FC = () => {
  // const [year, setYear] = useState<string>();

  // const [grillsOccupationExcel, setGrillOccupationExcel] = useState<OccupationExcel>();
  // const [parkOccupationExcel, setParkOccupationExcel] = useState<OccupationExcel>();
  // const [entranceByGroupDayExcel, setEntranceGroupByDayExcel] =
  //   useState<SalesByGroupDayExcel>();
  // const [packsByGroupDayExcel, setPacksGroupByDayExcel] =
  //   useState<SalesByGroupDayExcel>();

  // const [clientReccurencyExcel, setClientReccurencyExcel] =
  //   useState<ClientReccurencyExcel>();

  // const [salesReportExcel, setSalesReportExcel] = useState<SalesExcelData>();

  // const onSelectYear = (selectedYear: string) => {
  //   setYear(selectedYear);
  // };

  // const excelDataReady =
  //   clientReccurencyExcel &&
  //   parkOccupationExcel &&
  //   grillsOccupationExcel &&
  //   entranceByGroupDayExcel &&
  //   packsByGroupDayExcel &&
  //   salesReportExcel;

  // const onClick = () => {
  //   if (
  //     year &&
  //     clientReccurencyExcel &&
  //     parkOccupationExcel &&
  //     grillsOccupationExcel &&
  //     entranceByGroupDayExcel &&
  //     packsByGroupDayExcel &&
  //     salesReportExcel
  //   ) {
  //     const reportDataForExcel: ReportExcel = {
  //       year,
  //       clientReccurencyExcel,
  //       parkOccupationExcel,
  //       grillsOccupationExcel,
  //       entranceByGroupDayExcel,
  //       packsByGroupDayExcel,
  //       salesReportExcel,
  //     };

  //     toExcel(reportDataForExcel);
  //   }
  // };

  useEffect(() => {
    window.location.replace(
      'https://tricao.retool.com/apps/497a202e-3a13-11ef-a0ae-9723c899d828/Annual%20Report'
    );
  }, []);

  return (
    <></>
    // <Col>
    //   <Row>
    //     <h1 className={styles.Title}>{texts.title}</h1>
    //   </Row>
    //   <Row align="middle">
    //     <h3 className={styles.Title}>{texts.selectYear}</h3>
    //     <DatePicker
    //       onChange={(date, dateString) => {
    //         // eslint-disable-next-line @typescript-eslint/no-floating-promises
    //         onSelectYear(dateString);
    //       }}
    //       picker="year"
    //       style={{ marginLeft: '20px' }}
    //     />
    //   </Row>
    //   {year && (
    //     <>
    //       <Row justify="space-between">
    //         <h2 className={styles.Title} style={{ marginTop: '20px' }}>
    //           {texts.report} {year}
    //         </h2>
    //         {excelDataReady && (
    //           <Button type="primary" text={texts.excel} onClick={onClick} />
    //         )}
    //       </Row>
    //       <ParkOccupation
    //         year={year}
    //         dataForExcel={(columns, dataSource) => {
    //           setParkOccupationExcel({ columns, dataSource });
    //         }}
    //       />
    //       <GrillOccupation
    //         year={year}
    //         dataForExcel={(columns, dataSource) => {
    //           setGrillOccupationExcel({ columns, dataSource });
    //         }}
    //       />
    //       <ClientReccurencyComponent
    //         year={year}
    //         dataForExcel={(columns, dataSource) => {
    //           setClientReccurencyExcel({ columns, dataSource });
    //         }}
    //       />

    //       <ParkSalesGroupByDay
    //         year={year}
    //         dataForExcel={(parkSalesExcelData) => {
    //           setEntranceGroupByDayExcel({
    //             columns: parkSalesExcelData[0].columns,
    //             dataSource: parkSalesExcelData[0].dataSource,
    //           });
    //           setPacksGroupByDayExcel({
    //             columns: parkSalesExcelData[1].columns,
    //             dataSource: parkSalesExcelData[1].dataSource,
    //           });
    //         }}
    //       />

    //       <SaleReport
    //         year={year}
    //         dataForExcel={(saleExcelData) => {
    //           setSalesReportExcel(saleExcelData);
    //         }}
    //       />
    //     </>
    //   )}
    // </Col>
  );
};

export default Report;
