import { gql, useMutation } from '@apollo/client';
import { Pack, MutationCreatePackArgs } from 'apollo/generated/types';

const CREATE_PACK = gql`
  mutation createPack($input: CreatePackInput!) {
    createPack(input: $input) {
      id
      adultValue
      description
      enDescription
      enName
      name
      otherValue
      status
    }
  }
`;

const useCreatePackMutation = () => {
  return useMutation<{ createPackType: Pack }, MutationCreatePackArgs>(CREATE_PACK, {});
};
export default useCreatePackMutation;
