import React, { FC, useState } from 'react';
import { Row, Col, Tag, message } from 'antd';
import { Table, Button, Switch } from 'components/UI';
import * as texts from 'assets/texts/promotionCodeConfiguration';
import { Code, PromotionCode } from 'apollo/types';
import useQueryListPromotionCodes from 'apollo/resolvers/promotionCode/list';
import useMutationEditPromotionCode from 'apollo/resolvers/promotionCode/edit';
import { CopyOutlined, SaveOutlined } from '@ant-design/icons';
import CreationModal from 'components/views/PromotionCodeConfiguration/CreationModal';
import { PromotionCodeColumn } from './types';
import statusOnChange from './utils/statusOnChange';
import columns from './utils/createColumns';
import dataToTable from './utils/dataToTable';
import styles from './PromotionCodeConfiguration.module.scss';
import forWeekendsOnChange from './utils/forWeekendOnChange';
import toExcel from './utils/toExcel';
import promotionCodeExcelColumns from './utils/excel/columns';
import dataToExcel from './utils/excel/dataSource';

const PromotionCodeConfiguration: FC = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const { data, loading, refetch } = useQueryListPromotionCodes();
  const [editPromotionCode] = useMutationEditPromotionCode();

  const refetchFunction = async () => {
    await refetch();
  };

  const copyCodeToClipboard = async (code: Code) => {
    await navigator.clipboard.writeText(code.code);
    await message.success(texts.copiedCodeToClipboard, 0.5);
  };

  const downloadCodes = (codes: Code[], expirationDate?: string | null) => {
    const excelCols = promotionCodeExcelColumns();
    const dataSource = dataToExcel(codes, expirationDate);
    toExcel(excelCols, dataSource);
  };

  const statusColumnRender = (value: boolean, record: unknown): JSX.Element => {
    const promotionCode = record as PromotionCodeColumn;
    return (
      <Switch
        defaultChecked={value}
        onClick={() => statusOnChange(promotionCode, editPromotionCode)}
      />
    );
  };

  const forWeekendColumnRender = (value: boolean, record: unknown): JSX.Element => {
    const promotionCode = record as PromotionCodeColumn;
    return (
      <Row className={styles.FullWidth} justify="center">
        <Col className={styles.Switch} span={24}>
          <Switch
            defaultChecked={!!promotionCode.forWeekends}
            disabled
            onClick={() => forWeekendsOnChange(promotionCode, editPromotionCode)}
          />
        </Col>
      </Row>
    );
  };

  const actionButtons = (item: PromotionCode): JSX.Element => {
    return (
      <>
        {item.codes.length === 1 && (
          <>
            <Row justify="space-around">
              <Col span={24}>
                <Button
                  onClick={() => copyCodeToClipboard(item.codes[0])}
                  type="default"
                  text={texts.copy}
                  icon={<CopyOutlined />}
                />
              </Col>
            </Row>
            <div className={styles.MarginBottom} />
          </>
        )}
        <Row justify="space-around">
          <Col span={24}>
            <Button
              onClick={() => downloadCodes(item.codes, item.expirationDate)}
              type="default"
              text={texts.downloadCodes}
              icon={<SaveOutlined />}
            />
          </Col>
        </Row>
      </>
    );
  };

  const renderValidityTags = (item: PromotionCode): JSX.Element => {
    const isExpired = item.expirationDate
      ? new Date(item.expirationDate) < new Date()
      : true;

    return (
      <Row justify="center">
        <Col span={24}>
          <Tag color={isExpired ? 'red' : 'green'}>
            {isExpired ? 'Expirado' : 'Válido'}
          </Tag>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <CreationModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        refetchPromotionCodes={refetchFunction}
      />
      <Row>
        <Col>
          <h2 className={styles.Title}>{texts.title}</h2>
        </Col>
      </Row>
      <Row className={styles.Row}>
        <Col>
          <Button
            onClick={() => setIsModalVisible(true)}
            text={texts.createButton}
            type="primary"
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            dataSource={dataToTable(data?.listPromotionCodes)}
            loading={loading}
            columns={columns(
              statusColumnRender,
              forWeekendColumnRender,
              actionButtons,
              renderValidityTags
            )}
          />
        </Col>
      </Row>
    </>
  );
};

export default PromotionCodeConfiguration;
