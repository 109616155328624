import { gql, useMutation } from '@apollo/client';
import {
  ConfigurationParameter,
  MutationUpdateConfigurationParameterArgs,
} from 'apollo/types';

const UPDATE_CONFIGURATION_PARAMETER = gql`
  mutation updateConfigurationParameter($input: UpdateConfigurationParameterInput!) {
    updateConfigurationParameter(input: $input) {
      text
      value
      name
    }
  }
`;

const useMutationUpdateConfigurationParameter = () => {
  return useMutation<
    { updateConfigurationParameter: ConfigurationParameter },
    MutationUpdateConfigurationParameterArgs
  >(UPDATE_CONFIGURATION_PARAMETER, {});
};

export default useMutationUpdateConfigurationParameter;
