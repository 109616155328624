import { NewsPost, NewsPostImage, NewsPostImageType } from 'apollo/types';
import { useEffect, useState } from 'react';
import { Form as AntdForm } from 'antd';
import useUploadNewsPostImageMutation from 'apollo/resolvers/newsPostImage/upload';
import { DefaultOptionType } from 'antd/lib/select';
import useListNewsPosts from 'apollo/resolvers/newsPost/list';

type NewsPostImageWithStatus = NewsPostImage & {
  status?: string;
  url: string | undefined;
};

const useFormViewModel = (initialValues: NewsPost | undefined) => {
  const maxClosingImages = 2;
  const maxRecommendationsSelected = 3;
  const maxRecommendationsOptions = 200;

  const [uploadImage, { data: uploadImageData, loading: uploadImageLoading }] =
    useUploadNewsPostImageMutation();
  const [useLazyListNewsPostsQuery, { data: listNewsPostsData }] = useListNewsPosts();
  const [form] = AntdForm.useForm();
  const [coverImage, setCoverImage] = useState<NewsPostImageWithStatus[]>(
    initialValues?.coverImage ? [initialValues?.coverImage] : []
  );
  const [mainImage, setMainImage] = useState<NewsPostImageWithStatus[]>(
    initialValues?.mainImage ? [initialValues?.mainImage] : []
  );
  const [closingImages, setClosingImages] = useState<NewsPostImageWithStatus[]>(
    initialValues?.closingImages || []
  );
  const [recommendationIds, setRecommendationIds] = useState<string[]>([]);
  const [recommendationOptions, setRecommendationOptions] =
    useState<DefaultOptionType[]>();
  const [temporalUrl, setTemporalUrl] = useState<string>();

  const onCoverImageUpload = async (input: { contentType: string }) => {
    const response = await uploadImage({
      variables: {
        input: {
          contentType: input.contentType,
          newsPostImageType: NewsPostImageType.CoverImage,
        },
      },
    });
    const image = response.data?.uploadNewsPostImage as NewsPostImage;
    setCoverImage([{ ...image, url: '', status: 'uploading' }]);
    setTemporalUrl(image.url);
    form.setFieldsValue({ coverImage: { id: image.id } });
  };

  const deleteCoverImage = () => {
    setCoverImage([]);
    return new Promise<void>(() => {
      form.setFieldsValue({ coverImage: undefined });
    });
  };

  const refetchCoverImage = async () => {
    const [image] = coverImage;
    setCoverImage([{ ...image, url: temporalUrl as string, status: 'done' }]);
    await Promise.resolve();
  };

  const onMainImageUpload = async (input: { contentType: string }) => {
    const response = await uploadImage({
      variables: {
        input: {
          contentType: input.contentType,
          newsPostImageType: NewsPostImageType.MainImage,
        },
      },
    });
    const image = response.data?.uploadNewsPostImage as NewsPostImage;
    setMainImage([{ ...image, url: '', status: 'uploading' }]);
    setTemporalUrl(image.url);
    form.setFieldsValue({ mainImage: { id: image.id } });
  };

  const deleteMainImage = () => {
    setMainImage([]);
    return new Promise<void>(() => {
      form.setFieldsValue({ mainImage: undefined });
    });
  };

  const refetchMainImage = async () => {
    const [image] = mainImage;
    setMainImage([{ ...image, url: temporalUrl as string, status: 'done' }]);
    await Promise.resolve();
  };

  const onClosingImageUpload = async (input: { contentType: string }) => {
    const response = await uploadImage({
      variables: {
        input: {
          contentType: input.contentType,
          newsPostImageType: NewsPostImageType.ClosingImage,
        },
      },
    });
    const image = response.data?.uploadNewsPostImage as NewsPostImage;
    setClosingImages([...closingImages, { ...image, url: '', status: 'uploading' }]);
    setTemporalUrl(image.url);
    const formClosingImageIds = form.getFieldValue('closingImagesIds') as string[];
    form.setFieldsValue({
      closingImagesIds: [...formClosingImageIds, image.id],
    });
  };

  const deleteClosingImage = (args: { id: bigint }) => {
    const newClosingImages = closingImages.filter(
      (image) => BigInt(image.id) !== args.id
    );
    setClosingImages(newClosingImages);
    return new Promise<void>(() => {
      form.setFieldsValue({
        closingImagesIds: newClosingImages.map((image) => image.id),
      });
    });
  };

  const refetchClosingImage = async () => {
    const firstClosingImages = closingImages.slice(0, -1);
    const lastClosingImage = closingImages.slice(-1)[0];
    setClosingImages([
      ...firstClosingImages,
      { ...lastClosingImage, url: temporalUrl as string, status: 'done' },
    ]);
    await Promise.resolve();
  };

  const onChangeRecommendationIds = (value: string[]) => {
    setRecommendationIds(value);
  };

  const mapCurrentRecommendations = (newsPost: NewsPost | undefined): string[] => {
    if (!newsPost || !newsPost.newsPostRecommendations) return [];

    const recommendations = newsPost.newsPostRecommendations.map(
      (recommendation) => recommendation.id
    );

    return recommendations;
  };

  const initializeRecommendationOptions = async () => {
    await useLazyListNewsPostsQuery({
      variables: {
        input: {
          page: 0,
          pageSize: maxRecommendationsOptions,
        },
      },
    });
  };

  const setRecommendationOptionsValues = () => {
    let recommendationNewsPosts = listNewsPostsData?.listNewsPosts.newsPosts;
    if (initialValues) {
      recommendationNewsPosts = recommendationNewsPosts?.filter(
        (newsPost) => newsPost.id !== initialValues.id
      );
    }

    const options = recommendationNewsPosts?.map((newsPost) => ({
      label: newsPost.title,
      value: newsPost.id,
    }));
    setRecommendationOptions(options);
    setRecommendationIds(
      initialValues?.newsPostRecommendations.map((recommendation) => recommendation.id) ||
        []
    );
  };

  const onCloseModalButtonClicked = (onCloseModal: () => void) => {
    onCloseModal();
    form.resetFields();
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    initializeRecommendationOptions();
  }, []);

  useEffect(() => {
    setRecommendationOptionsValues();
  }, [initialValues, listNewsPostsData]);

  return {
    mapCurrentRecommendations,
    uploadImageData,
    uploadImageLoading,
    onCoverImageUpload,
    deleteCoverImage,
    refetchCoverImage,
    coverImage,
    onMainImageUpload,
    deleteMainImage,
    refetchMainImage,
    mainImage,
    onClosingImageUpload,
    deleteClosingImage,
    refetchClosingImage,
    closingImages,
    maxClosingImages,
    recommendationIds,
    onChangeRecommendationIds,
    recommendationOptions,
    maxRecommendationsSelected,
    onCloseModalButtonClicked,
    form,
  };
};

export default useFormViewModel;
