import useListProgramsQuery from 'apollo/resolvers/program/list';
import useEditProgramMutation from 'apollo/resolvers/program/edit';
import useUploadProgramImageMutation from 'apollo/resolvers/programImage/upload';
import { EditProgramInput, Program, ProgramImage, ProgramImageType } from 'apollo/types';
import { useEffect, useState } from 'react';
import { EditFormValues } from 'components/sharedComponents/FormModal/types';

const useProgramConfigurationViewModel = () => {
  const {
    data: listProgramsData,
    loading: listProgramsLoading,
    refetch: refetchListProgramsQuery,
  } = useListProgramsQuery();
  const [editProgramMutation, { loading: editProgramLoading }] = useEditProgramMutation();
  const [uploadImage, { data: uploadImageData, loading: uploadImageLoading }] =
    useUploadProgramImageMutation();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [editedProgram, setEditedProgram] = useState<Program>();
  const [programImagesIds, setProgramImageIds] = useState<string[]>([]);

  const maxNumberOfSecondaryImages = 6;

  const refetchListPrograms = async () => {
    await refetchListProgramsQuery();
  };

  const editProgram = async (input: EditProgramInput) => {
    const response = await editProgramMutation({
      variables: {
        input,
      },
    });
    if (response.data?.editProgram) {
      await refetchListPrograms();
    }
  };

  const onValidForWeekendsChange = async (programId: string, status: boolean) => {
    await editProgram({
      id: programId,
      validForWeekends: status,
    });
  };

  const onStatusChange = async (programId: string, status: boolean) => {
    await editProgram({
      id: programId,
      status,
    });
  };

  const onEditButtonClick = (program: Program) => {
    setEditedProgram(program);
    setIsModalVisible(true);
  };

  const onFormFinish = async (formValues: EditFormValues) => {
    const values = formValues as EditProgramInput;
    const item = await editProgramMutation({
      variables: {
        input: {
          ...formValues,
          videoLink: values.videoLink || '',
          programImagesIds,
        },
      },
    });
    return item;
  };

  const uploadCoverImage = async (input: { contentType: string }) => {
    await uploadImage({
      variables: {
        input: {
          contentType: input.contentType,
          programImageType: ProgramImageType.Cover,
        },
      },
    });
  };

  const uploadSecondaryImage = async (input: { contentType: string }) => {
    await uploadImage({
      variables: {
        input: {
          contentType: input.contentType,
          programImageType: ProgramImageType.Secondary,
        },
      },
    });
  };

  const deleteProgramImage = (values: { id: bigint }): Promise<void> => {
    if (!editedProgram) {
      return Promise.resolve();
    }

    const programImageId = values.id.toString();
    return new Promise<void>((resolve) => {
      if (editedProgram.coverImage?.id === programImageId) {
        setEditedProgram({ ...editedProgram, coverImage: undefined });
      } else if (editedProgram.secondaryImages) {
        const secondaryImages = editedProgram.secondaryImages.filter(
          (image) => image.id !== programImageId
        );
        setEditedProgram({ ...editedProgram, secondaryImages });
      }
      const ids = programImagesIds.filter((id) => id !== programImageId);
      setProgramImageIds(ids);
      resolve();
    });
  };

  const setIdsForImages = (program: Program) => {
    const imageIds = [];
    if (program.coverImage) {
      imageIds.push(program.coverImage.id);
    }
    if (program.secondaryImages) {
      imageIds.push(...program.secondaryImages.map((image) => image.id));
    }
    setProgramImageIds(imageIds);
  };

  const onUploadImageChange = (program: Program, programImage: ProgramImage) => {
    setProgramImageIds((prev) => [...prev, programImage.id]);
    if (programImage.programImageType === ProgramImageType.Cover) {
      setEditedProgram({
        ...program,
        coverImage: programImage,
      });
    } else if (programImage.programImageType === ProgramImageType.Secondary) {
      setEditedProgram({
        ...program,
        secondaryImages: [...(program.secondaryImages || []), programImage],
      });
    }
  };

  const refetchImages = async () => {
    if (editedProgram && uploadImageData?.uploadProgramImage) {
      onUploadImageChange(editedProgram, uploadImageData.uploadProgramImage);
    }
    await Promise.resolve();
  };

  useEffect(() => {
    if (editedProgram) {
      setIdsForImages(editedProgram);
    }
  }, [editedProgram]);

  return {
    isModalVisible,
    setIsModalVisible,
    listProgramsData,
    listProgramsLoading,
    refetchListPrograms,
    editProgramLoading,
    onValidForWeekendsChange,
    onStatusChange,
    uploadCoverImage,
    uploadSecondaryImage,
    deleteProgramImage,
    uploadImageData,
    uploadImageLoading,
    editedProgram,
    onEditButtonClick,
    onFormFinish,
    maxNumberOfSecondaryImages,
    refetchImages,
  };
};

export default useProgramConfigurationViewModel;
