import React, { FC, useState } from 'react';
import Input from 'components/UI/Input';
import { Form, Row, Col } from 'antd';
import Button from 'components/UI/Button';
import * as texts from 'assets/texts/purchases';
import { Filter, FilterType } from 'apollo/types';
import rutFormat from 'utils/formats/rutFormat';
import { SearcherProps, Props } from './types/index';
import getInitialValues from './utils/getInitialValues';
import filterTypeMapper from './utils/filterTypeMapper';
import styles from './Searcher.module.scss';

const Searcher: FC<Props> = (props: Props) => {
  const { inputs, form, canEraseFilters, filterSetter, eraseFilters } = props;
  const [initialValues] = useState(getInitialValues(inputs));
  const { Item } = Form;
  const additionalColSpan = inputs.length > 4 ? 2 : 1;
  const minimumColSpan = 5;
  const extraCols = inputs.length > 4 ? 4 - (inputs.length % 4) - 1 : 0;

  const calculateColSpan = () => {
    const colSpan = Math.floor(24 / (inputs.length + additionalColSpan));
    if (colSpan < minimumColSpan) {
      return minimumColSpan;
    }
    return colSpan;
  };

  const buttonOnClick = (values: unknown) => {
    const typedValues = values as SearcherProps;
    const searcherFilters: Filter[] = [];
    Object.entries(typedValues).forEach((tuple) => {
      const [name, value] = tuple;
      if (value) {
        const filterType: FilterType = filterTypeMapper[name];
        if (name === 'ticketType') {
          const valueAsList = value as string[];
          searcherFilters.push({ filterType, filterList: valueAsList });
        } else {
          const valueAsString = value as string;
          searcherFilters.push({ filterType, filter: valueAsString });
        }
      }
    });
    filterSetter(searcherFilters);
  };

  const normalize = (variableName: string) => {
    return variableName === 'rut' ? rutFormat : undefined;
  };

  return (
    <Form
      form={form}
      onFinish={buttonOnClick}
      layout="horizontal"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      initialValues={initialValues}
    >
      <Row justify="space-between" align="bottom" className={styles.Row}>
        {inputs.map((input) => {
          return (
            <Col
              className={styles.Col}
              span={calculateColSpan()}
              key={input.variableName}
            >
              <Item
                name={input.variableName}
                label={input.name}
                normalize={normalize(input.variableName)}
              >
                <Input
                  className={styles.Input}
                  placeholder={input.placeholder || ''}
                  type={input.type}
                  variableName={input.variableName}
                  options={input.options}
                  formInstance={form}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  mode={input.mode}
                />
              </Item>
            </Col>
          );
        })}
        {Array.from(Array(extraCols).keys()).map((index) => (
          <Col className={styles.Col} span={calculateColSpan()} key={index} />
        ))}
        <Col span={calculateColSpan()} className={styles.Col}>
          <Row>
            <Col span={10}>
              <Item>
                <Button
                  type="primary"
                  shape="round"
                  htmlType="submit"
                  text={texts.buttonText}
                />
              </Item>
            </Col>
            {canEraseFilters && (
              <Col span={10}>
                <Button
                  type="primary"
                  text="Borrar filtros"
                  shape="round"
                  onClick={eraseFilters}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default Searcher;
