import { GiftCardWithRemainingAmount } from 'apollo/types';
import dateFormat from 'utils/formats/dateFormat';

const dataToTable = (data: GiftCardWithRemainingAmount[] | undefined) => {
  if (!data) return [];
  const mappedGiftCards = data?.map((giftCard, index) => {
    const { from, to, totalAmount, expirationDate, remainingAmount } = giftCard;
    const dateExpirationDate = new Date(expirationDate);
    return {
      key: index,
      from,
      to,
      totalAmount,
      remainingAmount,
      expirationDate: dateFormat(dateExpirationDate, true),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      purchaseId: giftCard.purchase?.id || '',
    };
  });
  return mappedGiftCards;
};

export default dataToTable;
