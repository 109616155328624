export const title = 'Configuración de experiencias';
export const addThingToDo = 'Agregar nueva experiencia';
export const thingToDo = 'Experiencias';
export const edit = 'Editar';
export const editTitle = 'Editar experiencia';
export const name = 'Nombre';
export const description = 'Descripción';
export const photos = 'Fotos';
export const actions = 'Acciones';
export const successDelete = 'Experiencia eliminada con éxito';
export const confirmDelete = '¿Está seguro que desea eliminar esta experiencia?';
export const yes = 'Sí';
export const no = 'No';
