import { Activity } from 'apollo/types';

const dataToTable = (data: Activity[] | undefined) => {
  if (!data) return [];
  const mappedEntrances = data?.map((activity, index) => {
    const { name, place, status } = activity;
    return {
      key: index,
      name,
      description: place ? place.mainDescription : '',
      placeImages: place ? place.placeImages : [],
      status,
      actions: activity,
    };
  });
  return mappedEntrances;
};

export default dataToTable;
