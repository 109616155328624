import { NewsPost } from 'apollo/types';
import dateFormat from 'utils/formats/dateFormat';

const dataToTable = (data: NewsPost[] | undefined) => {
  if (!data || !data.length) {
    return [];
  }

  const mappedNewsPost = data?.map((newsPost) => {
    const {
      id,
      title,
      publicationDate,
      viewCount,
      featured,
      status,
      coverImage,
      mainImage,
      closingImages,
      readTime,
    } = newsPost;

    const formattedPublicationDate = dateFormat(new Date(publicationDate), true);

    const newsPostImages = [];
    if (coverImage) {
      newsPostImages.push(coverImage);
    }
    if (mainImage) {
      newsPostImages.push(mainImage);
    }
    if (closingImages) {
      newsPostImages.push(...closingImages);
    }

    return {
      key: id,
      id,
      title,
      publicationDate: formattedPublicationDate,
      viewCount,
      featured,
      status,
      newsPostImages,
      readTime,
      actions: newsPost,
    };
  });

  return mappedNewsPost;
};

export default dataToTable;
