/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: any;
  AWSDateTime: any;
  AWSEmail: any;
  AWSIPAddress: any;
  AWSJSON: any;
  AWSPhone: any;
  AWSTime: any;
  AWSTimestamp: any;
  AWSURL: any;
};

export type Activity = {
  __typename?: 'Activity';
  activityImages: Array<ActivityImage>;
  activityTimes: Array<ActivityTime>;
  activityTypeId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  enDescription?: Maybe<Scalars['String']>;
  enName?: Maybe<Scalars['String']>;
  hasSingleTimetable: Scalars['Boolean'];
  id: Scalars['ID'];
  maxStock: Scalars['Int'];
  name: Scalars['String'];
  paxPerTicket: Scalars['Int'];
  place: Place;
  status: Scalars['Boolean'];
  value: Scalars['Int'];
};

export type ActivityImage = {
  __typename?: 'ActivityImage';
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
};

export type ActivityTicket = {
  __typename?: 'ActivityTicket';
  activityTimeId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['Int']>;
  validityDate?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type ActivityTime = {
  __typename?: 'ActivityTime';
  activity?: Maybe<Activity>;
  dailyAvailability?: Maybe<Scalars['Int']>;
  endHour: Scalars['String'];
  id: Scalars['ID'];
  isWinterTime?: Maybe<Scalars['Boolean']>;
  openActivityTimetablesPerDay?: Maybe<Array<Maybe<OpenActivityTimetablesPerDay>>>;
  parkAvailabilityId?: Maybe<Scalars['ID']>;
  remaining?: Maybe<Scalars['Int']>;
  startHour: Scalars['String'];
};

export type ActivityType = {
  __typename?: 'ActivityType';
  activities?: Maybe<Array<Maybe<Activity>>>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type AgeRange = {
  __typename?: 'AgeRange';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type BillingDocument = {
  __typename?: 'BillingDocument';
  documentNumber?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  type: BillingDocumentType;
};

export enum BillingDocumentType {
  Invoice = 'Invoice',
  Voucher = 'Voucher',
}

export type BillingSystemCity = {
  __typename?: 'BillingSystemCity';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type BillingSystemCommune = {
  __typename?: 'BillingSystemCommune';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type Client = {
  __typename?: 'Client';
  ageRange?: Maybe<AgeRange>;
  documentIsPassport?: Maybe<Scalars['Boolean']>;
  documentNumber: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  frequentVisitor?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
};

export type ClientReccurency = {
  __typename?: 'ClientReccurency';
  recurrentAnualClients: Scalars['Int'];
  totalAnualIndividualClients: Scalars['Int'];
  totalClients: Scalars['Int'];
};

export type Code = {
  __typename?: 'Code';
  code: Scalars['String'];
  id: Scalars['ID'];
  isUsed: Scalars['Boolean'];
  purchaseId?: Maybe<Scalars['Int']>;
};

export type ConfigurationParameter = {
  __typename?: 'ConfigurationParameter';
  name: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type ConfigurationParameters = {
  __typename?: 'ConfigurationParameters';
  cancellationPolicy: ConfigurationParameter;
  oversold: ConfigurationParameter;
  parkCapacity: ConfigurationParameter;
  risk: ConfigurationParameter;
  terms: ConfigurationParameter;
};

export type ContactUsOutput = {
  __typename?: 'ContactUsOutput';
  response: Scalars['Boolean'];
};

export type CorporateEventContactOutput = {
  __typename?: 'CorporateEventContactOutput';
  response: Scalars['Boolean'];
};

export type CreateActivityInput = {
  activityTimes: Array<CreateActivityTimeInput>;
  activityTypeId: Scalars['ID'];
  enName?: InputMaybe<Scalars['String']>;
  maxStock: Scalars['Int'];
  name: Scalars['String'];
  paxPerTicket: Scalars['Int'];
  placeId: Scalars['ID'];
  status: Scalars['Boolean'];
  value: Scalars['Int'];
};

export type CreateActivityTimeInput = {
  endHour: Scalars['String'];
  parkAvailabilityId: Scalars['ID'];
  startHour: Scalars['String'];
  status: Scalars['Boolean'];
};

export type CreateDisabledPurchaseHistoryInput = {
  purchaseId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type CreateEntranceTicketTypeInput = {
  adultValue: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  enDescription?: InputMaybe<Scalars['String']>;
  enName?: InputMaybe<Scalars['String']>;
  isBicycle?: InputMaybe<Scalars['Boolean']>;
  maxStock: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  otherValue: Scalars['Int'];
  status?: InputMaybe<Scalars['Boolean']>;
};

export type CreateGrillInput = {
  enName?: InputMaybe<Scalars['String']>;
  mainDescription: Scalars['String'];
  name: Scalars['String'];
  secondaryDescription: Scalars['String'];
  status?: InputMaybe<Scalars['Boolean']>;
};

export type CreateNewsBannerInput = {
  body: Scalars['String'];
  isVisible: Scalars['Boolean'];
  title: Scalars['String'];
};

export type CreateNewsPostContentInput = {
  content: Scalars['String'];
  order: Scalars['Int'];
};

export type CreateNewsPostInput = {
  author: Scalars['String'];
  closingImagesIds: Array<Scalars['Int']>;
  contents: Array<CreateNewsPostContentInput>;
  coverImageId: Scalars['Int'];
  mainImage: ImageWithCaption;
  publicationDate: Scalars['String'];
  recommendationIds: Array<Scalars['Int']>;
  title: Scalars['String'];
};

export type CreatePackInput = {
  activities: Array<Scalars['Int']>;
  adultValue: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  enDescription?: InputMaybe<Scalars['String']>;
  enName?: InputMaybe<Scalars['String']>;
  entranceTicketTypes: Array<Scalars['Int']>;
  name: Scalars['String'];
  otherValue: Scalars['Int'];
  promotionCodePacks?: InputMaybe<Array<PromotionCodePackInput>>;
  status?: InputMaybe<Scalars['Boolean']>;
  visibility?: InputMaybe<Scalars['Boolean']>;
};

export type CreatePlaceInput = {
  mainDescription: Scalars['String'];
  name: Scalars['String'];
  placeMainImage?: InputMaybe<Scalars['String']>;
  secondaryDescription: Scalars['String'];
  thingsToDo?: InputMaybe<Array<CreatePlaceThingToDoInput>>;
};

export type CreatePlaceThingToDoImages = {
  id: Scalars['ID'];
  image?: InputMaybe<Scalars['String']>;
};

export type CreatePlaceThingToDoInput = {
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  thingToDoImages?: InputMaybe<Array<CreatePlaceThingToDoImages>>;
};

export type CreatePromotionCodeInput = {
  amountOfCodes: Scalars['Int'];
  email: Scalars['String'];
  expirationDate: Scalars['String'];
  forWeekends: Scalars['Boolean'];
  promotionCodeActivities?: InputMaybe<Array<InputPromotionCodeActivity>>;
  promotionCodeEntrances?: InputMaybe<Array<InputPromotionCodeEntrance>>;
  promotionCodePacks?: InputMaybe<Array<InputPromotionCodePack>>;
  status: Scalars['Boolean'];
  suffix: Scalars['String'];
};

export type CreateThingToDoInput = {
  description: Scalars['String'];
  name: Scalars['String'];
  thingToDoImages?: InputMaybe<Array<ThingToDoImageInput>>;
};

export type CreateUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  role: Scalars['String'];
};

export enum CustomerType {
  Company = 'Company',
  Person = 'Person',
}

export type DeleteEntranceImageInput = {
  entranceTicketTypeId: Scalars['ID'];
  id: Scalars['ID'];
};

export type DeleteNewsPostInput = {
  id: Scalars['ID'];
};

export type DeletePackImageInput = {
  id: Scalars['ID'];
  packId: Scalars['ID'];
};

export type DeletePlaceImageInput = {
  id: Scalars['ID'];
  isMain?: InputMaybe<Scalars['Boolean']>;
  placeId: Scalars['ID'];
};

export type DeletePlaceInput = {
  id: Scalars['ID'];
};

export type DeleteThingToDoImageInput = {
  id: Scalars['ID'];
  thingsToDoId: Scalars['ID'];
};

export type DetailedReservation = {
  __typename?: 'DetailedReservation';
  activityTickets: Array<DetailedReservationActivityTicket>;
  client: Client;
  entranceTickets: Array<DetailedReservationEntranceTicket>;
  purchaseId: Scalars['ID'];
};

export type DetailedReservationActivityTicket = {
  __typename?: 'DetailedReservationActivityTicket';
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  startHour: Scalars['String'];
  state: Scalars['Int'];
  subTotal: Scalars['Int'];
  validityDate: Scalars['String'];
};

export type DetailedReservationEntranceTicket = {
  __typename?: 'DetailedReservationEntranceTicket';
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  state: Scalars['Int'];
  ticketTypeId: Scalars['ID'];
  ticketTypeName: Scalars['String'];
  value: Scalars['Int'];
  visitDate: Scalars['String'];
};

export type DisablePurchaseHistory = {
  __typename?: 'DisablePurchaseHistory';
  createdAt?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  userFirstName?: Maybe<Scalars['String']>;
  userLastName?: Maybe<Scalars['String']>;
};

export type DisablePurchasesBackofficeInput = {
  purchaseIds: Array<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type EditActivityInput = {
  activityTimes?: InputMaybe<Array<EditActivityTimeInput>>;
  activityTypeId?: InputMaybe<Scalars['ID']>;
  enName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  maxStock?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  paxPerTicket?: InputMaybe<Scalars['Int']>;
  placeId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Scalars['Int']>;
};

export type EditActivityTimeInput = {
  endHour?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  parkAvailabilityId?: InputMaybe<Scalars['ID']>;
  startHour?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Boolean']>;
};

export type EditEntranceTicketTypeInput = {
  adultValue?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  enDescription?: InputMaybe<Scalars['String']>;
  enName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isBicycle?: InputMaybe<Scalars['Boolean']>;
  maxStock?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  otherValue?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Boolean']>;
  visibility?: InputMaybe<Scalars['Boolean']>;
};

export type EditGrillInput = {
  id: Scalars['ID'];
  mainDescription?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  placeMainImage?: InputMaybe<Scalars['String']>;
  secondaryDescription?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Boolean']>;
};

export type EditGrillResponse = {
  __typename?: 'EditGrillResponse';
  billingSystemProductId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  mainDescription?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  place?: Maybe<Place>;
  placeMainImage?: Maybe<Scalars['String']>;
  secondaryDescription?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  value: Scalars['Int'];
};

export type EditManyGrillsInput = {
  maxStock?: InputMaybe<Scalars['String']>;
  paxPerTicket?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type EditManyGrillsResponse = {
  __typename?: 'EditManyGrillsResponse';
  count?: Maybe<Scalars['Int']>;
};

export type EditNewsPostInput = {
  author?: InputMaybe<Scalars['String']>;
  closingImagesIds?: InputMaybe<Array<Scalars['Int']>>;
  contents?: InputMaybe<Array<CreateNewsPostContentInput>>;
  coverImageId?: InputMaybe<Scalars['Int']>;
  featured?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  mainImage?: InputMaybe<ImageWithCaption>;
  publicationDate?: InputMaybe<Scalars['String']>;
  recommendationIds?: InputMaybe<Array<Scalars['Int']>>;
  status?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

export type EditPackInput = {
  activities?: InputMaybe<Array<Scalars['Int']>>;
  adultValue?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  enDescription?: InputMaybe<Scalars['String']>;
  enName?: InputMaybe<Scalars['String']>;
  entranceTicketTypes?: InputMaybe<Array<Scalars['Int']>>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  otherValue?: InputMaybe<Scalars['Int']>;
  promotionCodePacks?: InputMaybe<Array<PromotionCodePackInput>>;
  status?: InputMaybe<Scalars['Boolean']>;
  visibility?: InputMaybe<Scalars['Boolean']>;
};

export type EditPlaceInput = {
  id: Scalars['ID'];
  mainDescription?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  placeMainImage?: InputMaybe<Scalars['String']>;
  secondaryDescription?: InputMaybe<Scalars['String']>;
  thingsToDo?: InputMaybe<Array<CreatePlaceThingToDoInput>>;
};

export type EditProgramInput = {
  description?: InputMaybe<Scalars['String']>;
  hoursDuration?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  programImagesIds?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<Scalars['Boolean']>;
  subtitle?: InputMaybe<Scalars['String']>;
  targetGroup?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  validForWeekends?: InputMaybe<Scalars['Boolean']>;
  videoLink?: InputMaybe<Scalars['String']>;
};

export type EditPromotionCodeInput = {
  forWeekends?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  status?: InputMaybe<Scalars['Boolean']>;
};

export type EnrichedClient = {
  __typename?: 'EnrichedClient';
  activityTicketsCount?: Maybe<Scalars['Int']>;
  ageRange?: Maybe<AgeRange>;
  documentIsPassport?: Maybe<Scalars['Boolean']>;
  documentNumber: Scalars['String'];
  email: Scalars['String'];
  entranceTicketsCount?: Maybe<Scalars['Int']>;
  firstName: Scalars['String'];
  frequentVisitor?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  purchasesCount?: Maybe<Scalars['Int']>;
};

export type EnrichedPurchase = {
  __typename?: 'EnrichedPurchase';
  activityTickets?: Maybe<Array<EnrichedPurchaseActivityTickets>>;
  activityTimes?: Maybe<Array<EnrichedPurchaseActivityTimes>>;
  createdAt?: Maybe<Scalars['String']>;
  entranceTickets?: Maybe<Array<EnrichedPurchaseEntranceTickets>>;
  giftCard?: Maybe<GiftCard>;
  giftCardPurchase?: Maybe<GiftCardPurchase>;
  grillTickets?: Maybe<Array<EnrichedPurchaseActivityTickets>>;
  id: Scalars['ID'];
  isDisabled?: Maybe<Scalars['Boolean']>;
  packPurchases?: Maybe<Array<EnrichedPurchasePackPurchases>>;
  preDiscountPrice?: Maybe<Scalars['Int']>;
  visitDate?: Maybe<Scalars['String']>;
};

export type EnrichedPurchaseActivityTickets = {
  __typename?: 'EnrichedPurchaseActivityTickets';
  finalPrice: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  percentageDiscount?: Maybe<Scalars['Int']>;
  price: Scalars['Int'];
  productId: Scalars['ID'];
  quantity?: Maybe<Scalars['Int']>;
};

export type EnrichedPurchaseActivityTimes = {
  __typename?: 'EnrichedPurchaseActivityTimes';
  activityTime: ActivityTime;
  id: Scalars['ID'];
  name: Scalars['String'];
  quantity: Scalars['Int'];
};

export type EnrichedPurchaseBackOffice = {
  __typename?: 'EnrichedPurchaseBackOffice';
  billingDocument?: Maybe<BillingDocument>;
  client?: Maybe<Client>;
  disabledPurchaseHistory?: Maybe<DisablePurchaseHistory>;
  isDisabled?: Maybe<Scalars['Boolean']>;
  preDiscountPrice?: Maybe<Scalars['Int']>;
  purchase?: Maybe<EnrichedPurchase>;
  purchaseQrScans: Array<Maybe<Scalars['String']>>;
  totalPrice?: Maybe<Scalars['Int']>;
};

export type EnrichedPurchaseEntranceTickets = {
  __typename?: 'EnrichedPurchaseEntranceTickets';
  finalPrice: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  percentageDiscount?: Maybe<Scalars['Int']>;
  price: Scalars['Int'];
  productId: Scalars['ID'];
  quantity?: Maybe<Scalars['Int']>;
};

export type EnrichedPurchaseMailer = {
  __typename?: 'EnrichedPurchaseMailer';
  client?: Maybe<Client>;
  disabledPurchaseHistory?: Maybe<DisablePurchaseHistory>;
  purchase?: Maybe<EnrichedPurchase>;
  purchaseQrScans: Array<Maybe<Scalars['String']>>;
  qrCodeImage?: Maybe<Scalars['String']>;
  totalPrice?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type EnrichedPurchasePackPurchases = {
  __typename?: 'EnrichedPurchasePackPurchases';
  finalPrice: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  percentageDiscount?: Maybe<Scalars['Int']>;
  price: Scalars['Int'];
  productId: Scalars['ID'];
  quantity?: Maybe<Scalars['Int']>;
};

export type EntranceImage = {
  __typename?: 'EntranceImage';
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  uploadUrl?: Maybe<Scalars['String']>;
};

export type EntranceSaleReport = {
  __typename?: 'EntranceSaleReport';
  entrancesByNameMonth: Array<GroupedByNameMonth>;
  packsEntrancesByNameMonth: Array<PacksGroupedByNameMonth>;
};

export type EntranceTicket = {
  __typename?: 'EntranceTicket';
  endPeriodTime: Scalars['String'];
  entranceTicketTypeName?: Maybe<Scalars['String']>;
  entranceTicketTypesId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isValid?: Maybe<Scalars['Boolean']>;
  purchaseId?: Maybe<Scalars['ID']>;
  qrCode?: Maybe<Scalars['String']>;
  startPeriodTime: Scalars['String'];
  state?: Maybe<Scalars['Int']>;
  typePersonId?: Maybe<Scalars['ID']>;
  value?: Maybe<Scalars['Int']>;
};

export type EntranceTicketType = {
  __typename?: 'EntranceTicketType';
  adultValue: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  enDescription?: Maybe<Scalars['String']>;
  enName?: Maybe<Scalars['String']>;
  entranceImages: Array<EntranceImage>;
  entranceTickets?: Maybe<Array<Maybe<EntranceTicket>>>;
  id: Scalars['ID'];
  isBicycle?: Maybe<Scalars['Boolean']>;
  maxStock: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  otherValue: Scalars['Int'];
  remaining: Scalars['Int'];
  status?: Maybe<Scalars['Boolean']>;
  visibility?: Maybe<Scalars['Boolean']>;
};

export type EntranceTicketTypeBackOffice = {
  __typename?: 'EntranceTicketTypeBackOffice';
  adultValue: Scalars['Int'];
  billingSystemProductId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  enDescription?: Maybe<Scalars['String']>;
  enName?: Maybe<Scalars['String']>;
  entranceImages: Array<EntranceImage>;
  id: Scalars['ID'];
  isBicycle?: Maybe<Scalars['Boolean']>;
  maxStock?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  otherValue: Scalars['Int'];
  status?: Maybe<Scalars['Boolean']>;
  visibility?: Maybe<Scalars['Boolean']>;
};

export type Experience = {
  __typename?: 'Experience';
  activityImages?: Maybe<Array<Maybe<ActivityImage>>>;
  description?: Maybe<Scalars['String']>;
  enDescription?: Maybe<Scalars['String']>;
  enName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
};

export type Filter = {
  filter?: InputMaybe<Scalars['String']>;
  filterList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filterType: FilterType;
};

export enum FilterType {
  BillingSystemDocumentNumber = 'billingSystemDocumentNumber',
  BillingSystemDocumentType = 'billingSystemDocumentType',
  Date = 'date',
  Disabled = 'disabled',
  DocumentNumber = 'documentNumber',
  Email = 'email',
  EndDate = 'endDate',
  FullName = 'fullName',
  Phone = 'phone',
  PurchaseId = 'purchaseId',
  Rut = 'rut',
  StartDate = 'startDate',
  TicketType = 'ticketType',
}

export type GetNewsPostInput = {
  id: Scalars['ID'];
};

export type GiftCard = {
  __typename?: 'GiftCard';
  code: Scalars['String'];
  from: Scalars['String'];
  id: Scalars['ID'];
  password: Scalars['String'];
  purchase?: Maybe<Purchase>;
  to: Scalars['String'];
  totalAmount: Scalars['Int'];
};

export type GiftCardPurchase = {
  __typename?: 'GiftCardPurchase';
  amountUsed: Scalars['Int'];
  giftCardId: Scalars['ID'];
  id: Scalars['ID'];
};

export type GiftCardWithRemainingAmount = {
  __typename?: 'GiftCardWithRemainingAmount';
  expirationDate: Scalars['String'];
  from: Scalars['String'];
  id: Scalars['ID'];
  purchase?: Maybe<Purchase>;
  remainingAmount: Scalars['Int'];
  to: Scalars['String'];
  totalAmount: Scalars['Int'];
};

export type Grill = {
  __typename?: 'Grill';
  clientEmail?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  clientPhone?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type Grills = {
  __typename?: 'Grills';
  count: Scalars['Int'];
  reservedGrills: Array<Grill>;
  stock: Scalars['Int'];
};

export type GroupByDay = {
  __typename?: 'GroupByDay';
  weekEndEntrances: Scalars['Int'];
  workDayEntrances: Scalars['Int'];
};

export type GroupedByNameMonth = {
  __typename?: 'GroupedByNameMonth';
  entranceTicketTypeId: Scalars['ID'];
  entrancesByMonth: Array<Scalars['Int']>;
  name: Scalars['String'];
};

export type ImageWithCaption = {
  caption?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

export type InputCode = {
  code: Scalars['String'];
  isUsed: Scalars['Boolean'];
};

export type InputPromotionCodeActivity = {
  activityId: Scalars['ID'];
  edited: Scalars['Boolean'];
  maxQuantity: Scalars['Int'];
  percentageDiscount: Scalars['Float'];
};

export type InputPromotionCodeEntrance = {
  edited: Scalars['Boolean'];
  entranceTicketTypeId: Scalars['ID'];
  maxQuantity: Scalars['Int'];
  percentageDiscount: Scalars['Float'];
};

export type InputPromotionCodePack = {
  edited: Scalars['Boolean'];
  maxQuantity: Scalars['Int'];
  packsId: Scalars['ID'];
  percentageDiscount: Scalars['Float'];
};

export type ListClientsBackofficeInput = {
  documentNumber?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type ListEntranceImageInput = {
  entranceTicketTypeId: Scalars['ID'];
};

export type ListGiftCardsInput = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListGiftCardsResponse = {
  __typename?: 'ListGiftCardsResponse';
  count: Scalars['Int'];
  giftCards: Array<GiftCardWithRemainingAmount>;
};

export type ListNewsPostsInput = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type ListNewsPostsResponse = {
  __typename?: 'ListNewsPostsResponse';
  count: Scalars['Int'];
  newsPosts: Array<NewsPost>;
};

export type ListPurchasesBackofficeResponse = {
  __typename?: 'ListPurchasesBackofficeResponse';
  count: Scalars['Int'];
  purchases: Array<TicketPurchase>;
};

export type MappedPurchase = {
  __typename?: 'MappedPurchase';
  date: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['ID'];
  includePacks: Scalars['Boolean'];
  purchaseActivities: Array<MappedPurchaseActivity>;
  purchaseGrills: Array<MappedPurchaseActivity>;
  purchaseItems: Array<MappedPurchaseItem>;
  uuid: Scalars['ID'];
};

export type MappedPurchaseActivity = {
  __typename?: 'MappedPurchaseActivity';
  activityTime: ActivityTime;
  id: Scalars['ID'];
  name: Scalars['String'];
  ticketCount: Scalars['Int'];
};

export type MappedPurchaseItem = {
  __typename?: 'MappedPurchaseItem';
  adultTicketCount: Scalars['Int'];
  entranceTicketTypeId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  otherTicketCount: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']>;
  createActivity?: Maybe<Activity>;
  createDisabledPurchaseHistory: DisabledPurchaseHistory;
  createEntranceTicketType: EntranceTicketType;
  createGrill?: Maybe<Activity>;
  createNewsBanner: NewsBanner;
  createNewsPost: NewsPost;
  createPack: Pack;
  createPlace?: Maybe<Place>;
  createPromotionCode?: Maybe<PromotionCode>;
  createThingToDo: ThingToDo;
  createUser?: Maybe<User>;
  deleteEntranceImage: EntranceImage;
  deleteNewsPost: NewsPost;
  deletePackImage: PackImage;
  deletePlaceImage: PlaceImage;
  deleteThingsToDoImages: ThingToDoImage;
  disablePromotionCode?: Maybe<PromotionCode>;
  disablePurchasesBackoffice?: Maybe<Success>;
  editActivity?: Maybe<Activity>;
  editEntranceTicketType: EntranceTicketType;
  editGrill?: Maybe<EditGrillResponse>;
  editManyGrills?: Maybe<EditManyGrillsResponse>;
  editNewsPost: NewsPost;
  editPack: Pack;
  editPlace?: Maybe<Place>;
  editProgram: Program;
  editPromotionCode?: Maybe<PromotionCode>;
  sendClientsEmailsBackoffice: Success;
  updateConfigurationParameter: ConfigurationParameter;
  updateNewsBanner: NewsBanner;
  updateOpenDays: OpenDay;
  updateThingToDo: ThingToDo;
  uploadEntranceImage: EntranceImage;
  uploadNewsPostImage: NewsPostImage;
  uploadPackImage: PackImage;
  uploadPlaceImage: PlaceImage;
  uploadProgramImage: ProgramImage;
  uploadThingsToDoImages: ThingToDoImage;
  validatePurchase?: Maybe<Success>;
};

export type MutationCreateActivityArgs = {
  input: CreateActivityInput;
};

export type MutationCreateDisabledPurchaseHistoryArgs = {
  input: CreateDisabledPurchaseHistoryInput;
};

export type MutationCreateEntranceTicketTypeArgs = {
  input: CreateEntranceTicketTypeInput;
};

export type MutationCreateGrillArgs = {
  input: CreateGrillInput;
};

export type MutationCreateNewsBannerArgs = {
  input?: InputMaybe<CreateNewsBannerInput>;
};

export type MutationCreateNewsPostArgs = {
  input: CreateNewsPostInput;
};

export type MutationCreatePackArgs = {
  input: CreatePackInput;
};

export type MutationCreatePlaceArgs = {
  input: CreatePlaceInput;
};

export type MutationCreatePromotionCodeArgs = {
  input: CreatePromotionCodeInput;
};

export type MutationCreateThingToDoArgs = {
  input: CreateThingToDoInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationDeleteEntranceImageArgs = {
  input: DeleteEntranceImageInput;
};

export type MutationDeleteNewsPostArgs = {
  input: DeleteNewsPostInput;
};

export type MutationDeletePackImageArgs = {
  input: DeletePackImageInput;
};

export type MutationDeletePlaceImageArgs = {
  input: DeletePlaceImageInput;
};

export type MutationDeleteThingsToDoImagesArgs = {
  input: DeleteThingToDoImageInput;
};

export type MutationDisablePromotionCodeArgs = {
  input: EditPromotionCodeInput;
};

export type MutationDisablePurchasesBackofficeArgs = {
  input: DisablePurchasesBackofficeInput;
};

export type MutationEditActivityArgs = {
  input: EditActivityInput;
};

export type MutationEditEntranceTicketTypeArgs = {
  input: EditEntranceTicketTypeInput;
};

export type MutationEditGrillArgs = {
  input: EditGrillInput;
};

export type MutationEditManyGrillsArgs = {
  input: EditManyGrillsInput;
};

export type MutationEditNewsPostArgs = {
  input: EditNewsPostInput;
};

export type MutationEditPackArgs = {
  input: EditPackInput;
};

export type MutationEditPlaceArgs = {
  input: EditPlaceInput;
};

export type MutationEditProgramArgs = {
  input: EditProgramInput;
};

export type MutationEditPromotionCodeArgs = {
  input: EditPromotionCodeInput;
};

export type MutationSendClientsEmailsBackofficeArgs = {
  input: SendClientsEmailsInput;
};

export type MutationUpdateConfigurationParameterArgs = {
  input: UpdateConfigurationParameterInput;
};

export type MutationUpdateNewsBannerArgs = {
  input?: InputMaybe<UpdateNewsBannerInput>;
};

export type MutationUpdateOpenDaysArgs = {
  input: UpdateOpenDaysInput;
};

export type MutationUpdateThingToDoArgs = {
  input: UpdateThingToDoInput;
};

export type MutationUploadEntranceImageArgs = {
  input: UploadEntranceImageInput;
};

export type MutationUploadNewsPostImageArgs = {
  input: UploadNewsPostImageInput;
};

export type MutationUploadPackImageArgs = {
  input: UploadPackImageInput;
};

export type MutationUploadPlaceImageArgs = {
  input: UploadPlaceImageInput;
};

export type MutationUploadProgramImageArgs = {
  input: UploadProgramImageInput;
};

export type MutationUploadThingsToDoImagesArgs = {
  input: UploadThingToDoImageInput;
};

export type MutationValidatePurchaseArgs = {
  input: ValidatePurchaseInput;
};

export type NewsBanner = {
  __typename?: 'NewsBanner';
  body: Scalars['String'];
  isVisible: Scalars['Boolean'];
  title: Scalars['String'];
};

export type NewsPost = {
  __typename?: 'NewsPost';
  author?: Maybe<Scalars['String']>;
  closingImages?: Maybe<Array<NewsPostImage>>;
  contents?: Maybe<Array<NewsPostContent>>;
  coverImage?: Maybe<NewsPostImage>;
  featured: Scalars['Boolean'];
  id: Scalars['ID'];
  mainImage?: Maybe<NewsPostImage>;
  newsPostRecommendations: Array<NewsPostRecommendation>;
  publicationDate: Scalars['String'];
  readTime: Scalars['Float'];
  status: Scalars['Boolean'];
  title: Scalars['String'];
  viewCount: Scalars['Int'];
};

export type NewsPostContent = {
  __typename?: 'NewsPostContent';
  content: Scalars['String'];
  id: Scalars['ID'];
  order: Scalars['Int'];
};

export type NewsPostImage = {
  __typename?: 'NewsPostImage';
  caption?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  type: NewsPostImageType;
  uploadUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export enum NewsPostImageType {
  ClosingImage = 'ClosingImage',
  CoverImage = 'CoverImage',
  MainImage = 'MainImage',
}

export type NewsPostRecommendation = {
  __typename?: 'NewsPostRecommendation';
  coverImage: NewsPostImage;
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type Newsletter = {
  __typename?: 'Newsletter';
  email: Scalars['String'];
  id: Scalars['ID'];
  status: Scalars['Boolean'];
};

export type Occupation = {
  __typename?: 'Occupation';
  maxCapacity: Scalars['Int'];
  occupationByMonth: Array<Scalars['Int']>;
  occupationPercentageByMonth: Array<Scalars['Float']>;
  total: Scalars['Int'];
};

export type OpenActivityTimetablesPerDay = {
  __typename?: 'OpenActivityTimetablesPerDay';
  id: Scalars['ID'];
};

export type OpenDay = {
  __typename?: 'OpenDay';
  day: Scalars['String'];
  id: Scalars['ID'];
};

export type Pack = {
  __typename?: 'Pack';
  activities: Array<Activity>;
  adultValue: Scalars['Int'];
  billingSystemProductId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  enDescription?: Maybe<Scalars['String']>;
  enName?: Maybe<Scalars['String']>;
  entranceTicketTypes: Array<EntranceTicketType>;
  id: Scalars['ID'];
  name: Scalars['String'];
  otherValue: Scalars['Int'];
  packImages: Array<PackImage>;
  remaining: Scalars['Int'];
  status?: Maybe<Scalars['Boolean']>;
  visibility?: Maybe<Scalars['Boolean']>;
};

export type PackImage = {
  __typename?: 'PackImage';
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  uploadUrl?: Maybe<Scalars['String']>;
};

export type PacksGroupedByNameMonth = {
  __typename?: 'PacksGroupedByNameMonth';
  entrancesByMonthAndName: Array<GroupedByNameMonth>;
  name: Scalars['String'];
};

export type ParkAvailability = {
  __typename?: 'ParkAvailability';
  aperture: Scalars['String'];
  closure: Scalars['String'];
  endDate: Scalars['String'];
  id: Scalars['ID'];
  isWinterTime: Scalars['Boolean'];
  startDate: Scalars['String'];
};

export type ParkSalesByGroupDay = {
  __typename?: 'ParkSalesByGroupDay';
  entrancesByMonth: Array<GroupByDay>;
  packsEntrancesByMonth: Array<GroupByDay>;
};

export type Place = {
  __typename?: 'Place';
  activities?: Maybe<Activity>;
  id: Scalars['ID'];
  mainDescription: Scalars['String'];
  name: Scalars['String'];
  placeImages?: Maybe<Array<PlaceImage>>;
  placeMainImage?: Maybe<Scalars['String']>;
  secondaryDescription: Scalars['String'];
  thingsToDo?: Maybe<Array<Maybe<ThingToDo>>>;
};

export type PlaceImage = {
  __typename?: 'PlaceImage';
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  uploadUrl?: Maybe<Scalars['String']>;
};

export type Program = {
  __typename?: 'Program';
  coverImage?: Maybe<ProgramImage>;
  description: Scalars['String'];
  hoursDuration: Scalars['Int'];
  id: Scalars['ID'];
  secondaryImages?: Maybe<Array<ProgramImage>>;
  status: Scalars['Boolean'];
  subtitle: Scalars['String'];
  targetGroup: Scalars['String'];
  title: Scalars['String'];
  validForWeekends: Scalars['Boolean'];
  videoLink?: Maybe<Scalars['String']>;
};

export type ProgramImage = {
  __typename?: 'ProgramImage';
  id: Scalars['ID'];
  programImageType: ProgramImageType;
  uploadUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export enum ProgramImageType {
  Cover = 'Cover',
  Secondary = 'Secondary',
}

export type PromotionCode = {
  __typename?: 'PromotionCode';
  codes: Array<Code>;
  createdAt?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['String']>;
  forWeekends?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  promotionCodeActivities: Array<PromotionCodeActivity>;
  promotionCodeEntrances: Array<PromotionCodeEntrance>;
  promotionCodePacks: Array<PromotionCodePack>;
  status: Scalars['Boolean'];
  suffix?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type PromotionCodeActivity = {
  __typename?: 'PromotionCodeActivity';
  activityId: Scalars['ID'];
  id: Scalars['ID'];
  maxQuantity: Scalars['Int'];
  percentageDiscount: Scalars['Float'];
};

export type PromotionCodeEntrance = {
  __typename?: 'PromotionCodeEntrance';
  entranceTicketTypeId: Scalars['ID'];
  id: Scalars['ID'];
  maxQuantity: Scalars['Int'];
  percentageDiscount: Scalars['Float'];
};

export type PromotionCodePack = {
  __typename?: 'PromotionCodePack';
  id: Scalars['ID'];
  maxQuantity: Scalars['Int'];
  packsId: Scalars['ID'];
  percentageDiscount: Scalars['Float'];
};

export type PromotionCodePackInput = {
  maxQuantity: Scalars['Int'];
  percentageDiscount: Scalars['Int'];
  promotionCodeId: Scalars['ID'];
};

export type PromotionOptions = {
  __typename?: 'PromotionOptions';
  activityTypes?: Maybe<Array<ActivityType>>;
  entranceTicketTypes?: Maybe<Array<EntranceTicketType>>;
  packs?: Maybe<Array<Pack>>;
};

export type Purchase = {
  __typename?: 'Purchase';
  client?: Maybe<Client>;
  clientID?: Maybe<Scalars['ID']>;
  code?: Maybe<Code>;
  createdAt?: Maybe<Scalars['String']>;
  giftCard?: Maybe<GiftCard>;
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  paymentMethod?: Maybe<Scalars['Int']>;
  qrCode?: Maybe<Scalars['String']>;
  state?: Maybe<PurchaseState>;
};

export enum PurchaseState {
  Confirmed = 'Confirmed',
  Pending = 'Pending',
  Refunded = 'Refunded',
  Rejected = 'Rejected',
}

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']>;
  getConfigurationParameters: ConfigurationParameters;
  getDisabledPurchaseHistory?: Maybe<DisabledPurchaseHistory>;
  getEnrichedPurchase?: Maybe<EnrichedPurchaseBackOffice>;
  getNewsBanner?: Maybe<NewsBanner>;
  getNewsPost: NewsPost;
  getProgram: Program;
  getReportClientReccurency: ReportClientRecurrency;
  getReportEntranceTickets: ReportEntranceTickets;
  getReportGrills: ReportGrill;
  getReportPark: ReportPark;
  getReportParkSalesByGroupDay: ReportSalesByGroupDay;
  getTotalTicketSummary?: Maybe<TodaysTicketData>;
  listActivities?: Maybe<Array<Maybe<Activity>>>;
  listActivitiesBackOffice?: Maybe<Array<Maybe<Activity>>>;
  listClientsBackoffice: ListClientsBackofficeResponse;
  listClientsMailerBackoffice?: Maybe<Array<Client>>;
  listEntranceTicketTypesBackOffice?: Maybe<Array<Maybe<EntranceTicketTypeBackOffice>>>;
  listGiftCards: ListGiftCardsResponse;
  listNewsPosts: ListNewsPostsResponse;
  listOpenDays: Array<Maybe<OpenDay>>;
  listPacks?: Maybe<Array<Pack>>;
  listParkAvailability?: Maybe<Array<Maybe<ParkAvailability>>>;
  listPlacesBackOffice: Array<Place>;
  listPrograms: Array<Program>;
  listPromotionCodes?: Maybe<Array<Maybe<PromotionCode>>>;
  listPromotionOptions?: Maybe<PromotionOptions>;
  listPurchases?: Maybe<ListPurchasesBackofficeResponse>;
  listThingsToDo: Array<ThingToDo>;
  listTicketsGrouped?: Maybe<TicketsCount>;
};

export type QueryGetDisabledPurchaseHistoryArgs = {
  id: Scalars['ID'];
};

export type QueryGetEnrichedPurchaseArgs = {
  id: Scalars['ID'];
  isInvalid?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetNewsPostArgs = {
  input: GetNewsPostInput;
};

export type QueryGetProgramArgs = {
  id: Scalars['ID'];
};

export type QueryGetReportClientReccurencyArgs = {
  month?: InputMaybe<Scalars['Int']>;
  year: Scalars['String'];
};

export type QueryGetReportEntranceTicketsArgs = {
  month?: InputMaybe<Scalars['Int']>;
  year: Scalars['String'];
};

export type QueryGetReportGrillsArgs = {
  month?: InputMaybe<Scalars['Int']>;
  year: Scalars['String'];
};

export type QueryGetReportParkArgs = {
  month?: InputMaybe<Scalars['Int']>;
  year: Scalars['String'];
};

export type QueryGetReportParkSalesByGroupDayArgs = {
  month?: InputMaybe<Scalars['Int']>;
  year: Scalars['String'];
};

export type QueryListActivitiesArgs = {
  activityTypeId?: InputMaybe<Scalars['ID']>;
  date: Scalars['String'];
};

export type QueryListActivitiesBackOfficeArgs = {
  activityTypeId?: InputMaybe<Scalars['ID']>;
};

export type QueryListClientsBackofficeArgs = {
  input: ListClientsBackofficeInput;
};

export type QueryListClientsMailerBackofficeArgs = {
  endDate: Scalars['String'];
  listEntranceTickets?: InputMaybe<Array<Scalars['String']>>;
  startDate: Scalars['String'];
};

export type QueryListGiftCardsArgs = {
  input: ListGiftCardsInput;
};

export type QueryListNewsPostsArgs = {
  input: ListNewsPostsInput;
};

export type QueryListOpenDaysArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type QueryListPacksArgs = {
  date?: InputMaybe<Scalars['String']>;
};

export type QueryListPurchasesArgs = {
  filters?: InputMaybe<Array<Filter>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  userId: Scalars['ID'];
  validReservations: Scalars['Boolean'];
};

export type QueryListTicketsGroupedArgs = {
  date: Scalars['String'];
};

export type ReportClientRecurrency = {
  __typename?: 'ReportClientRecurrency';
  clientReccurePurchaseCounter: Array<Scalars['Int']>;
  clientReccurency: ClientReccurency;
};

export type ReportEntranceTickets = {
  __typename?: 'ReportEntranceTickets';
  entranceSaleReport: EntranceSaleReport;
};

export type ReportGrill = {
  __typename?: 'ReportGrill';
  grillOccupation: Occupation;
};

export type ReportPark = {
  __typename?: 'ReportPark';
  parkOccupation: Occupation;
};

export type ReportSalesByGroupDay = {
  __typename?: 'ReportSalesByGroupDay';
  parkSalesByGroupDay: ParkSalesByGroupDay;
};

export type SendClientsEmailsInput = {
  body: Scalars['String'];
  clientsEmails: Array<Scalars['String']>;
  subject: Scalars['String'];
};

export type Success = {
  __typename?: 'Success';
  success: Scalars['Boolean'];
};

export type SummarizedReservation = {
  __typename?: 'SummarizedReservation';
  activityTicketsSummarized: Array<SummarizedReservationActivityTicket>;
  client: Client;
  entranceTicketsSummarized: Array<SummarizedReservationEntranceTicket>;
  purchaseId: Scalars['ID'];
};

export type SummarizedReservationActivityTicket = {
  __typename?: 'SummarizedReservationActivityTicket';
  name: Scalars['String'];
  startHour: Scalars['String'];
  ticketCount: Scalars['Int'];
  total: Scalars['Int'];
  validityDate: Scalars['String'];
};

export type SummarizedReservationEntranceTicket = {
  __typename?: 'SummarizedReservationEntranceTicket';
  state: Scalars['Boolean'];
  ticketCount: Scalars['Int'];
  ticketTypeName: Scalars['String'];
  totalValue: Scalars['Int'];
  visitDate: Scalars['String'];
};

export type ThingToDoImage = {
  __typename?: 'ThingToDoImage';
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  uploadUrl?: Maybe<Scalars['String']>;
};

export type ThingToDoImageInput = {
  id: Scalars['ID'];
  image: Scalars['String'];
};

export type TicketPurchase = {
  __typename?: 'TicketPurchase';
  disabledPurchaseHistory?: Maybe<DisablePurchaseHistory>;
  documentNumber: Scalars['String'];
  isDisabled: Scalars['Boolean'];
  purchaseId: Scalars['ID'];
  ticketType: Scalars['String'];
  visitDate: Scalars['String'];
};

export type TotalPrice = {
  __typename?: 'TotalPrice';
  totalPrice: Scalars['Int'];
};

export type UpdateConfigurationParameterInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  text?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Int']>;
};

export type UpdateNewsBannerInput = {
  body?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isVisible?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateOpenDaysInput = {
  activityTimesIds: Array<Scalars['Int']>;
  day: Scalars['String'];
};

export type UpdateThingToDoInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  thingToDoImages?: InputMaybe<Array<ThingToDoImageInput>>;
};

export type UploadEntranceImageInput = {
  contentType: Scalars['String'];
  entranceTicketTypeId: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
};

export type UploadNewsPostImageInput = {
  caption?: InputMaybe<Scalars['String']>;
  contentType: Scalars['String'];
  newsPostImageType: NewsPostImageType;
};

export type UploadPackImageInput = {
  contentType: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  packId: Scalars['ID'];
};

export type UploadPlaceImageInput = {
  contentType: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  isMain?: InputMaybe<Scalars['Boolean']>;
  placeId: Scalars['ID'];
};

export type UploadProgramImageInput = {
  contentType: Scalars['String'];
  programImageType: ProgramImageType;
};

export type UploadThingToDoImageInput = {
  contentType: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  thingsToDoId: Scalars['ID'];
};

export type UpsertClientInput = {
  documentIsPassport: Scalars['Boolean'];
  documentNumber: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  role: Scalars['String'];
};

export enum UserRole {
  Admin = 'Admin',
  Gatekeeper = 'Gatekeeper',
}

export type ValidatePurchaseInput = {
  purchaseId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type WebPayCreateGiftCardPurchaseResponse = {
  __typename?: 'WebPayCreateGiftCardPurchaseResponse';
  createGiftCardPurchase?: Maybe<WebpayTransactionResponse>;
};

export type WebPayCreatePurchaseResponse = {
  __typename?: 'WebPayCreatePurchaseResponse';
  createPurchase?: Maybe<WebpayTransactionResponse>;
};

export type WebpayTransactionResponse = {
  __typename?: 'WebpayTransactionResponse';
  token?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type ActivitiesReservations = {
  __typename?: 'activitiesReservations';
  aviaryReservation: ActivityReservations;
  grillsReservation: ActivityReservations;
  nurseryGardenReservation: ActivityReservations;
};

export type ActivityReservations = {
  __typename?: 'activityReservations';
  availableTimes: Scalars['Int'];
  count: Scalars['Int'];
  stock: Scalars['Int'];
};

export type DisabledPurchaseHistory = {
  __typename?: 'disabledPurchaseHistory';
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  purchaseId: Scalars['ID'];
  userEmail: Scalars['String'];
  userFirstName: Scalars['String'];
  userLastName: Scalars['String'];
};

export type EntranceTicketsGrouped = {
  __typename?: 'entranceTicketsGrouped';
  adultCount: Scalars['Int'];
  count: Scalars['Int'];
  entranceTicketTypeName?: Maybe<Scalars['String']>;
  kidsOrElderlyCount: Scalars['Int'];
  stock: Scalars['Int'];
  visitedAdultCount: Scalars['Int'];
  visitedCount: Scalars['Int'];
  visitedKidsOrElderlyCount: Scalars['Int'];
};

export type ExperienceTickets = {
  __typename?: 'experienceTickets';
  availableTimes: Scalars['Int'];
  name: Scalars['String'];
  stockPerTime: Scalars['Int'];
  times: Array<ExperienceTime>;
  totalCount: Scalars['Int'];
};

export type ExperienceTime = {
  __typename?: 'experienceTime';
  count?: Maybe<Scalars['Int']>;
  startEndHour?: Maybe<Scalars['String']>;
};

export type IsStillChangeable = {
  __typename?: 'isStillChangeable';
  isStillChangeable: Scalars['Boolean'];
};

export type ListClientsBackofficeResponse = {
  __typename?: 'listClientsBackofficeResponse';
  clients: Array<EnrichedClient>;
  count: Scalars['Int'];
};

export type ThingToDo = {
  __typename?: 'thingToDo';
  description: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  thingToDoImages?: Maybe<Array<ThingToDoImage>>;
};

export type TicketsCount = {
  __typename?: 'ticketsCount';
  entrances: Array<EntranceTicketsGrouped>;
  experiences: Array<ExperienceTickets>;
  grills?: Maybe<Grills>;
};

export type TodaysTicketData = {
  __typename?: 'todaysTicketData';
  activitiesReservations: ActivitiesReservations;
  adultCount: Scalars['Int'];
  count: Scalars['Int'];
  kidsOrElderlyCount: Scalars['Int'];
  stock: Scalars['Int'];
  visitedAdultCount: Scalars['Int'];
  visitedCount: Scalars['Int'];
  visitedKidsOrElderlyCount: Scalars['Int'];
};
