export const title = 'Configuración de Códigos Promocionales';
export const createButton = 'Agregar nuevo código promocional';

export const creationDate = 'Fecha de creación';
export const state = 'Estado';
export const downloadCodes = 'Descargar';
export const disableCodes = 'Deshabilitar';
export const copy = 'Copiar';
export const copiedCodeToClipboard = 'Código copiado al portapapeles';
export const saveButton = 'Guardar';
export const deleteButton = 'Eliminar';
export const cancelButton = 'Cancelar';
export const editTitle = 'Editar código promocional';

export const tooltip =
  'Cada lugar puede tener solo una actividad, si no hay ninguno disponible se debe crear un nuevo lugar antes de crear la actividad.';
