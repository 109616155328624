import { useMutation } from '@apollo/client';
import { Activity, MutationCreateGrillArgs } from 'apollo/types';
import { gql } from 'graphql.macro';

const CREATE_GRILL = gql`
  mutation createGrill($input: CreateGrillInput!) {
    createGrill(input: $input) {
      id
      value
      status
      paxPerTicket
      name
      maxStock
      enName
      enDescription
      description
      activityTypeId
      activityImages {
        image
      }
      place {
        placeMainImage
        placeImages {
          id
          image
          uploadUrl
        }
      }
    }
  }
`;

const useCreateGrillMutation = () => {
  return useMutation<{ createGrill: Activity }, MutationCreateGrillArgs>(
    CREATE_GRILL,
    {}
  );
};

export default useCreateGrillMutation;
