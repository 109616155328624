const dateFormat = (date: Date | null | undefined, isDateMonthYear: boolean): string => {
  if (date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    if (day < 10 && month < 10) {
      return isDateMonthYear ? `0${day}-0${month}-${year}` : `${year}-0${month}-0${day}`;
    }
    if (day < 10) {
      return isDateMonthYear ? `0${day}-${month}-${year}` : `${year}-${month}-0${day}`;
    }
    if (month < 10) {
      return isDateMonthYear ? `${day}-0${month}-${year}` : `${year}-0${month}-${day}`;
    }
    return isDateMonthYear ? `${day}-${month}-${year}` : `${year}-${month}-${day}`;
  }
  return '';
};
export default dateFormat;
