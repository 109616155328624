import { gql, useMutation } from '@apollo/client';
import { Activity, MutationCreateActivityArgs } from 'apollo/generated/types';

const CREATE_ACTIVITY = gql`
  mutation createActivity($input: CreateActivityInput!) {
    createActivity(input: $input) {
      activityTypeId
      description
      enDescription
      enName
      id
      maxStock
      name
      status
      paxPerTicket
      value
      activityImages {
        image
        id
      }
      activityTimes {
        id
      }
    }
  }
`;

const useCreateActivityMutation = () => {
  return useMutation<{ createActivity: Activity }, MutationCreateActivityArgs>(
    CREATE_ACTIVITY,
    {}
  );
};
export default useCreateActivityMutation;
