import { useMutation } from '@apollo/client';
import { MutationEditManyGrillsArgs } from 'apollo/generated/types';
import { gql } from 'graphql.macro';

const EDIT_MANY_GRILLS = gql`
  mutation editManyGrills($input: EditManyGrillsInput!) {
    editManyGrills(input: $input) {
      count
    }
  }
`;

const useEditManyGrillsMutation = () => {
  return useMutation<
    { editGrillsGenericProps: { count: number } },
    MutationEditManyGrillsArgs
  >(EDIT_MANY_GRILLS, {});
};

export default useEditManyGrillsMutation;
