import { EnrichedPurchaseActivityTimes } from 'apollo/types';
import Table from 'components/UI/Table';
import React, { FC } from 'react';
import columns from './utils/columns';
import styles from '../OrderDetail.module.scss';
import dataToTable from './utils/dataToTable';

export interface ActivityTimesTableProps {
  purchaseId: string;
  activityTimesTickets: EnrichedPurchaseActivityTimes[] | undefined;
}

const ActivityTimesTable: FC<ActivityTimesTableProps> = ({
  purchaseId,
  activityTimesTickets,
}) => {
  const isTicketInfoAvailable = activityTimesTickets && purchaseId !== undefined;

  return (
    <Table
      title={() => 'Horario actividades'}
      columns={columns}
      size="large"
      pagination={false}
      className={!isTicketInfoAvailable ? styles.Disabled : ''}
      dataSource={dataToTable(activityTimesTickets || [])}
    />
  );
};

export default ActivityTimesTable;
