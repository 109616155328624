/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useState, useEffect } from 'react';
import {
  Row,
  Col,
  Switch,
  Form,
  InputNumber,
  Card as AntdCard,
  Skeleton,
  message,
} from 'antd';
import { Table, ImagePreviewGroup, Button, UploadImages } from 'components/UI';
import FormModal from 'components/sharedComponents/FormModal';
import * as texts from 'assets/texts/entranceConfiguration';
import useGetParkCapacityQuery from 'apollo/resolvers/configurationParameters/getParkCapacity';
import useListEntranceTicketTypesQuery from 'apollo/resolvers/entranceTicketTypes/list';
import useEditEntranceTicketTypeMutation from 'apollo/resolvers/entranceTicketTypes/edit';
import useCreateEntranceTicketTypeMutation from 'apollo/resolvers/entranceTicketTypes/create';
import useUploadEntranceImageMutation from 'apollo/resolvers/entranceImage/upload';
import useDeleteEntranceImageMutation from 'apollo/resolvers/entranceImage/delete';
import {
  EntranceTicketType,
  EntranceImage,
  CreateEntranceTicketTypeInput,
  EditEntranceTicketTypeInput,
} from 'apollo/types';
import {
  CreateFormValues,
  EditFormValues,
  Mode,
} from 'components/sharedComponents/FormModal/types';
import { TagOutlined } from '@ant-design/icons';
import useMutationUpdateConfigurationParameter from 'apollo/resolvers/configurationParameters/update';
import statusOnChange from './utils/statusOnChange';
import setEditOrCreateForm from './utils/setEditOrCreateForm';
import { EntranceTicketTypeColumn } from './types';
import formToInput from './utils/formToInput';
import dataToTable from './utils/dataToTable';
import columns from './utils/createColumns';
import entranceFormFields from './utils/formFields';
import styles from './EntranceConfiguration.module.scss';
import visibilityOnChange from './utils/visibilityOnChange';

const EntranceConfiguration: FC = () => {
  const [form] = Form.useForm();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<EntranceTicketType>();
  const [modalMode, setModalMode] = useState<Mode | undefined>();

  const { data, loading, refetch } = useListEntranceTicketTypesQuery();
  const {
    data: parkCapacity,
    loading: loadingParkCapacity,
    refetch: refetchParkCapacity,
  } = useGetParkCapacityQuery();
  const [uploadImage, { data: uploadImageData, loading: uploadImageLoading }] =
    useUploadEntranceImageMutation();
  const [deleteImage, { loading: deleteImageLoading }] = useDeleteEntranceImageMutation();
  const [editEntranceTicketTypeMutation] = useEditEntranceTicketTypeMutation();
  const [createEntranceTicketTypeMutation] = useCreateEntranceTicketTypeMutation();
  const [updateParkCapacityMutation] = useMutationUpdateConfigurationParameter();

  const statusColumnRender = (value: boolean, record: unknown): JSX.Element => {
    const entranceTicketType = record as EntranceTicketTypeColumn;
    return (
      <Switch
        defaultChecked={value}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={() =>
          statusOnChange(entranceTicketType, editEntranceTicketTypeMutation, refetch)
        }
      />
    );
  };

  const editButton = (item: EntranceTicketType): JSX.Element => (
    <Button
      type="primary"
      onClick={() =>
        setEditOrCreateForm(item, setEditItem, setModalMode, setIsModalVisible)
      }
      text={texts.editButton}
    />
  );

  const createImageGroup = (entranceTicketImages: EntranceImage[]): JSX.Element => {
    const images = entranceTicketImages.map((image) => image.image || undefined);
    return <ImagePreviewGroup images={images} maxImagesToShow={2} widthImages={50} />;
  };

  const imageGroup = (item: EntranceImage[] | undefined): JSX.Element => {
    if (item && item.length > 0) {
      return createImageGroup(item);
    }
    return <></>;
  };

  const visibilityColumnRender = (value: boolean, record: unknown): JSX.Element => {
    const entranceTicketType = record as EntranceTicketTypeColumn;
    return (
      <Switch
        defaultChecked={value}
        disabled={entranceTicketType.status || false}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={() =>
          visibilityOnChange(entranceTicketType, editEntranceTicketTypeMutation, refetch)
        }
      />
    );
  };

  const refetchFunction = async () => {
    await refetch();
  };

  const editMutation = async (formValues: EditFormValues) => {
    const entranceFormValues = formValues as EditEntranceTicketTypeInput;
    const item = await editEntranceTicketTypeMutation({
      variables: { input: entranceFormValues },
    });

    return item;
  };

  const createMutation = async (formValues: CreateFormValues) => {
    const entranceFormValues = formValues as CreateEntranceTicketTypeInput;
    const item = await createEntranceTicketTypeMutation({
      variables: { input: entranceFormValues },
    });

    return item;
  };

  const uploadEntranceImage = async (values: {
    id: bigint | undefined;
    parentEntityId: bigint;
    contentType: string;
  }) => {
    await uploadImage({
      variables: {
        input: {
          id: values.id?.toString(),
          entranceTicketTypeId: values.parentEntityId.toString(),
          contentType: values.contentType,
        },
      },
    });
  };

  const deleteEntranceImage = async (values: { id: bigint; parentEntityId: bigint }) => {
    await deleteImage({
      variables: {
        input: {
          id: values.id.toString(),
          entranceTicketTypeId: values.parentEntityId.toString(),
        },
      },
    });
  };

  const updateParkCapacity = async () => {
    const values = form.getFieldsValue() as { parkCapacity: number };
    try {
      await updateParkCapacityMutation({
        variables: {
          input: { id: '1', name: 'park_capacity', value: values.parkCapacity },
        },
      });
      await refetchParkCapacity();
      await message.success(texts.parkCapacityUpdated);
    } catch {
      await message.error(texts.parkCapacityError);
    }
  };

  useEffect(() => {
    if (data?.listEntranceTicketTypesBackOffice) {
      if (editItem) {
        const foundItem = data.listEntranceTicketTypesBackOffice.find(
          (item) => item.id === editItem.id
        );
        if (foundItem) setEditItem(foundItem as unknown as EntranceTicketType);
      }
    }
  }, [data?.listEntranceTicketTypesBackOffice]);

  const uploadElement = (
    <UploadImages
      images={editItem?.entranceImages || []}
      parentEntityId={BigInt(editItem?.id || 0)}
      uploadedImageId={
        uploadImageData?.uploadEntranceImage.id
          ? BigInt(uploadImageData?.uploadEntranceImage.id)
          : undefined
      }
      uploadUrl={uploadImageData?.uploadEntranceImage.uploadUrl || undefined}
      loading={uploadImageLoading || deleteImageLoading}
      uploadCallback={uploadEntranceImage}
      deleteCallback={deleteEntranceImage}
      refetchImagesCallback={refetchFunction}
    />
  );

  return (
    <>
      <FormModal
        formToInput={formToInput}
        isModalVisible={isModalVisible}
        editOrCreateItem={editItem}
        setIsModalVisible={setIsModalVisible}
        formFields={entranceFormFields(modalMode === 'create', editItem, uploadElement)}
        title={modalMode === 'create' ? texts.createButton : texts.editTitle}
        mode={modalMode}
        refetch={refetchFunction}
        editFormValues={editMutation}
        createFormValues={createMutation}
      />
      <Row>
        <Col>
          <h2 className={styles.Title}>{texts.title}</h2>
        </Col>
      </Row>
      <Row className={styles.Row} justify="start">
        <Col xs={24} sm={12}>
          <AntdCard>
            <Skeleton loading={loadingParkCapacity} active>
              <>
                <p>{texts.dailyCapacity}</p>
                <Form form={form}>
                  <Row
                    className={styles.CapacityContainer}
                    justify="start"
                    align="middle"
                  >
                    <Col span={16}>
                      <Form.Item
                        name="parkCapacity"
                        initialValue={
                          parkCapacity?.getConfigurationParameters.parkCapacity.value
                        }
                        className={styles.FormItem}
                      >
                        <InputNumber
                          className={styles.Input}
                          controls={false}
                          prefix={<TagOutlined className={styles.SmallIcon} />}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="center">
                    <Col span={24}>
                      <Form.Item>
                        <Button
                          type="primary"
                          text="Editar capacidad del parque"
                          onClick={updateParkCapacity}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
                <div className={styles.CountContainer} />
              </>
            </Skeleton>
          </AntdCard>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2 className={styles.Title}>{texts.subtitle}</h2>
        </Col>
      </Row>
      <Row className={styles.Row}>
        <Col>
          <Button
            type="primary"
            onClick={() =>
              setEditOrCreateForm(undefined, setEditItem, setModalMode, setIsModalVisible)
            }
            text={texts.createButton}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            columns={columns(
              statusColumnRender,
              editButton,
              imageGroup,
              visibilityColumnRender
            )}
            dataSource={dataToTable(data?.listEntranceTicketTypesBackOffice)}
            loading={loading}
            pagination={false}
            rowKey="name"
          />
        </Col>
      </Row>
    </>
  );
};

export default EntranceConfiguration;
