import { gql, useMutation } from '@apollo/client';
import { MutationDeleteNewsPostArgs, NewsPost } from 'apollo/generated/types';

const DELETE_NEWS_POST = gql`
  mutation deleteNewsPost($input: DeleteNewsPostInput!) {
    deleteNewsPost(input: $input) {
      id
    }
  }
`;

const useDeleteNewsPostMutation = () => {
  return useMutation<{ deleteNewsPost: NewsPost }, MutationDeleteNewsPostArgs>(
    DELETE_NEWS_POST,
    {}
  );
};
export default useDeleteNewsPostMutation;
