import { gql, useQuery } from '@apollo/client';
import { PromotionOptions } from 'apollo/types';

const LIST_PROMOTION_OPTIONS = gql`
  query listPromotionOptions {
    listPromotionOptions {
      entranceTicketTypes {
        name
        id
      }
      activityTypes {
        name
        id
      }
      packs {
        name
        id
      }
    }
  }
`;

const useQueryListPromotionOptions = () => {
  return useQuery<{ listPromotionOptions: PromotionOptions }>(LIST_PROMOTION_OPTIONS, {});
};

export default useQueryListPromotionOptions;
