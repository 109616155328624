import { gql, useQuery } from '@apollo/client';
import { ConfigurationParameters } from 'apollo/generated/types';

const GET_PARK_CAPACITY = gql`
  query getParkCapacity {
    getConfigurationParameters {
      parkCapacity {
        name
        value
      }
    }
  }
`;

const useGetParkCapacityQuery = () => {
  return useQuery<{ getConfigurationParameters: ConfigurationParameters }>(
    GET_PARK_CAPACITY,
    {}
  );
};
export default useGetParkCapacityQuery;
