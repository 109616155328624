import { gql, useQuery } from '@apollo/client';
import { ThingToDo } from 'apollo/types';

const LIST_THINGS_TO_DO = gql`
  query listThingsToDo {
    listThingsToDo {
      id
      name
      description
      thingToDoImages {
        id
        image
        uploadUrl
      }
    }
  }
`;

const useListThingsToDoQuery = () => {
  return useQuery<{ listThingsToDo: ThingToDo[] }>(LIST_THINGS_TO_DO, {});
};

export default useListThingsToDoQuery;
