export const title = 'Configuración de quinchos';
export const save = 'Guardar';
export const value = 'Valor';
export const maxStock = 'Stock';
export const paxPerTicket = 'Capacidad';
export const createButton = 'Crear nuevo quincho';
export const name = 'Quincho';
export const description = 'Descripción';
export const photos = 'Fotos';
export const state = 'Estado';
export const actions = 'Acciones';
export const editButton = 'Editar';
export const editTitle = 'Editar quincho';
export const grillName = 'Nombre del quincho';
export const price = 'Precio';
export const uploadImages = 'Agregar imágenes';
