export const title = 'Configuración de espacios';
export const addPlace = 'Agregar nuevo espacio';
export const place = 'espacio';
export const description = 'Descripción';
export const secondaryDescription = 'Descripción secundaria';
export const maxStock = 'Capacidad máxima';
export const placeMainImage = 'Imagen principal del espacio';
export const photos = 'Fotos';
export const state = 'Estado';
export const actions = 'Acciones';
export const edit = 'Editar';
export const editTitle = 'Editar espacio';
export const placeName = 'Nombre del espacio';
export const thingsToDo = 'Experiencias';
