import { EnrichedPurchasePackPurchases } from 'apollo/generated/types';

export interface MappedReservationPack {
  key: number;
  ticketType: string;
  quantity: number;
  percentageDiscount: number;
  finalPrice: number;
  visitDate: string;
}

const dataToTable = (data: EnrichedPurchasePackPurchases[], visitDate: string) => {
  const mappedData = data
    .map((summarizedPack, index) => {
      const mappedSummarizedPack: MappedReservationPack = {
        key: index,
        ticketType: summarizedPack.name || '',
        quantity: summarizedPack.quantity || 0,
        percentageDiscount: summarizedPack.percentageDiscount || 0,
        finalPrice: summarizedPack.finalPrice || 0,
        visitDate,
      };

      return Array(1).fill(mappedSummarizedPack) as MappedReservationPack[];
    })
    .flat();

  return mappedData;
};

export default dataToTable;
