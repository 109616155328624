import { gql, useLazyQuery } from '@apollo/client';
import {
  ListPurchasesBackofficeResponse,
  Filter,
  QueryListPurchasesArgs,
} from 'apollo/generated/types';

const LIST_PURCHASES = gql`
  query listPurchases(
    $userId: ID!
    $filters: [Filter!]
    $validReservations: Boolean!
    $page: Int
    $pageSize: Int
  ) {
    listPurchases(
      userId: $userId
      filters: $filters
      validReservations: $validReservations
      page: $page
      pageSize: $pageSize
    ) {
      count
      purchases {
        documentNumber
        purchaseId
        ticketType
        visitDate
        disabledPurchaseHistory {
          createdAt
          userFirstName
          userLastName
          userEmail
        }
      }
    }
  }
`;

const useListPurchasesQuery = (
  userId: string,
  filters: Filter[],
  validReservations: boolean,
  page: number,
  pageSize: number
) => {
  return useLazyQuery<
    { listPurchases: ListPurchasesBackofficeResponse },
    QueryListPurchasesArgs
  >(LIST_PURCHASES, {
    variables: { userId, filters, validReservations, page, pageSize },
    fetchPolicy: 'no-cache',
  });
};
export default useListPurchasesQuery;
