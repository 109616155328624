/* eslint-disable import/prefer-default-export */
import { CreateNewsPostInput, EditNewsPostInput } from 'apollo/types';
import { FormContent, CreateNewsPostFormValues } from '../types';

const mapContent = (content: FormContent): CreateNewsPostInput['contents'] => {
  const mappedContent: CreateNewsPostInput['contents'] = [];

  Object.keys(content).forEach((key) => {
    mappedContent.push({
      content: content[key],
      order: Number(key),
    });
  });

  return mappedContent;
};

export const mapCreateFormValues = (
  values: CreateNewsPostFormValues
): CreateNewsPostInput => {
  const {
    title,
    publicationDate,
    content,
    coverImage,
    mainImage,
    recommendationIds,
    closingImagesIds,
    author,
  } = values;

  const mappedValues: CreateNewsPostInput = {
    author,
    closingImagesIds: closingImagesIds?.map((imageId) => Number(imageId)) || [],
    contents: mapContent(content),
    coverImageId: Number(coverImage.id),
    mainImage: { id: Number(mainImage.id), caption: mainImage.caption },
    publicationDate: publicationDate.toISOString(),
    recommendationIds: recommendationIds.map((id) => Number(id)),
    title,
  };

  return mappedValues;
};

export const mapEditFormValues = (
  values: CreateNewsPostFormValues,
  id: number
): EditNewsPostInput => {
  const {
    title,
    publicationDate,
    content,
    coverImage,
    mainImage,
    closingImagesIds,
    recommendationIds,
    author,
  } = values;

  const mappedValues: EditNewsPostInput = {
    id,
    contents: mapContent(content),
    coverImageId: Number(coverImage.id),
    mainImage: { id: Number(mainImage.id), caption: mainImage.caption },
    closingImagesIds: closingImagesIds?.map((imageId) => Number(imageId)) || [],
    publicationDate: publicationDate.toISOString(),
    recommendationIds: recommendationIds.map((recommendationId) =>
      Number(recommendationId)
    ),
    title,
    author,
  };

  return mappedValues;
};
