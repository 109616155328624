// data: any comes from library's definition and unused vars are for nullish default
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CognitoUser } from 'amazon-cognito-identity-js';
import { getStoredUserAuth } from 'auth/storage';
import userPool from 'auth/userPool';

export const forgotPasswordInit = (
  username?: string,
  onSuccessFunction?: (data: any) => void,
  onLoadingFunction?: () => void,
  onErrorFunction?: (error: Error) => void
) => {
  if (onLoadingFunction) onLoadingFunction();
  const userData = {
    Username: username || getStoredUserAuth().username,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  cognitoUser.forgotPassword({
    onSuccess: onSuccessFunction || ((data: any) => {}),
    onFailure: onErrorFunction || ((error: Error) => {}),
  });
};

export const forgotPasswordComplete = (
  code: string,
  newPassword: string,
  username?: string,
  onSuccessFunction?: (data: any) => void,
  onErrorFunction?: (error: Error) => void
) => {
  const userData = {
    Username: username || getStoredUserAuth().username,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  cognitoUser.confirmPassword(code, newPassword, {
    onSuccess: onSuccessFunction || ((data: any) => {}),
    onFailure: onErrorFunction || ((error: Error) => {}),
  });
};
