import { ActivityTime, ParkAvailability } from 'apollo/types';
import { ActivityTimeWithStatus } from './types';

const filterAndMapActivityTimes = (
  aviaryActivityTimes: ActivityTime[] | undefined,
  parkAvailability: ParkAvailability | undefined
): ActivityTimeWithStatus[] => {
  return (
    aviaryActivityTimes
      ?.filter(
        (aviaryActivityTime) =>
          aviaryActivityTime.parkAvailabilityId === parkAvailability?.id
      )
      .map((aviaryActivityTime) => ({
        ...aviaryActivityTime,
        selected: true,
        key: aviaryActivityTime.id,
      })) || []
  );
};

export default filterAndMapActivityTimes;
