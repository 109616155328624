import { ThingToDo } from 'apollo/types';

const dataToTable = (data: ThingToDo[] | undefined) => {
  if (!data) return [];
  const mappedThingsToDo = data?.map((thingToDo, index) => {
    const { id, description, name, thingToDoImages } = thingToDo;
    return {
      key: index,
      id,
      name,
      description,
      thingToDoImages,
      actions: thingToDo,
    };
  });
  return mappedThingsToDo;
};

export default dataToTable;
