import React, { createElement } from 'react';
import { Route, Redirect } from 'react-router-dom';
import NavigatedLayout from 'components/Layout/NavigatedLayout';
import GeneralLayout from 'components/Layout/GeneralLayout';
import { Route as RouteType } from './routes';
import { checkIfTokenIsValid, checkIfUserCanAccess } from '../auth/storage';

const AuthRoute = (props: RouteType) => {
  const {
    component: Component,
    private: privateRoute,
    path,
    group,
    navigatedView,
  } = props;
  const userIsValid = checkIfTokenIsValid();
  // const routeGroupMatch = checkTokenGroups(group);
  const canUserAccess = checkIfUserCanAccess(group);
  if (userIsValid && path === '/log-in') {
    return <Redirect to="/" />;
  }
  if (privateRoute && !userIsValid) {
    return (
      <Redirect
        to={{
          pathname: '/log-in',
          // eslint-disable-next-line no-restricted-globals
          state: { from: location.pathname },
        }}
      />
    );
  }
  if (userIsValid && !canUserAccess) {
    return <Redirect to="/" />;
  }

  const Layout = navigatedView ? NavigatedLayout : GeneralLayout;
  return (
    <Layout>
      <Route path={path} render={() => createElement(Component)} />
    </Layout>
  );
};

export default AuthRoute;
