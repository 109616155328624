import { gql, useMutation } from '@apollo/client';
import { EditGrillResponse, MutationEditGrillArgs } from 'apollo/types';

const EDIT_GRILL = gql`
  mutation editGrill($input: EditGrillInput!) {
    editGrill(input: $input) {
      id
      status
      secondaryDescription
      placeMainImage
      name
      mainDescription
    }
  }
`;

const useEditGrillMutation = () => {
  return useMutation<{ editActivityAndPlace: EditGrillResponse }, MutationEditGrillArgs>(
    EDIT_GRILL
  );
};

export default useEditGrillMutation;
