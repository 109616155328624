import { GroupedActivityTicketsRow, GroupedEntranceTicketsRow } from '../types';

export const getColumns = (
  row: GroupedEntranceTicketsRow | GroupedActivityTicketsRow
) => {
  const entranceColumns = [
    {
      title: 'Tickets Adulto',
      dataIndex: 'adultTickets',
      key: 'adultTickets',
    },
    {
      title: 'Tickets adulto mayor/niños',
      dataIndex: 'otherTickets',
      key: 'otherTickets',
    },
    {
      title: 'Visitas Registradas',
      dataIndex: 'registeredVisits',
      key: 'registeredVisits',
    },
  ];
  const experienceColumns = [
    {
      title: 'Cantidad de tickets',
      dataIndex: 'ticketAmount',
      key: 'ticketAmount',
    },
    {
      title: 'Maximo de tickets por horario',
      dataIndex: 'maxTicketsPerTime',
      key: 'maxTicketsPerTime',
    },
    {
      title: 'Horarios',
      dataIndex: 'activityTime',
      key: 'activityTime',
    },
  ];

  const grillColumns = [
    {
      title: 'Quinchos vendidos',
      dataIndex: 'soldGrill',
      key: 'soldGrill',
    },
  ];

  switch (row.type) {
    case 'entrance':
      return entranceColumns;
    case 'experience':
      return experienceColumns;
    case 'grill':
      return grillColumns;
    default:
      return [];
  }
};

export const mapEntranceTicketsSubTable = (row: GroupedEntranceTicketsRow) => {
  const entranceDataSource = [
    {
      key: row.key,
      adultTickets: row.adultTickets,
      otherTickets: row.otherTickets,
      registeredVisits: row.registeredVisits,
    },
  ];
  return entranceDataSource;
};

export const mapExperienceTicketsSubTable = (row: GroupedActivityTicketsRow) => {
  return row.activityTimes?.map((activityTime, index) => {
    return {
      key: index,
      ticketAmount: activityTime.count,
      maxTicketsPerTime: Number(row.stockPerTime),
      activityTime: activityTime.startEndHour,
    };
  });
};

export const mapGrillsSubTable = (row: GroupedActivityTicketsRow) => {
  return row.reservedGrills?.map((grill) => {
    return {
      key: grill.name,
      soldGrill: grill.name,
    };
  });
};
