import { Activity } from 'apollo/types';

const dataToTable = (data: Activity[] | undefined) => {
  if (!data) return [];
  const mappedActivities = data?.map((activity, index) => {
    const { name, value, status, activityTimes, maxStock, place } = activity;
    return {
      key: index,
      name,
      value,
      maxStock,
      status,
      activityTimes: activityTimes.length,
      place: place.name,
      actions: activity,
    };
  });
  return mappedActivities;
};

export default dataToTable;
