import { ActivityTimeRow } from '../../types';

const deleteFromDataSource = (
  record: ActivityTimeRow,
  dataSource: ActivityTimeRow[],
  setDataSource: React.Dispatch<React.SetStateAction<ActivityTimeRow[]>>
) => {
  const newData = [...dataSource];
  const index = newData.findIndex((item) => item.key === record.key);
  if (index > -1) {
    newData.splice(index, 1);
  }
  setDataSource(newData);
};

export default deleteFromDataSource;
