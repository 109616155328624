import { Button, Form } from 'components/UI';
import { Form as AntDForm, Row } from 'antd';
import React, { FC } from 'react';
import clientFormFields from 'views/Mailer/utils/formFields';
import { Client } from 'apollo/types';
import useSendMailMutation from 'apollo/resolvers/client/sendMail';
import * as texts from '../../../../assets/texts/mailer';
import styles from '../../../../views/Mailer/Mailer.module.scss';

export interface Mail {
  subject: string;
  body: string;
}

export interface CreateMailProps {
  clients: Client[];
  onEmailSent: () => void;
}

const CreateMail: FC<CreateMailProps> = ({ clients, onEmailSent }: CreateMailProps) => {
  const [form] = AntDForm.useForm();
  const [sendMailMutation] = useSendMailMutation();

  const onSubmit = async () => {
    await form.validateFields();
    const formValues = form.getFieldsValue() as Mail;
    const { subject, body } = formValues;
    const args = {
      subject,
      body,
      clientsEmails: clients.map((client) => client.email),
    };

    await sendMailMutation({
      variables: { input: args },
    });

    onEmailSent();
  };

  return (
    <>
      <h2 className={styles.Title} style={{ marginTop: '20px' }}>
        {texts.createMail}
      </h2>
      <Row justify="start">
        <Form
          formFields={clientFormFields()}
          isCreationForm
          form={form}
          preserve={false}
        />
      </Row>
      <Row justify="end">
        <Button type="primary" text="Enviar" onClick={onSubmit} />
      </Row>
    </>
  );
};

export default CreateMail;
