import Searcher from 'components/views/Purchases/Searcher';
import React, { FC, useState, useEffect } from 'react';
import { Form, Pagination } from 'antd';
import Table from 'components/UI/Table';
import { Filter, FilterType, TicketPurchase } from 'apollo/types';
import dataToTable from 'components/views/Purchases/Searcher/utils/dataToTable';
import * as texts from 'assets/texts/validation';
import useListPurchasesLazyQuery from 'apollo/resolvers/purchases/validations';
import { UserAuth } from 'auth/types';
import Button from 'components/UI/Button';
import { DetailModal } from 'components/views/Purchases/DetailModal';
import uselistTicketTypesQuery from 'apollo/resolvers/purchases/ticketTypes';
import { SearcherInput } from 'components/views/Purchases/Searcher/types';
import styles from './Validation.module.scss';
import createInputs from './utils/createInputs';
import createColumns from './utils/createColumns';
import ticketTypesMapper from '../Reservation/utils/ticketTypesMapper';

const Validation: FC = () => {
  const userAuth = localStorage.getItem('UserAuth');
  const jsonUserAuth = userAuth ? (JSON.parse(userAuth) as UserAuth) : null;
  const userId = jsonUserAuth ? jsonUserAuth.databaseId : '';
  const databaseId = userId ? userId.toString() : '';

  const [date] = useState(new Date().toISOString());
  const [form] = Form.useForm();
  const [filters, setFilters] = useState<Filter[]>([
    { filterType: FilterType.Date, filter: date },
  ]);
  const validReservations = false;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentPageSize, setCurrentPageSize] = useState<number>(10);
  const [count, setCount] = useState<number>();
  const [purchases, setPurchases] = useState<TicketPurchase[] | undefined>(undefined);
  const [isDetailModalVisible, setIsDetailModalVisible] = useState<boolean>(false);
  const [selectedPurchaseDetail, setSelectedPurchaseDetail] = useState<TicketPurchase>();
  const [listPurchases, { loading }] = useListPurchasesLazyQuery(
    databaseId,
    filters,
    validReservations,
    currentPage,
    currentPageSize
  );
  const [inputs, setInputs] = useState<SearcherInput[]>(createInputs(date));
  const ticketTypesQuery = uselistTicketTypesQuery();
  const ticketTypesQueryData = ticketTypesQuery.data;

  const getPurchases = async () => {
    return listPurchases({
      variables: {
        userId: databaseId,
        filters,
        validReservations,
        page: currentPage,
        pageSize: currentPageSize,
      },
    });
  };

  const updateTable = async () => {
    const response = await getPurchases();
    setCount(response.data?.listPurchases.count);
    setPurchases(response.data?.listPurchases.purchases);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    updateTable();
  }, [currentPage, currentPageSize]);

  const updateTableAndPage = async () => {
    await updateTable();
    setCurrentPage(1);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    updateTableAndPage();
  }, [filters]);

  const selectPurchaseDetail = (purchase: TicketPurchase) => {
    setSelectedPurchaseDetail(purchase);
    setIsDetailModalVisible(true);
  };

  const renderPurchaseDetailButton = (purchase: TicketPurchase) => {
    return (
      <Button
        type="primary"
        onClick={() => selectPurchaseDetail(purchase)}
        className={styles.Button}
        text={texts.detail}
      />
    );
  };

  const columns = createColumns(renderPurchaseDetailButton);

  const setTicketTypesOptions = () => {
    if (ticketTypesQueryData) {
      const inputsCopy = [...inputs];
      inputsCopy[3].options = ticketTypesMapper(ticketTypesQueryData);
      setInputs(inputsCopy);
    }
  };

  useEffect(() => {
    setTicketTypesOptions();
  }, [ticketTypesQueryData]);

  return (
    <>
      {selectedPurchaseDetail && (
        <DetailModal
          visible={isDetailModalVisible}
          onCancel={() => setIsDetailModalVisible(false)}
          purchaseDetail={selectedPurchaseDetail}
        />
      )}
      <h2 className={styles.Title}>{texts.title}</h2>
      <Searcher inputs={inputs} form={form} filterSetter={setFilters} />
      <h2 className={styles.Title}>{texts.details}</h2>
      <Table
        columns={columns}
        dataSource={dataToTable(purchases)}
        loading={loading}
        pagination={false}
      />
      <Pagination
        className={styles.Pagination}
        total={count}
        showSizeChanger
        pageSize={currentPageSize}
        onChange={(page, pageSize) => {
          setCurrentPage(page);
          setCurrentPageSize(pageSize);
        }}
        current={currentPage}
      />
    </>
  );
};

export default Validation;
