import { Pack } from 'apollo/types';
import { FormFields, Options } from 'components/UI/Form/types';
import { ReactNode } from 'react';

const packFormFields = (
  isCreation: boolean,
  entranceTicketTypesOptions: Options | undefined,
  activitiesOptions: Options | undefined,
  editableRow?: Pack,
  uploadelement?: ReactNode
): FormFields[] => {
  const formFields = [
    {
      name: 'name',
      label: 'Tipo de Ticket',
      type: 'text',
      initialValue: editableRow?.name || '',
      rules: [{ required: isCreation }],
    },
    {
      name: 'adultValue',
      label: 'Precio ticket adultos',
      type: 'number',
      initialValue: editableRow?.adultValue,
      rules: [{ required: isCreation, min: 0 }],
    },
    {
      name: 'otherValue',
      label: 'Precio adulto mayor o niños',
      type: 'number',
      initialValue: editableRow?.otherValue,
      rules: [{ required: isCreation, min: 0 }],
    },
    {
      name: 'description',
      label: 'Descripción',
      type: 'textarea',
      initialValue: editableRow?.description || '',
      rules: [{ required: isCreation }],
    },
    {
      name: 'entranceTicketTypes',
      label: 'Tipos de entrada',
      type: 'select',
      initialValue:
        editableRow?.entranceTicketTypes.map((entranceTicket) => {
          return {
            label: entranceTicket.name?.toString() || '',
            value: Number(entranceTicket.id),
          };
        }) || [],
      options: entranceTicketTypesOptions,
      rules: [{ required: isCreation }],
      mode: 'multiple' as const,
    },
    {
      name: 'activities',
      label: 'Actividades',
      type: 'select',
      initialValue:
        editableRow?.activities.map((activity) => {
          return {
            label: activity.name?.toString() || '',
            value: Number(activity.id),
          };
        }) || [],
      options: activitiesOptions,
      rules: [{ required: isCreation }],
      mode: 'multiple' as const,
    },
  ];

  if (isCreation) {
    return formFields;
  }

  return [
    ...formFields,
    {
      name: 'images',
      label: 'Fotos',
      type: 'images',
      uploadelement,
      rules: [{ required: false }],
    },
  ];
};

export default packFormFields;
