import React, { useState, FC, ReactElement, ReactNode } from 'react';
import { Input as AntdInput, FormInstance, Switch } from 'antd';
import { RichTextEditor } from '@mantine/rte';
import { DefaultOptionType } from 'antd/lib/select';
import ActivityTimesForm from 'components/views/ActivityConfiguration/ActivityTimesForm';
import { ActivityTime } from 'apollo/types';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';
import Select from '../Select';
import DatePicker from '../DatePicker';
import styles from './Input.module.scss';
import { Options } from '../Form/types';

type InputProps = {
  type: string;
  initialValue?: string | number | boolean | string[] | Options | ActivityTime[];
  fileList?: string[];
  variableName?: string;
  options?: DefaultOptionType[];
  formInstance?: FormInstance;
  className?: string;
  placeholder?: string;
  uploadelement?: ReactNode;
  mode?: 'multiple' | 'tags';
};

const Input: FC<InputProps> = (props: InputProps): ReactElement => {
  const {
    type,
    initialValue,
    options,
    variableName,
    formInstance,
    placeholder,
    uploadelement,
    mode,
    ...otherProps
  } = props;
  const [value, onChange] = useState(initialValue);

  const optionOnChange = (val: unknown) => {
    const values = val as number[];
    formInstance?.setFieldsValue({ [variableName as string]: values });
  };

  const onSwitchChange = (checked: boolean) => {
    formInstance?.setFieldsValue({ [variableName as string]: checked });
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current < moment().endOf('day');
  };

  switch (type) {
    case 'simpleTextArea':
      return (
        <AntdInput.TextArea
          className={styles.Input}
          placeholder={placeholder}
          defaultValue={initialValue as string | number}
          {...otherProps}
        />
      );
    case 'textarea':
      return (
        <RichTextEditor value={value as string} onChange={onChange} {...otherProps} />
      );
    case 'images':
      return <>{uploadelement}</>;
    case 'select':
      if (variableName) {
        return (
          <Select
            options={options}
            initialValue={initialValue as { label: string; value: unknown }[]}
            defaultValue={initialValue as { label: string; value: unknown }[]}
            placeholder={placeholder}
            onChange={optionOnChange}
            variableName={variableName}
            className={styles.Select}
            formInstance={formInstance}
            mode={mode}
            maxTagCount="responsive"
          />
        );
      }
      return <></>;
    case 'activityTimes':
      return (
        <ActivityTimesForm
          initialValue={initialValue as ActivityTime[]}
          formInstance={formInstance}
          variableName={type}
          {...otherProps}
        />
      );
    case 'date':
      return (
        <DatePicker
          initialValue={initialValue as string}
          formInstance={formInstance}
          variableName={type}
          picker="date"
          format="DD-MM-YYYY"
          {...otherProps}
        />
      );
    case 'fromCurrentdate':
      return (
        <DatePicker
          initialValue={initialValue as string}
          formInstance={formInstance}
          variableName={type}
          picker="date"
          disabledDate={disabledDate}
          {...otherProps}
        />
      );
    case 'switch':
      return (
        <Switch defaultChecked={initialValue as boolean} onChange={onSwitchChange} />
      );
    default:
      return (
        <AntdInput
          type={type}
          className={styles.Input}
          placeholder={placeholder}
          defaultValue={initialValue as string | number}
          {...otherProps}
        />
      );
  }
};

Input.defaultProps = {
  fileList: [],
  initialValue: undefined,
  formInstance: undefined,
  options: undefined,
  variableName: undefined,
  className: undefined,
  placeholder: undefined,
  uploadelement: undefined,
  mode: undefined,
};

export default Input;
