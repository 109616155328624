import React, { FC, useEffect, useState } from 'react';
import PurchaseOrderInput from 'components/views/GateValidation/PurchaseOrderInput';
import OrderDetail from 'components/views/GateValidation/OrderDetail';
import { message, Space } from 'antd';
import ClientInformation from 'components/views/GateValidation/ClientInformation';
import { EnrichedPurchaseBackOffice } from 'apollo/types';
import ValidatePurchase from 'components/views/GateValidation/ValidatePurchase';
import * as texts from 'assets/texts/gateValidation';
import { UserAuth } from 'auth/types';
import BackOfficeInformation from 'components/views/GateValidation/BackOfficeUserInformation';
import styles from './GateValidation.module.scss';

const GateValidation: FC = () => {
  const [enrichedPurchase, setEnrichedPurchase] = useState<EnrichedPurchaseBackOffice>();
  const [userAuth, setUserAuth] = useState<UserAuth>();

  const [isPurchaseDisabled, setIsPurchaseDisabled] = useState<boolean>(false);
  const [wasPurchaseValidated, setWasPurchaseValidated] = useState<boolean>(false);

  const isVisitDateInValidToday = (visitDate: string) => {
    const date = visitDate.split('/');

    const visitDateYear = Number(date[2]);
    const visitDateMonth = Number(date[1]) - 1;
    const visitDateDay = Number(date[0]);

    const visit = new Date(visitDateYear, visitDateMonth, visitDateDay);
    const today = new Date();

    if (
      today.getDate() !== visit.getDate() &&
      today.getMonth() !== visit.getMonth() &&
      today.getFullYear() !== visit.getFullYear()
    ) {
      return true;
    }
    return false;
  };

  const setPurchaseFromEnrichedPurchase = (
    enrichedPurchaseFromQuery: EnrichedPurchaseBackOffice | undefined
  ) => {
    if (enrichedPurchaseFromQuery !== undefined) {
      setWasPurchaseValidated(false);
      setEnrichedPurchase(enrichedPurchaseFromQuery);
    } else {
      setWasPurchaseValidated(true);
    }
  };

  const checkPurchaseValidation = (isPurchaseValid: boolean) => {
    setWasPurchaseValidated(isPurchaseValid);
    setEnrichedPurchase(undefined);
    if (isPurchaseValid) {
      message.success(texts.successValidEntranceMsg) as unknown as void;
    } else {
      message.error(texts.errorValidEntranceMsg) as unknown as void;
    }
  };

  const client = enrichedPurchase?.client;
  const purchaseId = enrichedPurchase?.purchase?.id;
  const entranceTickets = enrichedPurchase?.purchase?.entranceTickets;
  const activityTickets = enrichedPurchase?.purchase?.activityTickets;
  const activityTimeTickets = enrichedPurchase?.purchase?.activityTimes;
  const packTickets = enrichedPurchase?.purchase?.packPurchases;
  const visitDate = enrichedPurchase?.purchase?.visitDate;
  const createdAt = enrichedPurchase?.purchase?.createdAt || undefined;
  const giftCardPurchase = enrichedPurchase?.purchase?.giftCardPurchase || undefined;

  useEffect(() => {
    const localStorageUserAuth = localStorage.getItem('UserAuth');
    if (localStorageUserAuth) {
      setUserAuth(JSON.parse(localStorageUserAuth) as UserAuth);
    }
  }, []);

  return (
    <div>
      <Space direction="vertical" size="large" className={styles.InputContainer}>
        <PurchaseOrderInput
          setPurchaseFromEnrichedPurchase={setPurchaseFromEnrichedPurchase}
          setPurchaseIsDisabled={setIsPurchaseDisabled}
        />
        {!wasPurchaseValidated &&
          client &&
          entranceTickets &&
          activityTickets &&
          packTickets &&
          visitDate &&
          activityTimeTickets && (
            <>
              <ClientInformation client={client} />
              <OrderDetail
                purchaseId={purchaseId}
                entranceTickets={entranceTickets}
                activityTickets={activityTickets}
                packTickets={packTickets}
                activityTimeTickets={activityTimeTickets}
                visitDate={visitDate}
                createdAt={createdAt}
                giftCardPurchase={giftCardPurchase}
              />
              {isPurchaseDisabled && enrichedPurchase?.disabledPurchaseHistory && (
                <BackOfficeInformation
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  disabledPurchaseHistory={enrichedPurchase?.disabledPurchaseHistory}
                />
              )}
              {purchaseId && userAuth && userAuth.databaseId && (
                <ValidatePurchase
                  purchaseId={purchaseId}
                  userId={userAuth.databaseId}
                  disabledByDate={isVisitDateInValidToday(visitDate)}
                  disabledByUser={isPurchaseDisabled}
                  isPurchaseValid={checkPurchaseValidation}
                />
              )}
            </>
          )}
      </Space>
    </div>
  );
};

export default GateValidation;
