import { useMutation } from '@apollo/client';
import { MutationCreateNewsBannerArgs, NewsBanner } from 'apollo/types';
import { gql } from 'graphql.macro';

const CREATE_NEWSBANNER = gql`
  mutation createNewsBanner($input: CreateNewsBannerInput!) {
    createNewsBanner(input: $input) {
      title
      body
      isVisible
    }
  }
`;

const useCreateNewsBannerMutation = () => {
  return useMutation<{ createNewsBanner: NewsBanner }, MutationCreateNewsBannerArgs>(
    CREATE_NEWSBANNER,
    {}
  );
};

export default useCreateNewsBannerMutation;
