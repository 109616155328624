import { gql, useQuery } from '@apollo/client';
import { Activity, QueryListActivitiesBackOfficeArgs } from 'apollo/generated/types';

const LIST_ACTIVITIES = gql`
  query listActivities($activityTypeId: ID) {
    listActivitiesBackOffice(activityTypeId: $activityTypeId) {
      activityTimes {
        id
        parkAvailabilityId
        startHour
        endHour
      }
      description
      enDescription
      enName
      id
      maxStock
      name
      paxPerTicket
      status
      value
      place {
        id
        name
      }
    }
  }
`;

const useListActivitiesQuery = (activityTypeId?: string) => {
  return useQuery<
    { listActivitiesBackOffice: Activity[] },
    QueryListActivitiesBackOfficeArgs
  >(LIST_ACTIVITIES, { variables: { activityTypeId } });
};
export default useListActivitiesQuery;
