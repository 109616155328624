import { gql, useMutation } from '@apollo/client';
import { PlaceImage, MutationUploadPlaceImageArgs } from 'apollo/generated/types';

const UPLOAD_PLACE_IMAGE = gql`
  mutation uploadPlaceImage($input: UploadPlaceImageInput!) {
    uploadPlaceImage(input: $input) {
      id
      image
      uploadUrl
    }
  }
`;

const useUploadPlaceImageMutation = () => {
  return useMutation<{ uploadPlaceImage: PlaceImage }, MutationUploadPlaceImageArgs>(
    UPLOAD_PLACE_IMAGE
  );
};
export default useUploadPlaceImageMutation;
