import { EnrichedPurchaseActivityTickets } from 'apollo/generated/types';

export interface MappedReservationActivity {
  key: number;
  ticketType: string;
  quantity: number;
  percentageDiscount: number;
  finalPrice: number;
  visitDate: string;
}

const dataToTable = (data: EnrichedPurchaseActivityTickets[], visitDate: string) => {
  const mappedData = data
    .map((summarizedActivity, index) => {
      const mappedSummarizedActivity: MappedReservationActivity = {
        key: index,
        ticketType: summarizedActivity.name || '',
        quantity: summarizedActivity.quantity || 0,
        percentageDiscount: summarizedActivity.percentageDiscount || 0,
        finalPrice: summarizedActivity.finalPrice || 0,
        visitDate,
      };

      return Array(1).fill(mappedSummarizedActivity) as MappedReservationActivity[];
    })
    .flat();

  return mappedData;
};

export default dataToTable;
