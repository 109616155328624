import { Activity } from 'apollo/types';
import { FormFields } from 'components/UI/Form/types';
import * as texts from '../../../../assets/texts/grillConfiguration';

const genericGrillFormFields = (editableRow?: Activity): FormFields[] => {
  const formFields = [
    {
      name: 'value',
      label: texts.value,
      type: 'number',
      initialValue: editableRow?.value,
      rules: [{ required: false }],
    },
    {
      name: 'paxPerTicket',
      label: texts.paxPerTicket,
      type: 'number',
      initialValue: editableRow?.paxPerTicket,
      rules: [{ required: false }],
    },
  ];

  return formFields;
};

export default genericGrillFormFields;
