import React, { FC, useState, useEffect } from 'react';
import { Row, Col, Switch, Form as AntdForm, message } from 'antd';
import FormModal from 'components/sharedComponents/FormModal';
import * as texts from 'assets/texts/grillConfiguration';
import useListGrillsQuery from 'apollo/resolvers/grill/list';
import {
  Activity,
  PlaceImage,
  CreateGrillInput,
  EditGrillInput,
  EditManyGrillsInput,
} from 'apollo/types';
import { Mode, CreateFormValues } from 'components/sharedComponents/FormModal/types';
import Button from 'components/UI/Button';
import useEditManyGrillsMutation from 'apollo/resolvers/grill/editMany';
import useCreateGrillMutation from 'apollo/resolvers/grill/create';
import useEditGrillMutation from 'apollo/resolvers/grill/edit';
import useUploadPlaceImageMutation from 'apollo/resolvers/placeImage/upload';
import useDeletePlaceImageMutation from 'apollo/resolvers/placeImage/delete';
import { Form, Table, ImagePreviewGroup, UploadImages } from 'components/UI';
import setEditOrCreateForm from './utils/setEditOrCreateForm';
import { ActivityColumn } from './types';
import statusOnChange from './utils/statusOnChange';
import columns from './utils/createColumns';
import styles from './GrillConfiguration.module.scss';
import genericGrillFormFields from './utils/genericGrillsFormField';
import grillFormFields from './utils/formFields';
import formToInput from './utils/formToInput';
import dataToTable from './utils/dataToTable';

const GrillConfiguration: FC = () => {
  const { data, loading, refetch } = useListGrillsQuery();
  const [createGrillMutation] = useCreateGrillMutation();
  const [editGrillMutation] = useEditGrillMutation();
  const [editManyGrillsMutation] = useEditManyGrillsMutation();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<Activity>();
  const [modalMode, setModalMode] = useState<Mode | undefined>();
  const [uploadImage, { data: uploadImageData, loading: uploadImageLoading }] =
    useUploadPlaceImageMutation();
  const [deleteImage, { loading: deleteImageLoading }] = useDeletePlaceImageMutation();
  const [form] = AntdForm.useForm();

  const statusColumnRender = (value: boolean, record: unknown): JSX.Element => {
    const activity = record as ActivityColumn;
    return (
      <Switch
        defaultChecked={value}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={() => statusOnChange(activity, editGrillMutation)}
      />
    );
  };

  const editButton = (item: Activity): JSX.Element => (
    <Button
      onClick={() =>
        setEditOrCreateForm(item, setEditItem, setModalMode, setIsModalVisible)
      }
      type="primary"
      text={texts.editButton}
    />
  );

  const refetchFunction = async () => {
    await refetch();
  };

  const createImageGroup = (entranceTicketImages: PlaceImage[]): JSX.Element => {
    const images = entranceTicketImages.map((image) => image.image || undefined);
    return <ImagePreviewGroup images={images} maxImagesToShow={2} widthImages={50} />;
  };

  const imageGroup = (item: PlaceImage[] | undefined): JSX.Element => {
    if (item && item.length > 0) {
      return createImageGroup(item);
    }
    return <></>;
  };

  const editMutation = async (formValues: EditGrillInput) => {
    const activityFormValues = formValues;
    const item = await editGrillMutation({
      variables: { input: activityFormValues },
    });
    return item;
  };

  const createMutation = async (
    formValues: CreateFormValues,
    redirectToAddImages: boolean
  ) => {
    const grillAndPlaceFormValues = formValues as CreateGrillInput;
    const item = await createGrillMutation({
      variables: { input: grillAndPlaceFormValues },
    });

    if (redirectToAddImages) {
      setEditItem(item.data?.createGrill);
      setEditOrCreateForm(
        item.data?.createGrill,
        setEditItem,
        setModalMode,
        setIsModalVisible
      );
    }
    return item;
  };

  const updateAllGrillsMutation = async () => {
    const genericGrillFormValues = form.getFieldsValue() as EditManyGrillsInput;

    const item = await editManyGrillsMutation({
      variables: { input: genericGrillFormValues },
    });

    if (item.data?.editGrillsGenericProps.count) {
      message.success(
        'Se han actualizado las propiedades de los quinchos.'
      ) as unknown as undefined;
    }
  };

  const uploadPlaceImage = async (values: {
    id: bigint | undefined;
    parentEntityId: bigint;
    contentType: string;
    isMain: boolean;
  }) => {
    await uploadImage({
      variables: {
        input: {
          id: values.id?.toString(),
          placeId: values.parentEntityId.toString(),
          contentType: values.contentType,
          isMain: values.isMain,
        },
      },
    });
  };

  const deletePlaceImage = async (values: {
    id: bigint;
    parentEntityId: bigint;
    isMain: boolean;
  }) => {
    await deleteImage({
      variables: {
        input: {
          id: values.id.toString(),
          placeId: values.parentEntityId.toString(),
          isMain: values.isMain,
        },
      },
    });
  };

  const setActivitiesBackOfficeFromQuery = () => {
    if (!data) {
      return;
    }
    if (data.listActivitiesBackOffice) {
      if (editItem) {
        const foundItem = data.listActivitiesBackOffice.find(
          (item) => item.id === editItem.id
        );
        if (foundItem) setEditItem(foundItem as unknown as Activity);
      }
    }
  };

  useEffect(() => {
    setActivitiesBackOfficeFromQuery();
  }, [data]);

  const uploadElement = (isMain = false) => {
    let images = editItem?.place.placeImages || [];
    if (images && isMain) {
      images = images.filter((img) => img.image === editItem?.place.placeMainImage);
    }
    return (
      <UploadImages
        images={images}
        parentEntityId={BigInt(editItem?.place.id || 0)}
        uploadedImageId={
          uploadImageData?.uploadPlaceImage.id
            ? BigInt(uploadImageData?.uploadPlaceImage.id)
            : undefined
        }
        uploadUrl={uploadImageData?.uploadPlaceImage.uploadUrl || undefined}
        loading={uploadImageLoading || deleteImageLoading}
        isMain={isMain}
        uploadCallback={uploadPlaceImage}
        deleteCallback={deletePlaceImage}
        refetchImagesCallback={refetchFunction}
      />
    );
  };

  const renderHTMLTag = (description: string) => {
    return (
      <div
        className={styles.Description}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: description }}
      />
    );
  };

  const renderFormFields = () => {
    const editModalMode = modalMode === 'edit';
    if (editModalMode) {
      return grillFormFields(false, editItem, uploadElement(), uploadElement(true));
    }
    return grillFormFields(true, editItem);
  };

  return (
    <>
      <FormModal
        formToInput={formToInput}
        isModalVisible={isModalVisible}
        editOrCreateItem={editItem}
        setIsModalVisible={setIsModalVisible}
        formFields={renderFormFields()}
        title={modalMode === 'create' ? texts.createButton : texts.editTitle}
        mode={modalMode}
        editFormValues={editMutation}
        createFormValues={createMutation}
        refetch={refetchFunction}
      />
      <Row>
        <Col>
          <h2 className={styles.Title}>{texts.title}</h2>
        </Col>
      </Row>
      <Row>
        <Col span={18}>
          <Form
            preserve={false}
            formFields={genericGrillFormFields(data?.listActivitiesBackOffice[0])}
            form={form}
            isCreationForm={false}
            isHorizontal
          />
        </Col>
        <Col span={6}>
          <Button onClick={updateAllGrillsMutation} type="primary" text={texts.save} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            onClick={() =>
              setEditOrCreateForm(undefined, setEditItem, setModalMode, setIsModalVisible)
            }
            text={texts.createButton}
            type="primary"
          />
        </Col>
      </Row>
      <Row className={styles.Row}>
        <Col span={24}>
          <Table
            columns={columns(statusColumnRender, editButton, imageGroup, renderHTMLTag)}
            dataSource={dataToTable(data?.listActivitiesBackOffice)}
            loading={loading}
            rowKey="name"
            pagination={{ pageSize: 5 }}
          />
        </Col>
      </Row>
    </>
  );
};

export default GrillConfiguration;
