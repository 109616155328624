import { gql, useQuery } from '@apollo/client';
import { EntranceTicketTypeBackOffice } from 'apollo/generated/types';

const LIST_ENTRANCE_TICKET_TYPES_BACKOFFICE = gql`
  query listEntranceTicketTypesBackOffice {
    listEntranceTicketTypesBackOffice {
      adultValue
      description
      id
      maxStock
      name
      otherValue
      status
      isBicycle
      visibility
      entranceImages {
        id
        image
        uploadUrl
      }
    }
  }
`;

const useListEntranceTicketTypesQuery = () => {
  return useQuery<{ listEntranceTicketTypesBackOffice: EntranceTicketTypeBackOffice[] }>(
    LIST_ENTRANCE_TICKET_TYPES_BACKOFFICE,
    {}
  );
};
export default useListEntranceTicketTypesQuery;
