import React, { FC, ReactElement } from 'react';
import { Table as AntdTable } from 'antd';
import { TableProps } from 'antd/lib/table/index';
import { ActivityTimeRow } from 'components/views/ActivityConfiguration/types';
import EditableCell from './EditableCell';
import styles from './EditableTable.module.scss';

type CustomTableProps = TableProps<ActivityTimeRow>;

const EditableTable: FC<CustomTableProps> = (props: CustomTableProps): ReactElement => {
  const { ...otherProps } = props;

  return (
    <AntdTable
      {...otherProps}
      components={{
        body: { cell: EditableCell },
      }}
      pagination={false}
      rowClassName={(record, index) =>
        index % 2 === 0 ? styles.tableRowDark : styles.tableRowLight
      }
    />
  );
};

export default EditableTable;
