import { FormInstance } from 'antd';
import { CreateGrillInput } from 'apollo/types';
import { CreateFormValues, FormToInput } from '../types';

const handleCreate = async (
  form: FormInstance,
  redirectToAddImages: boolean,
  formToInput: FormToInput,
  createFormValues: (
    formValues: CreateFormValues,
    redirectToAddImages: boolean
  ) => Promise<unknown>
): Promise<unknown> => {
  await form.validateFields();
  const formValues = formToInput(form) as CreateGrillInput;
  if (!formValues) {
    // TODO: ven manejo de errores en front.
    return 'Error';
  }
  const createdItem = await createFormValues(formValues, redirectToAddImages);
  return createdItem;
};

export default handleCreate;
