export const title = 'Validación de entradas en portería';
export const orderDetail = 'Detalle de orden';
export const purchaseCreatedAt = 'Fecha de compra';
export const purchaseNumberInput = 'Ingresar Nº de compra';
export const purchaseNumberInputError = 'Debes ingresar un Nº de compra';
export const purchaseNotFoundError =
  'No se ha encontrado ninguna compra por validar con Nº ';
export const purchaseAlreadyValidated = 'La compra ya ha sido validada.';
export const search = 'Buscar';
export const clientInformation = 'Información del cliente';
export const id = 'ID';
export const document = 'RUT/Pasaporte';
export const name = 'Nombre';
export const birthRange = 'Rango etario';
export const phoneNumber = 'Teléfono';
export const mail = 'Mail';
export const orderNumber = 'Nro de Orden';
export const visitDate = 'Fecha de visita';
export const hourVisitDate = 'Horario de visita';
export const rut = 'Rut';
export const billingInformation = 'Información de facturación';
export const billingDocumentNumber = 'Número de documento electrónico';
export const billingDocumentType = 'Tipo de documento';
export const invoice = 'Factura';
export const voucher = 'Boleta';
export const entranceTicketType = 'Tipo de entrada';
export const quantity = 'Cantidad';
export const percentageDiscount = '% Descuento';
export const finalPrice = 'Precio final';
export const activityTime = 'Horario';
export const activityTicketType = 'Tipo de entrada';
export const ticketCount = 'Cantidad de tickets';
export const adultTicketCount = 'Cantidad de tickets adulto';
export const otherTicketCount = 'Cantida de ticket niño/adulto mayor';
export const ticketTime = 'Horario';
export const total = 'Sub-total';
export const state = 'Estado';
export const validateEntrance = 'Validar entradas';
export const invalidDateEntrances = 'La fecha de visita es distinta a la de hoy.';
export const invalidUserEntrances = 'La compra ha sido deshabilitada por un usuario.';
export const successValidEntranceMsg = 'Se han validado las entradas con éxito';
export const errorValidEntranceMsg = 'Ha surgido un problema al validar las entradas.';
export const purchaseDisabledError = 'La compra está deshabilitada';
export const backOfficeUserInformation =
  'Información de usuario que deshabilitó la compra';
export const disabledDate = 'Fecha de deshabilitación';
export const packName = 'Nombre del pack';
export const notAvailable = 'No disponible';
export const giftCardAmountUsed = 'Monto de Gift Card utilizado';
export const usedGiftCard = 'Gift Card utilizada';
