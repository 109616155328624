/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { UserAuth } from 'auth/types';
import userPool from 'auth/userPool';

const logIn = (
  email: string,
  password: string,
  onSuccessFunction?: (userAuth: UserAuth, cognitoUser: CognitoUser) => void,
  onLoadingFunction?: () => void,
  onErrorFunction?: (error: Error) => void
) => {
  if (onLoadingFunction) onLoadingFunction();
  const authData = {
    Username: email,
    Password: password,
  };
  const authDetails = new AuthenticationDetails(authData);
  const userData = {
    Username: email,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  cognitoUser.authenticateUser(authDetails, {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess(result: CognitoUserSession | any) {
      const userAuth = {
        userId: result.idToken.payload.sub as string,
        idToken: result.idToken.jwtToken as string,
        authenticated: true,
        username: email,
        accessToken: result.accessToken.jwtToken as string,
      };
      if (onSuccessFunction) onSuccessFunction(userAuth, cognitoUser);
    },
    onFailure(error: Error) {
      if (onErrorFunction) onErrorFunction(error);
    },
  });
};

export default logIn;
