import { CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import userPool from 'auth/userPool';
import * as texts from 'assets/texts/auth';

const getAttributes = (
  onSuccessFunction?: (result: CognitoUserAttribute[]) => void,
  onLoadingFunction?: () => void,
  onErrorFunction?: (error: Error) => void
) => {
  if (onLoadingFunction) onLoadingFunction();
  const cognitoUser = userPool.getCurrentUser() as CognitoUser;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  cognitoUser.getSession((_err: Error, _session: null) => {});
  cognitoUser.getUserAttributes((error, result) => {
    if (error) {
      if (onErrorFunction) onErrorFunction(error);
    }
    if (result) {
      if (onSuccessFunction) onSuccessFunction(result);
      return result;
    }
    if (onErrorFunction)
      onErrorFunction({
        message: texts.getAttributesError,
      } as Error);
    return undefined;
  });
};

export default getAttributes;
