import { Form } from 'antd';
import { Filter } from 'apollo/generated/types';
import Searcher from 'components/views/Purchases/Searcher';
import { SearcherInput } from 'components/views/Purchases/Searcher/types';
import React, { FC } from 'react';
import * as texts from '../../../../assets/texts/mailer';
import styles from '../../../../views/Mailer/Mailer.module.scss';

export interface ListClientSearcherProps {
  onFinished: (value: React.SetStateAction<Filter[]>) => void;
  inputs: SearcherInput[];
}

const ListClientSearcher: FC<ListClientSearcherProps> = ({
  onFinished,
  inputs,
}: ListClientSearcherProps) => {
  const [form] = Form.useForm();

  return (
    <>
      <h2 className={styles.Title}>{texts.searchClients}</h2>
      <Searcher inputs={inputs} form={form} filterSetter={onFinished} />
    </>
  );
};

export default ListClientSearcher;
