/* eslint-disable @typescript-eslint/no-floating-promises */
import { useState, useEffect } from 'react';
import { message as messageAntd } from 'antd';

const useSuccess = <T>(success: T, message = 'Success'): T => {
  const [successValue, setSuccessValue] = useState<T>(success);

  useEffect(() => {
    if (success) {
      setSuccessValue(success);
      messageAntd.success(message);
    }
  }, [success, message]);
  return successValue;
};

export default useSuccess;
