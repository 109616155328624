import { FormInstance } from 'antd';
import { EditProgramInput } from 'apollo/types';
import { ProgramEdit } from '../types';

const programConfigurationFormToInput = (form: FormInstance): ProgramEdit => {
  const formValues = form.getFieldsValue(true) as EditProgramInput;
  const mappedFormValues = {
    id: formValues.id,
    title: formValues.title || '',
    subtitle: formValues.subtitle || '',
    description: formValues.description || '',
    hoursDuration: Number(formValues.hoursDuration),
    targetGroup: formValues.targetGroup || '',
    videoLink: formValues.videoLink || undefined,
  };
  return mappedFormValues;
};

export default programConfigurationFormToInput;
