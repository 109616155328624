import React, { FC, ReactElement } from 'react';
import { DatePicker as AntdDatePicker, FormInstance } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker/index';

type CustomDatePickerProps = DatePickerProps & {
  initialValue?: string;
  formInstance?: FormInstance;
  variableName: string;
};

const DatePicker: FC<CustomDatePickerProps> = (
  props: CustomDatePickerProps
): ReactElement => {
  const { initialValue, formInstance, variableName, ...otherProps } = props;

  return <AntdDatePicker {...otherProps} />;
};
DatePicker.defaultProps = {
  formInstance: undefined,
  initialValue: undefined,
};

export default DatePicker;
