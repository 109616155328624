import React, { FC } from 'react';
import * as texts from 'assets/texts/gateValidation';
import { Row, Col, Space } from 'antd';
import {
  EnrichedPurchaseActivityTickets,
  EnrichedPurchaseActivityTimes,
  EnrichedPurchaseEntranceTickets,
  EnrichedPurchasePackPurchases,
  GiftCardPurchase,
} from 'apollo/types';
import priceFormat from 'utils/formats/priceFormat';
import styles from './OrderDetail.module.scss';
import EntrancesTable from './EntrancesTable';
import ActivitiesTable from './ActivitiesTable';
import PacksTable from './PacksTable';
import ActivityTimesTable from './ActivityTimesTable';

export interface OrderDetailProps {
  purchaseId: string | undefined;
  entranceTickets: EnrichedPurchaseEntranceTickets[] | undefined;
  activityTickets: EnrichedPurchaseActivityTickets[] | undefined;
  packTickets: EnrichedPurchasePackPurchases[] | undefined;
  activityTimeTickets: EnrichedPurchaseActivityTimes[] | undefined;
  visitDate: string | undefined;
  createdAt: string | undefined;
  giftCardPurchase: GiftCardPurchase | undefined;
}

const OrderDetail: FC<OrderDetailProps> = ({
  purchaseId,
  entranceTickets,
  activityTickets,
  packTickets,
  activityTimeTickets,
  visitDate,
  createdAt,
  giftCardPurchase,
}: OrderDetailProps) => {
  const areEntranceTickets = entranceTickets && entranceTickets.length > 0;
  const areActivityTickets = activityTickets && activityTickets.length > 0;
  const arePackTickets = packTickets && packTickets.length > 0;
  const areActivityTimeTickets = activityTimeTickets && activityTimeTickets.length > 0;
  return (
    <Row className={styles.OrderDetailContainer}>
      <Col span={24}>
        <Space direction="vertical" size="large" className={styles.Table}>
          {purchaseId && (
            <>
              {giftCardPurchase && (
                <>
                  <h3>
                    {texts.usedGiftCard} #{giftCardPurchase.giftCardId}
                  </h3>
                  <p className={styles.GiftCardAmountUsed}>
                    {texts.giftCardAmountUsed}:{' '}
                    {priceFormat.format(giftCardPurchase.amountUsed)}
                  </p>
                </>
              )}
              <h3>
                {texts.orderDetail} #{purchaseId}
              </h3>
              {createdAt && (
                <p className={styles.CreatedAt}>
                  {texts.purchaseCreatedAt}: {createdAt}
                </p>
              )}
              {areEntranceTickets && (
                <EntrancesTable
                  purchaseId={purchaseId}
                  entranceTickets={entranceTickets}
                  visitDate={visitDate}
                />
              )}
              {arePackTickets && (
                <PacksTable
                  purchaseId={purchaseId}
                  packPurchases={packTickets}
                  visitDate={visitDate}
                />
              )}
              {areActivityTickets && (
                <ActivitiesTable
                  purchaseId={purchaseId}
                  activityTickets={activityTickets}
                  visitDate={visitDate}
                />
              )}
              {areActivityTimeTickets && (
                <ActivityTimesTable
                  purchaseId={purchaseId}
                  activityTimesTickets={activityTimeTickets}
                />
              )}
            </>
          )}
        </Space>
      </Col>
    </Row>
  );
};

export default OrderDetail;
