import { Excel } from 'antd-table-saveas-excel';
import { Columns } from 'components/views/Report/types/types';
import { CodeForExcelDataSource } from '../types';

const toExcel = (columns: Columns[], dataSource: CodeForExcelDataSource[]) => {
  const excel = new Excel();
  excel
    .addSheet('Códigos de promoción')
    .addColumns(columns)
    .addDataSource(dataSource)
    .saveAs('códigos_promocionales_tricao.xlsx');
};

export default toExcel;
