// Styles are created in this file, and not in a module.scss, since the Calendar dateRender function does not take some
// styles from className.

export const isTodayContainerStyle = {
  display: 'block',
  width: 'auto',
  height: '80px',
  margin: '0 4px',
  padding: '4px 8px 0',
  border: '0',
  borderTop: '2px solid #1890ff',
  borderRadius: '0',
  transition: 'background 0.3s',
  marginBottom: '5px',
};

export const isNotTodayContainerStyle = {
  display: 'block',
  width: 'auto',
  height: '80px',
  margin: '0 4px',
  padding: '4px 8px 0',
  border: '0',
  borderTop: '2px solid #f0f0f0',
  borderRadius: '0',
  transition: 'background 0.3s',
  marginBottom: '5px',
};

export type FontWeight =
  | 'bold'
  | number
  | '-moz-initial'
  | 'inherit'
  | 'initial'
  | 'revert'
  | 'unset'
  | 'normal'
  | 'bolder'
  | 'lighter'
  | undefined;

export const isSelectedStyle = {
  display: 'inline-block',
  width: '25px',
  lineHeight: '25px',
  textAlign: 'center' as const,
  backgroundColor: '#21b367',
  color: '#fff',
  borderRadius: '50%',
  fontWeight: '400' as FontWeight,
};

export const isNotSelectedStyle = {
  display: 'inline-block',
  lineHeight: '25px',
  width: '25px',
  backgroundColor: 'transparent',
  textAlign: 'center' as const,
  fontWeight: '400' as FontWeight,
};
