import { useMutation } from '@apollo/client';
import { MutationUpdateThingToDoArgs, ThingToDo } from 'apollo/types';
import { gql } from 'graphql.macro';

const EDIT_THINGS_TO_DO = gql`
  mutation updateThingToDo($input: UpdateThingToDoInput!) {
    updateThingToDo(input: $input) {
      description
      id
      name
      thingToDoImages {
        id
        image
      }
    }
  }
`;

const useEditThingToDoMutation = () => {
  return useMutation<{ editThingToDo: ThingToDo }, MutationUpdateThingToDoArgs>(
    EDIT_THINGS_TO_DO,
    {}
  );
};

export default useEditThingToDoMutation;
