import React from 'react';
import { Form, Row, Space, Switch, Table } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { Button, Modal } from 'components/UI';
import styles from './ActivityTimetableConfigurator.module.scss';
import * as texts from '../../../../assets/texts/openDays';
import { ActivityTimeTableConfigurator, ActivityTimeWithStatus } from './utils/types';
import filterAndMapActivityTimes from './utils/filterAndMapActivityTimes';

const columns = [
  {
    title: texts.aviaryTimeTableColStartDate,
    dataIndex: 'startHour',
    key: 'startHour',
  },
  {
    title: texts.aviaryTimeTableColEndDate,
    dataIndex: 'endHour',
    key: 'endHour',
  },
  {
    title: texts.aviaryTimeTableColSelected,
    dataIndex: 'selected',
    key: 'selected',
    render: (_: unknown, record: ActivityTimeWithStatus) => (
      <Form.Item
        id={record.id}
        name={record.id}
        valuePropName="checked"
        initialValue={record.selected}
      >
        <Switch />
      </Form.Item>
    ),
  },
];

const ModalForm: React.FC<ActivityTimeTableConfigurator> = ({
  isModalVisible,
  setIsModalVisible,
  onCancel,
  aviaryActivityTimes,
  parkAvailability,
  handleOnSuccessNewOpenDay,
}) => {
  const [form] = useForm();
  const data = filterAndMapActivityTimes(aviaryActivityTimes, parkAvailability);

  const onFinish = async (values: Record<string, boolean>) => {
    const activityTimesToSave: ActivityTimeWithStatus[] = data.map((activityTime) => ({
      ...activityTime,
      selected: values[activityTime.id],
    }));
    const activityTimesIds = activityTimesToSave
      .filter((activityTimeToFilter) => activityTimeToFilter.selected)
      .map((activityTimeToSave) => Number(activityTimeToSave.id));
    await handleOnSuccessNewOpenDay(activityTimesIds);
  };
  return (
    <Modal
      title={texts.aviaryTimetableTitleModal}
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
      className={styles.ModalTitle}
    >
      <Form
        form={form}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises, @typescript-eslint/no-unsafe-argument
        onFinish={(values) => onFinish(values)}
        initialValues={{ timeRanges: data }}
      >
        <Table
          dataSource={data}
          columns={columns}
          pagination={false}
          rowClassName={styles.CustomRow}
        />
        <Row justify="end" className={styles.FooterButtons}>
          <Space>
            <Form.Item>
              <Button type="primary" htmlType="submit" text="Guardar" />
            </Form.Item>
            <Form.Item>
              <Button type="ghost" text="Cancelar" onClick={onCancel} />
            </Form.Item>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalForm;
