import { gql, useMutation } from '@apollo/client';
import { MutationEditNewsPostArgs, NewsPost } from 'apollo/generated/types';

const EDIT_NEWS_POST = gql`
  mutation editNewsPost($input: EditNewsPostInput!) {
    editNewsPost(input: $input) {
      id
    }
  }
`;

const useEditNewsPostMutation = () => {
  return useMutation<{ editNewsPost: NewsPost }, MutationEditNewsPostArgs>(
    EDIT_NEWS_POST,
    {}
  );
};
export default useEditNewsPostMutation;
