import { Button, Modal } from 'components/UI';
import React, { useState } from 'react';
import { Row } from 'antd';
import styles from './CloseDayConfirmationModal.module.scss';
import * as texts from '../../../../assets/texts/openDays';

interface CloseDayConfirmationModalProps {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  confirmationFunction: () => Promise<void>;
}

const CloseDayConfirmationModal: React.FC<CloseDayConfirmationModalProps> = ({
  isModalVisible,
  setIsModalVisible,
  confirmationFunction,
}) => {
  const [confirming, setConfirming] = useState(false);

  const handleConfirm = async () => {
    setConfirming(true);
    await confirmationFunction();
    setIsModalVisible(false);
    setConfirming(false);
  };

  const closeModal = () => setIsModalVisible(false);
  const confirmButtonText = confirming ? 'Confirmando...' : 'Confirmar';
  const footerButtons = () => [
    <Button
      key="confirm"
      type="primary"
      onClick={handleConfirm}
      loading={confirming}
      disabled={confirming}
      text={confirmButtonText}
    />,
    <Button
      key="cancel"
      onClick={closeModal}
      disabled={confirming}
      text="Cancelar"
      type="ghost"
    />,
  ];
  return (
    <Modal
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      onCancel={closeModal}
      footer={footerButtons()}
      width={400}
      centered
    >
      <Row justify="center" className={styles.Text}>
        {texts.closeDayModal}
      </Row>
    </Modal>
  );
};

export default CloseDayConfirmationModal;
