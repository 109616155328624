import { EnrichedPurchaseEntranceTickets } from 'apollo/generated/types';

export interface MappedReservationEntrance {
  key: number;
  ticketType: string;
  quantity: number;
  percentageDiscount: number;
  finalPrice: number;
  visitDate: string;
}

const dataToTable = (data: EnrichedPurchaseEntranceTickets[], visitDate: string) => {
  const mappedData = data
    .map((entrance, index) => {
      const mappedEntrance: MappedReservationEntrance = {
        key: index,
        ticketType: entrance.name || '',
        quantity: entrance.quantity || 0,
        percentageDiscount: entrance.percentageDiscount || 0,
        finalPrice: entrance.finalPrice || 0,
        visitDate,
      };

      return Array(1).fill(mappedEntrance) as MappedReservationEntrance[];
    })
    .flat();

  return mappedData;
};

export default dataToTable;
