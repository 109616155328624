import React, { FC, ReactElement } from 'react';
import { Form as AntdForm, Row } from 'antd';
import { FormProps } from 'antd/lib/form/index';
import { DefaultOptionType } from 'antd/lib/select';
import Input from '../Input';
import { FormFields } from './types';

type CustomFormProps = FormProps & {
  formFields: FormFields[];
  isCreationForm: boolean;
  isHorizontal?: boolean;
};

const Form: FC<CustomFormProps> = (props: CustomFormProps): ReactElement => {
  const { formFields, onFinish, isCreationForm, isHorizontal, form, ...otherProps } =
    props;
  const formItemLayout = {
    labelCol: {
      xs: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 18 },
    },
  };

  const mappedFormFields = formFields.map((formField) => (
    <AntdForm.Item key={formField.name} {...formField}>
      <Input
        key={formField.name}
        type={formField.type}
        options={formField.options as DefaultOptionType[]}
        initialValue={formField.initialValue}
        variableName={formField.name}
        formInstance={form}
        uploadelement={formField.uploadelement}
        mode={formField.mode}
      />
    </AntdForm.Item>
  ));

  return (
    <AntdForm
      {...formItemLayout}
      form={form}
      onFinish={onFinish}
      onError={() => {}}
      {...otherProps}
    >
      {isHorizontal ? (
        <Row justify="space-evenly">{mappedFormFields}</Row>
      ) : (
        mappedFormFields
      )}
    </AntdForm>
  );
};

Form.defaultProps = {
  isHorizontal: false,
};

export default Form;
