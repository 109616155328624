import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import type { MenuProps } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { UserAuth } from 'auth/types';
import { UserRole } from 'apollo/types';
import styles from './Sidebar.module.scss';
import adminNavigationTree from '../utils/adminNavigationTree';
import gateKeeperNavigationTree from '../utils/gateKeeperNavigationTree';
import { NavigationItem } from '../utils/types';

const Sidebar = () => {
  const [currentKey, setCurrentKey] = useState<string>('');
  const [userRole, setUserRole] = useState<string>();
  const [navigationTree, setNavigationTree] = useState<NavigationItem[]>([]);

  const history = useHistory();
  const location = useLocation();

  const onClickHandler: MenuProps['onClick'] = (e): void => {
    history.push(`/${e.keyPath[0]}`);
  };

  const getCurrentKey = (): string => {
    return location.pathname.substring(1);
  };

  const getLocalStorageUserRole = () => {
    const userAuth = localStorage.getItem('UserAuth');
    if (userAuth) {
      const jsonUserAuth = JSON.parse(userAuth) as UserAuth;
      setUserRole(jsonUserAuth.userRole);
    }
  };

  const setNavigationTreeFromRole = (): void => {
    if (userRole === UserRole.Admin) {
      setNavigationTree(adminNavigationTree);
    } else {
      setNavigationTree(gateKeeperNavigationTree);
    }
  };

  useEffect(() => {
    setCurrentKey(getCurrentKey());
    getLocalStorageUserRole();
  }, []);

  useEffect(() => {
    setNavigationTreeFromRole();
  }, [userRole]);

  return (
    <div className={styles.Container}>
      <Menu
        theme="dark"
        onClick={onClickHandler}
        mode="inline"
        className={styles.Menu}
        inlineCollapsed={false}
        items={navigationTree}
        selectedKeys={[currentKey]}
      />
    </div>
  );
};

export default Sidebar;
