import React, { FC, useState } from 'react';
import { Col, Modal, Popconfirm, Row } from 'antd';
import { Button, ImagePreviewGroup, Switch, Table } from 'components/UI';
import * as texts from 'assets/texts/newsPostConfiguration';
import { NewsPost, NewsPostImage } from 'apollo/types';
import { Form } from 'components/views/NewsPostConfiguration/Form';
import useNewsPostConfigurationViewModel from './newsPostConfiguration.viewModel';
import columns from './utils/createColumns';
import dataToTable from './utils/dataToTable';
import styles from './NewsPostConfiguration.module.scss';

const NewsPostConfiguration: FC = () => {
  const {
    onFeaturedChange,
    onStatusChange,
    listNewsPostsData,
    listNewsPostsLoading,
    editNewsPostLoading,
    deleteNewsPostLoading,
    onDeleteNewsPost,
    onPaginationChange,
    onCreateModalButtonClicked,
    onEditModalButtonClicked,
    onCloseModal,
    onModalFinished,
    modalInitialValues,
  } = useNewsPostConfigurationViewModel();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalType, setModalType] = useState<string>('create');

  const editButton = (newsPost: NewsPost) => {
    return (
      <Button
        type="default"
        text={texts.edit}
        onClick={() =>
          onEditModalButtonClicked(setIsModalVisible, setModalType, newsPost)
        }
      />
    );
  };

  const deleteButton = (newsPost: NewsPost) => {
    const { id } = newsPost;
    return (
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      <Popconfirm title={texts.deleteConfirm} onConfirm={() => onDeleteNewsPost(id)}>
        <Button type="default" text={texts.deleteText} />
      </Popconfirm>
    );
  };

  const renderFeaturedColumn = (value: boolean, record: unknown) => {
    const newsPostId = (record as NewsPost).id;
    return (
      <Switch
        defaultChecked={value}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onChange={(checked: boolean) => onFeaturedChange(newsPostId, checked)}
      />
    );
  };

  const renderStatusColumn = (value: boolean, record: unknown) => {
    const newsPostId = (record as NewsPost).id;
    return (
      <Switch
        defaultChecked={value}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onChange={(checked: boolean) => onStatusChange(newsPostId, checked)}
      />
    );
  };

  const renderImagesGroup = (images: NewsPostImage[]) => {
    if (images.length > 0) {
      const urls = images.map((image) => image.url || undefined);
      return <ImagePreviewGroup images={urls} maxImagesToShow={2} widthImages={50} />;
    }
    return <></>;
  };

  const renderActionColumns = (item: NewsPost) => {
    return (
      <Row justify="center">
        <Col>
          <Row className={styles.ButtonContainer}>
            <Col span={24}>{editButton(item)}</Col>
          </Row>
          <Row>
            <Col span={24}>{deleteButton(item)}</Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const formOnCloseModal = () => {
    onCloseModal(setIsModalVisible);
  };

  const renderModalContent = () => {
    if (modalType === 'edit') {
      return (
        <Form
          initialValues={modalInitialValues}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onFormFinished={(values, formInstance) =>
            onModalFinished(values, formInstance, setIsModalVisible, modalType)
          }
          onCloseModal={formOnCloseModal}
        />
      );
    }
    return (
      <Form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onFormFinished={(values, formInstance) =>
          onModalFinished(values, formInstance, setIsModalVisible, modalType)
        }
        onCloseModal={formOnCloseModal}
      />
    );
  };

  const renderModalTitle = () => {
    return (
      <span className={styles.ModalTitle}>
        {modalType === 'create' ? texts.createNewsPost : texts.editNewsPost}
      </span>
    );
  };

  return (
    <>
      <Modal
        destroyOnClose
        className={styles.Modal}
        visible={isModalVisible}
        title={renderModalTitle()}
        onCancel={() => onCloseModal(setIsModalVisible)}
        footer={null}
      >
        {renderModalContent()}
      </Modal>
      <Row>
        <Col>
          <h2 className={styles.Title}>{texts.tableTitle}</h2>
        </Col>
      </Row>
      <Row justify="start">
        <Col>
          <Button
            type="primary"
            text={texts.createNewsPost}
            onClick={() => onCreateModalButtonClicked(setIsModalVisible, setModalType)}
          />
        </Col>
      </Row>
      <Row className={styles.Row}>
        <Col span={24}>
          <Table
            columns={columns(
              renderFeaturedColumn,
              renderStatusColumn,
              renderImagesGroup,
              renderActionColumns
            )}
            dataSource={dataToTable(listNewsPostsData?.listNewsPosts.newsPosts)}
            loading={listNewsPostsLoading || editNewsPostLoading || deleteNewsPostLoading}
            rowKey="name"
            pagination={{
              defaultPageSize: 4,
              total: listNewsPostsData?.listNewsPosts.count,
            }}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onChange={(pagination) =>
              onPaginationChange(pagination.current || 1, pagination.pageSize || 10)
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default NewsPostConfiguration;
