/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
  EntranceImage,
  NewsPostImage,
  PackImage,
  PlaceImage,
  ProgramImage,
  ThingToDoImage,
} from 'apollo/types';
import { Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import type { UploadFile } from 'antd/es/upload/interface';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import mapImages from './utils/mapImages';

type Props = {
  images: (
    | EntranceImage
    | PlaceImage
    | ThingToDoImage
    | ProgramImage
    | NewsPostImage
    | PackImage
  )[];
  parentEntityId: bigint;
  uploadedImageId: bigint | undefined;
  uploadUrl: string | undefined;
  loading: boolean;
  isMain?: boolean;
  maxNumberOfImages?: number;
  uploadCallback: (args: any) => Promise<void>;
  deleteCallback: (args: any) => Promise<void>;
  refetchImagesCallback?: () => Promise<void>;
  refetchOnDelete?: boolean;
};

const UploadImages: React.FC<Props> = (props: Props) => {
  const {
    images,
    parentEntityId,
    uploadedImageId,
    uploadUrl,
    loading,
    isMain,
    maxNumberOfImages,
    uploadCallback,
    deleteCallback,
    refetchImagesCallback,
    refetchOnDelete,
  } = props;
  const [headers, setHeaders] = useState<Record<string, string>>();
  const [fileList, setFileList] = useState<UploadFile[]>(mapImages(images));
  let maxCount;
  if (!maxNumberOfImages) {
    maxCount = isMain ? 1 : 10;
  } else {
    maxCount = maxNumberOfImages;
  }

  const refetchImages = async () => {
    if (refetchImagesCallback) {
      await refetchImagesCallback();
    }
  };

  const handleBeforeUpload = async ({ type, uid }: UploadFile): Promise<void> => {
    setHeaders({ 'Content-Type': type || '' });
    const imageId = images.find((img) => img.id === uid)?.id;
    await uploadCallback({
      id: imageId,
      parentEntityId,
      contentType: type || '',
      isMain,
    });
  };

  const handleImageDelete = async (uid: bigint) => {
    await deleteCallback({
      id: uid,
      parentEntityId,
      isMain,
    });
    if (refetchOnDelete) {
      await refetchImages();
    }
  };

  const handleImageUpload = ({ file, onError, onSuccess }: UploadRequestOption) => {
    if (!uploadUrl) return;
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', uploadUrl);
    xhr.onreadystatechange = async () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          if (onSuccess) {
            onSuccess(file);
            await refetchImages();
          }
        } else if (onError) {
          if (uploadedImageId) {
            await handleImageDelete(uploadedImageId);
          }
          await refetchImages();
        }
      }
    };
    xhr.send(file);
  };

  useEffect(() => {
    setFileList(mapImages(images));
  }, [images]);

  return (
    <Upload
      action={uploadUrl}
      method="put"
      accept=".jpg,.jpeg,.png"
      headers={headers}
      beforeUpload={(args) => handleBeforeUpload(args)}
      maxCount={maxCount}
      customRequest={handleImageUpload}
      listType="picture-card"
      disabled={loading}
      fileList={fileList}
      onRemove={({ uid }) => handleImageDelete(BigInt(uid))}
    >
      {images.length < maxCount && (
        <>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Upload</div>
        </>
      )}
    </Upload>
  );
};

UploadImages.defaultProps = {
  isMain: false,
  maxNumberOfImages: undefined,
  refetchImagesCallback: undefined,
  refetchOnDelete: true,
};

export default UploadImages;
