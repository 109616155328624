import { useMutation } from '@apollo/client';
import { MutationCreatePlaceArgs, Place } from 'apollo/types';
import { gql } from 'graphql.macro';

const CREATE_PLACE = gql`
  mutation createPlace($input: CreatePlaceInput!) {
    createPlace(input: $input) {
      mainDescription
      name
      placeMainImage
      secondaryDescription
      id
    }
  }
`;

const useCreatePlaceMutation = () => {
  return useMutation<{ createPlace: Place }, MutationCreatePlaceArgs>(CREATE_PLACE, {});
};

export default useCreatePlaceMutation;
