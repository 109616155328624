import { gql, useMutation } from '@apollo/client';
import { MutationCreateNewsPostArgs, NewsPost } from 'apollo/generated/types';

const CREATE_NEWS_POST = gql`
  mutation createNewsPost($input: CreateNewsPostInput!) {
    createNewsPost(input: $input) {
      id
    }
  }
`;

const useCreateNewsPostMutation = () => {
  return useMutation<{ createNewsPost: NewsPost }, MutationCreateNewsPostArgs>(
    CREATE_NEWS_POST,
    {}
  );
};
export default useCreateNewsPostMutation;
