import React, { FC, ReactElement, useEffect } from 'react';
import { Select as AntdSelect, FormInstance } from 'antd';
import { SelectProps } from 'antd/lib/select/index';

type CustomSelectProps = SelectProps & {
  initialValue: { label: string; value: unknown }[];
  formInstance?: FormInstance;
  variableName: string;
};

const Select: FC<CustomSelectProps> = (props: CustomSelectProps): ReactElement => {
  const { initialValue, formInstance, variableName, options, ...otherProps } = props;

  useEffect(() => {
    const values = initialValue?.map((initial) => initial.value);
    formInstance?.setFieldsValue({ [variableName]: values });
  }, [initialValue]);

  return <AntdSelect options={options} {...otherProps} />;
};
Select.defaultProps = {
  formInstance: undefined,
};

export default Select;
