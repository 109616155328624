import { gql, useMutation } from '@apollo/client';
import { ValidatePurchaseInput } from 'apollo/types';

const VALDIDATE_PURCHASE = gql`
  mutation validatePurchase($purchaseId: ID!, $userId: ID!) {
    validatePurchase(input: { purchaseId: $purchaseId, userId: $userId }) {
      success
    }
  }
`;

const useValidatePurchaseMutation = (purchaseId: string, userId: string) => {
  return useMutation<{ validatePurchase: { success: boolean } }, ValidatePurchaseInput>(
    VALDIDATE_PURCHASE,
    {
      variables: { purchaseId, userId },
    }
  );
};

export default useValidatePurchaseMutation;
