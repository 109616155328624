import { gql, useLazyQuery } from '@apollo/client';
import {
  ListClientsBackofficeResponse,
  QueryListClientsBackofficeArgs,
} from 'apollo/generated/types';

const LIST_CLIENTS_BACKOFFICE = gql`
  query listClientsBackoffice($input: ListClientsBackofficeInput!) {
    listClientsBackoffice(input: $input) {
      clients {
        phone
        purchasesCount
        lastName
        frequentVisitor
        id
        firstName
        email
        entranceTicketsCount
        documentNumber
        documentIsPassport
        activityTicketsCount
      }
      count
    }
  }
`;

const useListClientsBackofficeLazyQuery = () => {
  return useLazyQuery<
    { listClientsBackoffice: ListClientsBackofficeResponse },
    QueryListClientsBackofficeArgs
  >(LIST_CLIENTS_BACKOFFICE, {});
};
export default useListClientsBackofficeLazyQuery;
