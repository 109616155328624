import React, { FC, ReactElement } from 'react';
import { Modal as AntdModal } from 'antd';
import { ModalProps } from 'antd/lib/modal/index';

type CustomModalProps = ModalProps & {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const Modal: FC<CustomModalProps> = (props: CustomModalProps): ReactElement => {
  const { isModalVisible, setIsModalVisible, onOk, onCancel, ...otherProps } = props;

  return (
    <AntdModal
      visible={isModalVisible}
      onCancel={onCancel || (() => setIsModalVisible(false))}
      onOk={onOk || (() => setIsModalVisible(false))}
      closable={false}
      width={900}
      destroyOnClose
      {...otherProps}
    />
  );
};

export default Modal;
