import { EntranceTicketTypeBackOffice } from 'apollo/types';

const dataToTable = (data: EntranceTicketTypeBackOffice[] | undefined) => {
  if (!data) return [];
  const mappedEntrances = data?.map((entranceTicketType, index) => {
    const {
      id,
      name,
      adultValue,
      otherValue,
      maxStock,
      status,
      entranceImages,
      isBicycle,
      visibility,
    } = entranceTicketType;
    return {
      key: index,
      id,
      name,
      adultValue,
      otherValue,
      maxStock,
      status,
      entranceImages,
      isBicycle,
      visibility,
      actions: entranceTicketType,
    };
  });
  return mappedEntrances;
};

export default dataToTable;
