import React, { FC } from 'react';
import { Switch as AntdSwitch } from 'antd';
import { SwitchProps } from 'antd/lib/switch';
import { FetchResults } from './types';

type Props = SwitchProps & {
  onClick?: () => Promise<FetchResults> | void;
};

const Switch: FC<Props> = (props: Props) => {
  const { onClick, ...otherProps } = props;
  /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
  return <AntdSwitch {...otherProps} onClick={onClick} />;
};

Switch.defaultProps = {
  onClick: undefined,
};

export default Switch;
