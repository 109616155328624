import React, { FC, ReactElement } from 'react';
import { Calendar as AntdCalendar, ConfigProvider } from 'antd';
import { CalendarProps } from 'antd/lib/calendar/index';
import { Moment } from 'moment';
import en_GB from 'antd/lib/locale-provider/en_GB';
import 'moment/locale/en-gb';

type Props = CalendarProps<Moment>;

const Calendar: FC<Props> = (props: Props): ReactElement => {
  const { ...otherProps } = props;

  return (
    // ConfigProvider is for start the calendar on monday
    <ConfigProvider locale={en_GB}>
      <AntdCalendar {...otherProps} />
    </ConfigProvider>
  );
};

export default Calendar;
