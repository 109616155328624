import { EnrichedPurchasePackPurchases } from 'apollo/types';
import Table from 'components/UI/Table';
import React, { FC } from 'react';
import columns from './utils/columns';
import styles from '../OrderDetail.module.scss';
import dataToTable from './utils/dataToTable';

export interface PackTableProps {
  purchaseId: string;
  packPurchases: EnrichedPurchasePackPurchases[] | undefined;
  visitDate: string | undefined;
}

const PacksTable: FC<PackTableProps> = ({
  purchaseId,
  packPurchases,
  visitDate,
}: PackTableProps) => {
  const isTicketInfoAvailable = packPurchases && purchaseId !== undefined;

  return (
    <Table
      title={() => 'Packs'}
      columns={columns}
      size="large"
      pagination={false}
      className={!isTicketInfoAvailable ? styles.Disabled : ''}
      dataSource={dataToTable(packPurchases || [], visitDate || '')}
    />
  );
};

export default PacksTable;
