import { gql, useMutation } from '@apollo/client';
import { MutationEditPromotionCodeArgs, PromotionCode } from 'apollo/types';

const EDIT_PROMOTION_CODE = gql`
  mutation editPromotionCode($input: EditPromotionCodeInput!) {
    editPromotionCode(input: $input) {
      forWeekends
      status
    }
  }
`;

const useMutationEditPromotionCode = () => {
  return useMutation<{ editPromotionCode: PromotionCode }, MutationEditPromotionCodeArgs>(
    EDIT_PROMOTION_CODE,
    {}
  );
};

export default useMutationEditPromotionCode;
