import { Client } from 'apollo/types';

const dataToTable = (data: Client[] | undefined) => {
  if (!data) return [];
  const mappedClients = data?.map((client, index) => {
    const { firstName, lastName, documentNumber, email, phone } = client;
    return {
      key: index,
      fullName: `${firstName} ${lastName}`,
      documentNumber,
      email,
      phone,
    };
  });
  return mappedClients;
};

export default dataToTable;
