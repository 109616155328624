import React, { FC, ReactElement, useCallback, useState, useEffect, Key } from 'react';
import { Table } from 'antd';
import { TableProps } from 'antd/lib/table/index';
import { EntranceTicketsGrouped, ExperienceTickets, Grills } from 'apollo/types';
import mapGroupedEntranceTickets from './utils/groupedEntranceTickets';
import mapGroupedActivityTickets from './utils/groupedActivityTickets';
import { GroupedEntranceTicketsRow, GroupedActivityTicketsRow } from './types';
import {
  mapEntranceTicketsSubTable,
  mapExperienceTicketsSubTable,
  mapGrillsSubTable,
  getColumns,
} from './utils/groupedTicketsSubTables';

type Props = TableProps<object> & {
  currentDate: string;
  entrances?: EntranceTicketsGrouped[];
  experiences?: ExperienceTickets[];
  grills?: Grills;
};

const CollapsibleTable: FC<Props> = (props: Props): ReactElement => {
  const { currentDate, entrances, experiences, grills, ...otherProps } = props;

  const [currentlyExpandedKey, setCurrentlyExpandedKey] = useState<Key[]>([]);
  const [currentExpanded, setCurrentExpanded] = useState<
    GroupedEntranceTicketsRow | GroupedActivityTicketsRow | null
  >(null);

  const entranceData = mapGroupedEntranceTickets(entrances);
  const experienceData = mapGroupedActivityTickets(experiences, grills);

  const data = [...entranceData, ...experienceData];

  useEffect(() => {
    setCurrentExpanded(null);
  }, [currentDate]);

  const handleExpandRow = (keys: readonly Key[]) => {
    const currentKey = keys.slice(-1);
    setCurrentlyExpandedKey(currentKey);
  };

  const expandedRowRender = useCallback(() => {
    const getDataSource = (
      row: GroupedEntranceTicketsRow | GroupedActivityTicketsRow
    ) => {
      if ('adultTickets' in row) {
        return mapEntranceTicketsSubTable(row);
      }
      if (row.type === 'experience') {
        return mapExperienceTicketsSubTable(row);
      }
      return mapGrillsSubTable(row);
    };
    return (
      <>
        {currentExpanded && (
          <Table
            pagination={false}
            columns={getColumns(currentExpanded)}
            dataSource={getDataSource(currentExpanded) as readonly object[]}
          />
        )}
      </>
    );
  }, [currentExpanded]);

  return (
    <Table
      {...otherProps}
      expandable={{
        expandedRowRender,
        onExpand: (_index, record) =>
          setCurrentExpanded(
            record as GroupedEntranceTicketsRow | GroupedActivityTicketsRow
          ),
        onExpandedRowsChange: handleExpandRow,
        expandedRowKeys: currentlyExpandedKey,
      }}
      scroll={{ y: 245 }}
      dataSource={data}
      pagination={false}
    />
  );
};

export default CollapsibleTable;

CollapsibleTable.defaultProps = {
  entrances: [],
  experiences: [],
  grills: undefined,
};
