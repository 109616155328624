import {
  ActivityColumn,
  GrillConfigurationEditMutation,
  GrillFetchResult,
} from '../types';

const statusOnChange = async (
  item: ActivityColumn | undefined,
  editMutation: GrillConfigurationEditMutation
): Promise<GrillFetchResult> => {
  const id = item?.actions.id || '';
  const toEdit = { id, status: !item?.status };

  const edited = await editMutation({
    variables: { input: toEdit },
  });
  return edited;
};

export default statusOnChange;
