import { FormInstance } from 'antd';

const handleCancel = (
  form: FormInstance,
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
) => {
  form.resetFields();
  setIsModalVisible(false);
};

export default handleCancel;
