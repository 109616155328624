/* eslint-disable @typescript-eslint/no-explicit-any */
const columns = (
  discountPercentage: (id: string, type: any) => JSX.Element,
  maxTicketQuantity: (id: string, type: any) => JSX.Element
) => {
  return [
    {
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Porcentaje de descuento',
      dataIndex: 'discountPercentageForm',
      key: 'discountPercentageForm',
      render: discountPercentage,
    },
    {
      title: 'Cantidad máxima de tickets',
      dataIndex: 'maxTicketsform',
      key: 'maxTicketsform',
      render: maxTicketQuantity,
    },
  ];
};

export default columns;
