import { gql, useMutation } from '@apollo/client';
import { EntranceImage, MutationDeleteEntranceImageArgs } from 'apollo/generated/types';

const DELETE_ENTRANCE_IMAGE = gql`
  mutation deleteEntranceImage($input: DeleteEntranceImageInput!) {
    deleteEntranceImage(input: $input) {
      id
    }
  }
`;

const useDeleteEntranceImageMutation = () => {
  return useMutation<
    { deleteEntranceImage: EntranceImage },
    MutationDeleteEntranceImageArgs
  >(DELETE_ENTRANCE_IMAGE);
};
export default useDeleteEntranceImageMutation;
