import React, { FC, useState } from 'react';
import { Collapse, FormInstance, message } from 'antd';
import EditableTable from 'components/sharedComponents/EditableTable';
import { Button } from 'components/UI';
import * as texts from 'assets/texts/activityConfiguration';
import { ActivityTime } from 'apollo/types';
import columns from './utils/createColumns';
import dataToTable from './utils/dataToTable';
import updateDataSource from './utils/updateDataSource';
import deleteFromDataSource from './utils/deleteFromDataSource';
import validateActivityTime from './utils/validateActivityTime';
import { ActivityTimeRow } from '../types';

type ActivityTimesFormProps = {
  initialValue: ActivityTime[];
  variableName: string;
  formInstance?: FormInstance;
};

const { Panel } = Collapse;

const ActivityTimesForm: FC<ActivityTimesFormProps> = (props: ActivityTimesFormProps) => {
  const { initialValue, variableName, formInstance } = props;

  const [editingKey, setEditingKey] = useState<number | null>(null);
  const [count, setCount] = useState<number>(initialValue.length * 2);
  const [winterDataSource, setWinterDataSource] = useState<ActivityTimeRow[]>(
    dataToTable('2', initialValue)
  );
  const [summerDataSource, setSummerDataSource] = useState<ActivityTimeRow[]>(
    dataToTable('1', initialValue)
  );

  const blankRow: ActivityTimeRow = {
    key: count + 1,
    status: false,
    parkAvailabilityId: '',
  };

  const addTableRow = (parkAvailabilityId: string) => {
    if (parkAvailabilityId === '1') {
      setSummerDataSource([...summerDataSource, { ...blankRow, parkAvailabilityId }]);
    } else {
      setWinterDataSource([...winterDataSource, { ...blankRow, parkAvailabilityId }]);
    }
    setEditingKey(count + 1);
    setCount(count + 1);
  };

  const isEditing = (record: ActivityTimeRow) => record.key === editingKey;

  const save = async (record: ActivityTimeRow) => {
    await formInstance?.validateFields(['startHour', 'endHour']);
    if (validateActivityTime(record)) {
      setEditingKey(null);
      if (record.parkAvailabilityId === '1') {
        updateDataSource(record, summerDataSource, setSummerDataSource);
      } else {
        updateDataSource(record, winterDataSource, setWinterDataSource);
      }
      formInstance?.setFieldsValue({
        [variableName]: [...winterDataSource, ...summerDataSource],
      });
    } else {
      await message.error(texts.activityTimeFormError);
    }
  };

  const edit = (record: ActivityTimeRow) => {
    formInstance?.setFieldsValue({ ...blankRow, ...record });
    setEditingKey(record.key);
  };

  const cancel = (record: ActivityTimeRow) => {
    if (!validateActivityTime(record)) {
      if (record.parkAvailabilityId === '1') {
        deleteFromDataSource(record, summerDataSource, setSummerDataSource);
      } else {
        deleteFromDataSource(record, winterDataSource, setWinterDataSource);
      }
    }
    setEditingKey(null);
  };

  const actions = (_: string, record: ActivityTimeRow): JSX.Element => {
    const editable = isEditing(record);
    return editable ? (
      <span>
        <Button
          onClick={() => save(record)}
          style={{ marginRight: 8 }}
          text={texts.saveButton}
          type="primary"
        />
        <Button onClick={() => cancel(record)} text={texts.cancelButton} type="primary" />
      </span>
    ) : (
      <span>
        <Button type="primary" onClick={() => edit(record)} text={texts.editButton} />
      </span>
    );
  };

  return (
    <Collapse accordion>
      <Panel header={texts.summerSpringTimes} key="1">
        <Button
          type="primary"
          text={texts.addActivityTime}
          onClick={() => addTableRow('1')}
          disabled={editingKey !== null}
        />
        <EditableTable
          columns={columns(actions, isEditing)}
          dataSource={summerDataSource}
        />
      </Panel>
      <Panel header={texts.autumnWinterTimes} key="2">
        <Button
          type="primary"
          text={texts.addActivityTime}
          onClick={() => addTableRow('2')}
          disabled={editingKey !== null}
        />
        <EditableTable
          columns={columns(actions, isEditing)}
          dataSource={winterDataSource}
        />
      </Panel>
    </Collapse>
  );
};

export default ActivityTimesForm;

ActivityTimesForm.defaultProps = {
  formInstance: undefined,
};
