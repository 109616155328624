import { gql, useMutation } from '@apollo/client';
import {
  ThingToDoImage,
  MutationDeleteThingsToDoImagesArgs,
} from 'apollo/generated/types';

const DELETE_THINGS_TODO_IMAGE = gql`
  mutation deleteThingsToDoImages($input: DeleteThingToDoImageInput!) {
    deleteThingsToDoImages(input: $input) {
      id
    }
  }
`;

const useDeleteThingsToDoImageMutation = () => {
  return useMutation<
    { deleteThingsToDoImages: ThingToDoImage },
    MutationDeleteThingsToDoImagesArgs
  >(DELETE_THINGS_TODO_IMAGE, {});
};
export default useDeleteThingsToDoImageMutation;
