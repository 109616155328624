import { gql, useLazyQuery } from '@apollo/client';
import { Client } from 'apollo/types';

const LIST_CLIENTS = gql`
  query listClientsMailerBackoffice(
    $startDate: String!
    $endDate: String!
    $listEntranceTickets: [String!]
  ) {
    listClientsMailerBackoffice(
      startDate: $startDate
      endDate: $endDate
      listEntranceTickets: $listEntranceTickets
    ) {
      email
      firstName
      lastName
      phone
      documentNumber
      documentIsPassport
    }
  }
`;

const useLazyListClientsQuery = () => {
  return useLazyQuery<{ listClientsMailerBackoffice: Client[] }>(LIST_CLIENTS, {
    variables: {},
  });
};

export default useLazyListClientsQuery;
