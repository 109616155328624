import Searcher from 'components/views/Purchases/Searcher';
import React, { FC, useState, useEffect, Key } from 'react';
import { Col, Form, message, Pagination, Popover, Row, Tag } from 'antd';
import Table from 'components/UI/Table';
import {
  DisablePurchaseHistory,
  Filter,
  FilterType,
  TicketPurchase,
  UserRole,
} from 'apollo/types';
import dataToTable from 'components/views/Purchases/Searcher/utils/dataToTable';
import * as texts from 'assets/texts/reservation';
import useListPurchasesLazyQuery from 'apollo/resolvers/purchases/validations';
import uselistTicketTypesQuery from 'apollo/resolvers/purchases/ticketTypes';
import { SearcherInput } from 'components/views/Purchases/Searcher/types';
import { UserAuth } from 'auth/types';
import Button from 'components/UI/Button';
import useDisablePurchasesMutation from 'apollo/resolvers/purchases/disable';
import { InfoCircleOutlined } from '@ant-design/icons';
import { DetailModal } from 'components/views/Purchases/DetailModal';
import styles from './Reservation.module.scss';
import createInputs from './utils/createInputs';
import createColumns from './utils/createColumns';
import ticketTypesMapper from './utils/ticketTypesMapper';
import mapPurchasesForDisable from './utils/mapPurchasesForDisable';

const Reservation: FC = () => {
  const userAuth = localStorage.getItem('UserAuth');
  const jsonUserAuth = userAuth ? (JSON.parse(userAuth) as UserAuth) : null;
  const userId = jsonUserAuth ? jsonUserAuth.databaseId : '';
  const userRole = jsonUserAuth ? jsonUserAuth.userRole : '';
  const databaseId = userId ? userId.toString() : '';

  const [date] = useState(new Date().toISOString());
  const [form] = Form.useForm();
  const [filters, setFilters] = useState<Filter[]>([
    { filterType: FilterType.Date, filter: date },
  ]);
  const validReservations = true;

  const [isDetailModalVisible, setIsDetailModalVisible] = useState<boolean>(false);
  const [selectedPurchaseDetail, setSelectedPurchaseDetail] = useState<TicketPurchase>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentPageSize, setCurrentPageSize] = useState<number>(10);
  const [count, setCount] = useState<number>();
  const [isAdmin, setAdmin] = useState<boolean>(false);
  const [purchases, setPurchases] = useState<TicketPurchase[] | undefined>(undefined);
  const [listPurchases, { loading }] = useListPurchasesLazyQuery(
    databaseId,
    filters,
    validReservations,
    currentPage,
    currentPageSize
  );
  const [inputs, setInputs] = useState<SearcherInput[]>(createInputs(date));
  const [selectedPurchaseForDisable, setSelectedPurchaseForDisable] = useState<Key[]>([]);
  const [disablePurchases, { loading: disableLoading }] = useDisablePurchasesMutation();
  const ticketTypesQuery = uselistTicketTypesQuery();
  const ticketTypesQueryData = ticketTypesQuery.data;
  const dataSource = dataToTable(purchases);
  const keySelector = 'orderNumber';

  const checkUserRole = () => {
    if (userRole === UserRole.Admin) {
      setAdmin(true);
    } else {
      setAdmin(false);
    }
  };

  const getPurchases = async () => {
    return listPurchases({
      variables: {
        userId: databaseId,
        filters,
        validReservations,
        page: currentPage,
        pageSize: currentPageSize,
      },
    });
  };

  const updateTable = async () => {
    const response = await getPurchases();
    setCount(response.data?.listPurchases.count);
    setPurchases(response.data?.listPurchases.purchases);
    setSelectedPurchaseForDisable([]);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    updateTable();
  }, [currentPage, currentPageSize]);

  const updateTableAndPage = async () => {
    setPurchases([]);
    await updateTable();
    setCurrentPage(1);
  };

  const onDisableButtonClick = async () => {
    const response = await disablePurchases({
      variables: {
        input: {
          purchaseIds: mapPurchasesForDisable(selectedPurchaseForDisable),
          userId: databaseId,
        },
      },
    });
    setSelectedPurchaseForDisable([]);
    await updateTableAndPage();
    if (response.data?.disablePurchasesBackoffice.success === true) {
      await message.success(texts.disablePurchases);
    } else {
      await message.error(texts.errorDisablePurchases);
    }
  };

  const customizedSelect = (selectedKey: React.Key) => {
    if (dataSource && selectedKey) {
      const [selectedKeySelector] = selectedKey.toString().split(' ');
      const formattedDataSource = dataSource as unknown as {
        [keySelector]: string;
        key: Key;
      }[];
      const sameKeyData = formattedDataSource.filter(
        (data) => data[keySelector] === selectedKeySelector
      );
      const keys = sameKeyData.map((data) => data.key);
      setSelectedPurchaseForDisable(keys);
    }
  };

  const renderIsDisabledColumn = (disabledPurchaseHistory: DisablePurchaseHistory) => {
    if (disabledPurchaseHistory) {
      const firstName = disabledPurchaseHistory.userFirstName as string;
      const lastName = disabledPurchaseHistory.userLastName as string;
      const email = disabledPurchaseHistory.userEmail as string;
      const disabledDate = disabledPurchaseHistory.createdAt as string;
      const title = `${texts.disabledBy}:`;
      const content = (
        <>
          <Row>
            <Col span={24}>{`${texts.name}: ${firstName} ${lastName}`}</Col>
          </Row>
          <Row>
            <Col span={24}>{`${texts.email}: ${email}`}</Col>
          </Row>
          <Row>
            <Col span={24}>{`${texts.inTheDate}: ${disabledDate}`}</Col>
          </Row>
        </>
      );
      return (
        <Row justify="center" align="middle">
          <Col span={20}>
            <Row justify="center">
              <Col span={24}>
                <Tag color="red">{texts.disabled}</Tag>
              </Col>
            </Row>
          </Col>
          <Col span={4}>
            <Popover title={title} content={content}>
              <InfoCircleOutlined />
            </Popover>
          </Col>
        </Row>
      );
    }
    return (
      <Row justify="center">
        <Col span={24}>
          <Tag color="green">{texts.enabled}</Tag>
        </Col>
      </Row>
    );
  };

  const selectPurchaseDetail = (purchase: TicketPurchase) => {
    setSelectedPurchaseDetail(purchase);
    setIsDetailModalVisible(true);
  };

  const renderPurchaseDetailButton = (purchase: TicketPurchase) => {
    return (
      <Button
        type="primary"
        onClick={() => selectPurchaseDetail(purchase)}
        className={styles.Button}
        text={texts.detail}
      />
    );
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    updateTableAndPage();
  }, [filters]);

  useEffect(() => {
    if (ticketTypesQueryData) {
      const inputsCopy = [...inputs];
      inputsCopy[3].options = ticketTypesMapper(ticketTypesQueryData);
      setInputs(inputsCopy);
    }
  }, [ticketTypesQueryData]);

  useEffect(() => {
    checkUserRole();
  }, [userRole]);

  const columns = createColumns(renderIsDisabledColumn, renderPurchaseDetailButton);

  return (
    <>
      {selectedPurchaseDetail && (
        <DetailModal
          visible={isDetailModalVisible}
          onCancel={() => setIsDetailModalVisible(false)}
          purchaseDetail={selectedPurchaseDetail}
        />
      )}
      <h2 className={styles.Title}>{texts.title}</h2>
      <Searcher inputs={inputs} form={form} filterSetter={setFilters} />
      <Row className={styles.DisableContainer}>
        <Col span={24}>
          <Row justify="space-between">
            <Col span={4}>
              <h2 className={styles.Title}>{texts.details}</h2>
            </Col>
            {isAdmin && selectedPurchaseForDisable.length > 0 && (
              <Col span={4}>
                <Button
                  type="primary"
                  shape="round"
                  text={texts.disablePurchases}
                  loading={disableLoading}
                  onClick={onDisableButtonClick}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        pagination={false}
        selectedRowKeys={isAdmin ? selectedPurchaseForDisable : undefined}
        setSelectedRowKeys={isAdmin ? setSelectedPurchaseForDisable : undefined}
        customizedSelect={customizedSelect}
      />
      <Pagination
        className={styles.Pagination}
        total={count}
        showSizeChanger
        pageSize={currentPageSize}
        onChange={(page, pageSize) => {
          setCurrentPage(page);
          setCurrentPageSize(pageSize);
        }}
        current={currentPage}
      />
    </>
  );
};

export default Reservation;
