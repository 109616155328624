import { ActivityTimeRow } from '../../types';

const columns = (
  editButton: (_: string, record: ActivityTimeRow) => JSX.Element,
  isEditing: (record: ActivityTimeRow) => boolean
) => [
  {
    title: 'Hora inicio',
    dataIndex: 'startHour',
    key: 'startHour',
    onCell: (record: ActivityTimeRow) => ({
      inputType: 'select',
      title: 'Hora inicio',
      dataIndex: 'startHour',
      editing: isEditing(record),
      record,
    }),
  },
  {
    title: 'Hora término',
    dataIndex: 'endHour',
    key: 'endHour',
    onCell: (record: ActivityTimeRow) => ({
      inputType: 'select',
      title: 'Hora término',
      dataIndex: 'endHour',
      editing: isEditing(record),
      record,
    }),
  },
  {
    title: 'Acciones',
    dataIndex: 'actions',
    key: 'actions',
    render: editButton,
  },
];

export default columns;
