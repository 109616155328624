import { Program, ProgramImage } from 'apollo/types';
import * as texts from 'assets/texts/programConfiguration';

const columns = (
  renderActionColumns: (item: Program) => JSX.Element,
  validForWeekendsColumnRender: (value: boolean, record: unknown) => JSX.Element,
  statusColumnRender: (value: boolean, record: unknown) => JSX.Element,
  renderImagesGroup: (item: ProgramImage[]) => JSX.Element,
  renderVideoLink: (link: string) => JSX.Element
) => {
  return [
    {
      title: texts.name,
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: texts.subtitle,
      dataIndex: 'subtitle',
      key: 'subtitle',
    },
    {
      title: texts.hoursDuration,
      dataIndex: 'hoursDuration',
      key: 'hoursDuration',
    },
    {
      title: texts.targetGroup,
      dataIndex: 'targetGroup',
      key: 'targetGroup',
    },
    {
      title: texts.validForWeekends,
      dataIndex: 'validForWeekends',
      key: 'validForWeekends',
      render: validForWeekendsColumnRender,
    },
    {
      title: texts.status,
      dataIndex: 'status',
      key: 'status',
      render: statusColumnRender,
    },
    {
      title: texts.photos,
      dataIndex: 'programImages',
      key: 'programImages',
      align: 'center' as const,
      render: renderImagesGroup,
    },
    {
      title: texts.videoLink,
      dataIndex: 'videoLink',
      key: 'videoLink',
      render: renderVideoLink,
    },
    {
      title: texts.actions,
      dataIndex: 'actions',
      key: 'actions',
      align: 'center' as const,
      render: renderActionColumns,
    },
  ];
};

export default columns;
