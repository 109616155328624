import useGetNewsBannerQuery from 'apollo/resolvers/newsBanner/get';
import { NewsBanner } from 'apollo/types';
import { LoadingModal } from 'components/UI';
import React, { FC, useEffect, useState } from 'react';
import NewsBannerForm from 'components/views/NewBannerConfiguration/NewsBannerForm';
import * as texts from '../../../assets/texts/newsBanner';
import styles from './NewsBanner.module.scss';

const NewsBannerConfiguration: FC = () => {
  const { data: newsBannerData, loading, refetch } = useGetNewsBannerQuery();

  const [newsBanner, setNewsBanner] = useState<NewsBanner>();
  const [isCreationForm, setIsCreationForm] = useState<boolean>(true);

  const setNewsBannerFromQuery = () => {
    if (newsBannerData && newsBannerData.getNewsBanner) {
      setNewsBanner(newsBannerData.getNewsBanner);
      setIsCreationForm(false);
    }
  };

  useEffect(() => {
    setNewsBannerFromQuery();
  }, [newsBannerData]);

  return (
    <>
      <LoadingModal visible={loading} />
      <h2 className={styles.Title}>{texts.newsBanner}</h2>
      <NewsBannerForm
        isCreationForm={isCreationForm}
        newsBanner={newsBanner}
        refetch={refetch}
      />
    </>
  );
};

export default NewsBannerConfiguration;
