import { Col, Row } from 'antd';
import React, { FC } from 'react';
import * as texts from 'assets/texts/gateValidation';
import { DisablePurchaseHistory } from 'apollo/types';
import styles from './BackOfficeUserInformation.module.scss';

interface BackOfficeInformationProps {
  disabledPurchaseHistory: DisablePurchaseHistory;
}

const BackOfficeInformation: FC<BackOfficeInformationProps> = (
  props: BackOfficeInformationProps
) => {
  const { disabledPurchaseHistory } = props;
  return (
    <>
      <Row className={styles.Text}>
        <Col span={24}>
          <h3 className={styles.Text}>{texts.backOfficeUserInformation}</h3>
          <Row className={styles.Layout} gutter={[8, 24]} justify="space-around">
            <Col className={styles.Column}>
              <b>{texts.name}:</b> {disabledPurchaseHistory.userFirstName}{' '}
              {disabledPurchaseHistory.userLastName}
            </Col>
            <Col className={styles.Column}>
              <b>{texts.mail}:</b> {disabledPurchaseHistory.userEmail}
            </Col>
            <Col className={styles.Column}>
              <b>{texts.disabledDate}:</b> {disabledPurchaseHistory.createdAt}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default BackOfficeInformation;
