import React, { useState, useEffect } from 'react';
import { Col, Row, Tabs } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { UserAuth } from 'auth/types';
import { UserRole } from 'apollo/types';
import styles from './TabBar.module.scss';
import adminNavigationTree from '../utils/adminNavigationTree';
import { Tab } from './types';
import gateKeeperNavigationTree from '../utils/gateKeeperNavigationTree';
import { NavigationItem } from '../utils/types';

const TabBar = () => {
  const [currentKey, setCurrentKey] = useState<string>('');
  const [tabs, setTabs] = useState<Tab[] | null>(null);
  const [userRole, setUserRole] = useState<string>();
  const [navigationTree, setNavigationTree] = useState<NavigationItem[]>([]);

  const history = useHistory();
  const location = useLocation();

  const getLocalStorageUserRole = () => {
    const userAuth = localStorage.getItem('UserAuth');
    if (userAuth) {
      const jsonUserAuth = JSON.parse(userAuth) as UserAuth;
      setUserRole(jsonUserAuth.userRole);
    }
  };

  const setNavigationTreeFromRole = (): void => {
    if (userRole === UserRole.Admin) {
      setNavigationTree(adminNavigationTree);
    } else {
      setNavigationTree(gateKeeperNavigationTree);
    }
  };

  const findTabItemsFromNavigationTree = (): void => {
    if (navigationTree.length === 0) {
      return;
    }
    const currentNavigationSectionName = location.pathname.split('/')[1];
    const filterResult = navigationTree.filter((navigationSection) => {
      return navigationSection.key === currentNavigationSectionName;
    });
    if (filterResult.length > 0) {
      const currentNavigationSection = filterResult[0];
      if (
        currentNavigationSection.children &&
        currentNavigationSection.children.length > 0
      ) {
        setTabs(currentNavigationSection.children);
      }
    }
  };

  const onClickHandler = (key: string) => {
    history.push(`/${key}`);
  };

  const getCurrentKey = (): string => {
    return location.pathname.substring(1);
  };

  useEffect(() => {
    getLocalStorageUserRole();
  }, []);

  useEffect(() => {
    setNavigationTreeFromRole();
  }, [userRole]);

  useEffect(() => {
    findTabItemsFromNavigationTree();
  }, [navigationTree]);

  useEffect(() => {
    setCurrentKey(getCurrentKey());
  }, [tabs]);

  const { TabPane } = Tabs;

  const content = tabs ? (
    <Row className={styles.Container}>
      <Col xs={24}>
        <Tabs activeKey={currentKey} onTabClick={onClickHandler}>
          {tabs.map((tab) => {
            return <TabPane tab={tab.label} key={tab.key} />;
          })}
        </Tabs>
      </Col>
    </Row>
  ) : (
    <></>
  );
  return content;
};

export default TabBar;
