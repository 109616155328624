import { EntranceTicketType } from 'apollo/types';
import { Mode } from 'components/sharedComponents/FormModal/types';

const setEditOrCreateForm = (
  item: EntranceTicketType | undefined,
  setEditItem: React.Dispatch<React.SetStateAction<EntranceTicketType | undefined>>,
  setModalMode: React.Dispatch<React.SetStateAction<Mode | undefined>>,
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
): void => {
  setEditItem(item);
  if (item) {
    setModalMode('edit');
  } else {
    setModalMode('create');
  }
  setIsModalVisible(true);
};

export default setEditOrCreateForm;
