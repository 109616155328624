import {
  PackColumn,
  EntranceConfigurationEditMutation,
  EntranceTicketFetchResult,
} from '../types';

const visibilityOnChange = async (
  item: PackColumn | undefined,
  editMutation: EntranceConfigurationEditMutation,
  refetch: () => Promise<unknown>
): Promise<EntranceTicketFetchResult> => {
  const id = item?.actions.id || '';
  const toEdit = { id, visibility: !item?.visibility, name: item?.name };
  const edited = await editMutation({
    variables: { input: toEdit },
  });
  await refetch();
  return edited;
};

export default visibilityOnChange;
