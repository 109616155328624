import React, { FC, useState, useEffect } from 'react';
import { Row, Col, Tag, message } from 'antd';
import {
  Table,
  ImagePreviewGroup,
  Button,
  UploadImages,
  LoadingModal,
} from 'components/UI';
import FormModal from 'components/sharedComponents/FormModal';
import * as texts from 'assets/texts/placeConfiguration';
import {
  PlaceImage,
  Place,
  CreatePlaceInput,
  ThingToDo,
  EditPlaceInput,
  CreatePlaceThingToDoInput,
} from 'apollo/types';
import useListPlacesQuery from 'apollo/resolvers/place/list';
import useCreatePlaceMutation from 'apollo/resolvers/place/create';
import useEditPlaceMutation from 'apollo/resolvers/place/edit';
import useUploadPlaceImageMutation from 'apollo/resolvers/placeImage/upload';
import useDeletePlaceImageMutation from 'apollo/resolvers/placeImage/delete';
import {
  EditFormValues,
  CreateFormValues,
} from 'components/sharedComponents/FormModal/types';
import useListThingsToDoQuery from 'apollo/resolvers/thingsToDo/list';
import columns from './utils/createColumns';
import styles from './PlaceConfiguration.module.scss';
import placeFormFields from './utils/formFields';
import setEditOrCreateForm from './utils/setEditOrCreateForm';
import dataToTable from './utils/dataToTable';
import formToInput from './utils/formToInput';
import { ThingsToDoOptions } from './types';
import thingsToDoMapper from './utils/thingsToDoMapper';

const PlaceConfiguration: FC = () => {
  const { data, loading, refetch } = useListPlacesQuery();
  const { data: thingsToDoData } = useListThingsToDoQuery();
  const [isLoadingModalVisible, setIsLoadingModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [thingsToDo, setThingsToDo] = useState<ThingsToDoOptions>([]);
  const [formThingsToDo, setFormThingsToDo] = useState<CreatePlaceThingToDoInput[]>([]);
  const [editItem, setEditItem] = useState<Place>();
  const [modalMode, setModalMode] = useState<'edit' | 'create' | undefined>();
  const [editPlaceMutation] = useEditPlaceMutation();
  const [createPlaceMutation] = useCreatePlaceMutation();
  const [uploadImage, { data: uploadImageData, loading: uploadImageLoading }] =
    useUploadPlaceImageMutation();
  const [deleteImage, { loading: deleteImageLoading }] = useDeletePlaceImageMutation();

  useEffect(() => {
    if (thingsToDoData) {
      const mappedThingToDo = thingsToDoMapper(thingsToDoData.listThingsToDo);
      setThingsToDo(mappedThingToDo);
    }
  }, [thingsToDoData]);

  const mappedThingTodo = (
    placeFormValues: CreatePlaceInput | EditPlaceInput,
    dbThingsToDo: ThingToDo[]
  ) => {
    const placeFormThingsToDo: CreatePlaceThingToDoInput[] = [];
    placeFormValues.thingsToDo?.forEach((thing) => {
      const index = thing.id;
      dbThingsToDo.forEach((dbThing) => {
        if (index === dbThing.id) {
          formThingsToDo.push({
            id: dbThing.id,
            name: dbThing.name,
            description: dbThing.description,
          });
        }
      });
    });
    setFormThingsToDo(placeFormThingsToDo);
  };

  const editButton = (item: Place): JSX.Element => (
    <Button
      type="primary"
      text={texts.edit}
      onClick={() =>
        setEditOrCreateForm(item, setEditItem, setModalMode, setIsModalVisible)
      }
    />
  );

  const createImageGroup = (entranceTicketImages: PlaceImage[]): JSX.Element => {
    const images = entranceTicketImages.map((image) => image.image || undefined);
    return <ImagePreviewGroup images={images} maxImagesToShow={2} widthImages={50} />;
  };

  const imageGroup = (item: PlaceImage[] | undefined): JSX.Element => {
    if (item && item.length > 0) {
      return createImageGroup(item);
    }
    return <></>;
  };

  const refetchFunction = async () => {
    await refetch();
  };

  const editMutation = async (formValues: EditFormValues) => {
    setIsModalVisible(false);
    setIsLoadingModalVisible(true);
    const placeFormValues = formValues as EditPlaceInput;
    const listThingsToDo = thingsToDoData?.listThingsToDo as ThingToDo[];

    mappedThingTodo(placeFormValues, listThingsToDo);
    const item = await editPlaceMutation({
      variables: { input: { ...placeFormValues, thingsToDo: formThingsToDo } },
    });

    await refetch();
    setIsLoadingModalVisible(false);
    await message.success('Se ha editado el lugar exitosamente', 0.5);

    return item;
  };

  const createMutation = async (
    formValues: CreateFormValues,
    redirectToAddImages: boolean
  ) => {
    setIsLoadingModalVisible(true);
    const placeFormValues = formValues as CreatePlaceInput;
    const listThingsToDo = thingsToDoData?.listThingsToDo as ThingToDo[];

    mappedThingTodo(placeFormValues, listThingsToDo);

    const item = await createPlaceMutation({
      variables: { input: { ...placeFormValues, thingsToDo: formThingsToDo } },
    });

    if (redirectToAddImages) {
      setEditItem(item.data?.createPlace);
      setEditOrCreateForm(
        item.data?.createPlace,
        setEditItem,
        setModalMode,
        setIsModalVisible
      );
    }

    await refetch();
    setIsModalVisible(false);
    setIsLoadingModalVisible(false);

    await message.success('Se ha creado el lugar exitosamente', 0.5);

    return item;
  };

  const uploadPlaceImage = async (values: {
    id: bigint | undefined;
    parentEntityId: bigint;
    contentType: string;
    isMain: boolean;
  }) => {
    await uploadImage({
      variables: {
        input: {
          id: values.id?.toString(),
          placeId: values.parentEntityId.toString(),
          contentType: values.contentType,
          isMain: values.isMain,
        },
      },
    });
  };

  const deletePlaceImage = async (values: {
    id: bigint;
    parentEntityId: bigint;
    isMain: boolean;
  }) => {
    await deleteImage({
      variables: {
        input: {
          id: values.id.toString(),
          placeId: values.parentEntityId.toString(),
          isMain: values.isMain,
        },
      },
    });
  };

  useEffect(() => {
    if (data?.listPlacesBackOffice) {
      if (editItem) {
        const foundItem = data.listPlacesBackOffice.find(
          (item) => item.id === editItem.id
        );
        if (foundItem) setEditItem(foundItem as unknown as Place);
      }
    }
  }, [data?.listPlacesBackOffice]);

  const uploadElement = (isMain = false) => {
    let images = editItem?.placeImages || [];
    if (images && isMain) {
      images = images.filter((img) => img.image === editItem?.placeMainImage);
    }
    return (
      <UploadImages
        images={images}
        parentEntityId={BigInt(editItem?.id || 0)}
        uploadedImageId={
          uploadImageData?.uploadPlaceImage.id
            ? BigInt(uploadImageData?.uploadPlaceImage.id)
            : undefined
        }
        uploadUrl={uploadImageData?.uploadPlaceImage.uploadUrl || undefined}
        loading={uploadImageLoading || deleteImageLoading}
        isMain={isMain}
        uploadCallback={uploadPlaceImage}
        deleteCallback={deletePlaceImage}
        refetchImagesCallback={refetchFunction}
      />
    );
  };

  const renderTags = (tags: { description: string; id: string; name: string }[]) => {
    return (
      <>
        {tags && (
          <>
            {tags.slice(0, 2).map((tag) => (
              <Tag className={styles.Tag} key={tag.name}>
                {tag.name}
              </Tag>
            ))}
            {tags?.length > 2 && (
              <Tag className={styles.Tag} key="+">
                + {tags.length - 2}
              </Tag>
            )}
          </>
        )}
      </>
    );
  };

  const renderFormFields = () => {
    const editModalMode = modalMode === 'edit';
    if (editModalMode) {
      return placeFormFields(
        false,
        editItem,
        uploadElement(),
        uploadElement(true),
        thingsToDo
      );
    }
    return placeFormFields(true, editItem, undefined, undefined, thingsToDo);
  };

  const renderHTMLTag = (mainDescription: string) => {
    return (
      <div
        className={styles.Description}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: mainDescription }}
      />
    );
  };

  return (
    <>
      <LoadingModal visible={isLoadingModalVisible} />
      <FormModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        formFields={renderFormFields()}
        title={modalMode === 'create' ? texts.addPlace : texts.editTitle}
        mode={modalMode}
        refetch={refetch}
        editFormValues={editMutation}
        createFormValues={createMutation}
        formToInput={formToInput}
        editOrCreateItem={editItem}
      />
      <Row>
        <Col>
          <h2 className={styles.Title}>{texts.title}</h2>
        </Col>
      </Row>
      <Row className={styles.Row}>
        <Col>
          <Button
            type="primary"
            text={texts.addPlace}
            onClick={() =>
              setEditOrCreateForm(undefined, setEditItem, setModalMode, setIsModalVisible)
            }
          />
        </Col>
      </Row>
      <Row className={styles.Row}>
        <Col span={24}>
          <Table
            columns={columns(editButton, imageGroup, renderTags, renderHTMLTag)}
            dataSource={dataToTable(data?.listPlacesBackOffice)}
            loading={loading}
            pagination={false}
            rowKey="name"
          />
        </Col>
      </Row>
    </>
  );
};

export default PlaceConfiguration;
